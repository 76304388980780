// Don't change class names as CSS styles are associated with it

const events = {
  devjams: {
    name: "DevJams'21",
    date: '20th - 22nd',
    month: 'November',
    content:
      'DevJams is an annual 36-hour long hackathon, that aims to bring together great developers and designers under one roof and pitting them against each other in a healthy competition. Under focused mentorship from a dedicated and experienced technical team, and an equally challenging and innovative panel of themes, participants can test their skills and use them to build projects to solve real-world problems',
    classname: 'devjams-grid',
    route: '/events/devjams'
  },
  devtalks: {
    name: 'DevTalks 21',
    date: '12th - 13th Nov',
    content:
      'Interact and meet with a few of the most prestigious and renowned figures in the ever-changing tech industry!',
    classname: 'devtalks-grid',
    coords: { circle1: '-top-32 -right-0', circle2: '-bottom-20 left-0' },
    route: '/events/devtalks',
    logo: 'DevtalksLogo'
  },
  knockathon: {
    name: 'Knockathons 21',
    date: '9th - 10th Oct',
    content:
      'Have what it takes to be a winner? Team up with your friends to solve an innovative problem and pitch your ideas while competing against other teams to come out on top!',
    classname: 'knockathon-grid',
    coords: { circle1: '-top-32 -right-20', circle2: '-bottom-20 -left-20' },
    route: '/events/knockathon',
    logo: 'KnockathonLogo'
  },
  ctf: {
    name: 'DeconstruCTF',
    date: '1st - 2nd Oct',
    content:
      'A 24 hour jeopardy style CTF, ranging to five different domains, namely, web, forensics, cryptography, OSINT and miscellaneous!',
    classname: 'ctf-grid',
    coords: { circle1: '-top-0 -right-32', circle2: '-bottom-20 -left-20' },
    route: 'https://ctf.dscvit.com/',
    logo: 'CTFLogo'
  },
  hexathon: {
    name: 'Hexathon',
    date: '27th - 28th Nov',
    content:
      'Channel your inner passion and creativity to create innovative and ingenious design solutions to current problems and win exciting prizes!',
    classname: 'hexathon-grid',
    coords: { circle1: '-top-32 -right-32', circle2: 'bottom-10 -left-20' },
    route: '/events/hexathon',
    logo: 'HexathonLogo'
  }
}

export default events

// TODO: To be pasted later
// DevJams is one of a kind hackathon conducted by Google Developer
//           Student Clubs VIT every year to encourage tech enthusiasts from across
//           the country to develop their ideas for the future. The hackathon
//           focuses on providing students and upcoming entrepreneurs with a
//           platform to develop, compete, network and present their ideas to solve
//           real-world problems. We have been successfully conducting DevJams
//           (previously known as Devfest) for 5 years now. DevJams’21 will be the
//           sixth edition through which we hope to bring innovative minds from all
//           around the globe under one banner.
