import 'Styles/About.css'
import Faq from './Faq'

const Info = ({ faq, details, ...props }) => {
  return (
    <>
      <div className='mb-24 rounded-2xl shadow-lg py-10 px-8 md:px-14 bg-jams_dark_blue text-white border-4 border-jams_blue'>
        {props.children}
        <p className='text-sm leading-loose text-justify my-5 xl:text-base'>
          {details}
        </p>
      </div>
      {props.prizes &&
        <div className='bg-jams_dark_blue flex flex-col justify-center items-center p-5 border-4 md:w-5/6 lg:w-1/2 border-jams_blue mb-20 rounded-xl'>
          <span className='text-white text-center text-base md:text-lg font-semibold mb-5 whitespace-nowrap py-1 px-4 md:px-5 m-auto bg-jams_blue w-auto rounded-full'>Prizes</span>
          <img src={props.prizes} alt='prizes' className='w-full rounded-md' />
        </div>}
      <Faq faq={faq} />
    </>
  )
}

export default Info
