/* eslint-disable */

// import { ReactComponent as Hexcoin } from "Assets/Hexathon/Hexcoin.svg";
// import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as GoBack } from "Assets/Hexathon/go-back.svg";
// import { sendMarketplaceCart } from "Utils/APICalls";
// import { setLoading } from "Redux/slices/loading";
// import { toast } from "react-toastify";
// import { decamelize } from "Utils/Helper";

const MarketHeader = ({ title, text, limit }) => {
  // const dispatch = useDispatch();
  // const { choices, selected } = useSelector((state) => state.market);
  // const { config } = useSelector((state) => state.auth);
  // const { totalCoinsLeft } = choices;
  const pathname = useLocation().pathname;
  const isHome = pathname === "/events/hexathon/market";
  const history = useHistory();

  // const handleCheckout = async () => {
  //   for (const x in selected) {
  //     if (x !== "challenges") {
  //       if (selected[x].length === 0) {
  //         toast.info(
  //           `${decamelize(
  //             x
  //           )} is not chosen yet, make sure all of them are chosen before your submission, else your submission won't be evaluated`,
  //           { autoClose: 6000 }
  //         );
  //         break;
  //       }
  //     }
  //   }

  //   dispatch(setLoading(true));
  //   const body = {
  //     categories: choices.selectedItems,
  //   };
  //   sendMarketplaceCart(body, config).then((data) => {
  //     dispatch(setLoading(false));
  //   });
  // };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="py-8 relative text-white">
      <div className="pt-20 md:pt-0 grid gap-y-3 mr-6">
        <div className="text-xl sm:text-2xl font-bold flex items-center pb-4">
          <span onClick={handleBack} className="cursor-pointer">
            <GoBack className="w-5 h-5 mr-2 sm:-ml-10" />
          </span>
          <span className="whitespace-nowrap">{title}</span>
          {!isHome && (
            <span className="ml-3 text-sm bg-red-500 px-2 py-1 rounded-md whitespace-nowrap">
              Limit: {limit}
            </span>
          )}
        </div>
        <div className="text-sm">{text}</div>
      </div>
      {/* <div className="sm:mr-10 absolute top-12 right-0 overflow-hidden">
        {!isHome ? (
          <div className="bg-jams_notify_purple px-10 rounded-md py-2 flex justify-center items-center">
            <div className="font-bold">{totalCoinsLeft}</div>
            <Hexcoin className="w-6 h-6 ml-2" />
          </div>
        ) : (
          <div className="flex gap-3">
            <div className="bg-jams_notify_purple px-10 rounded-md py-2 flex justify-center items-center">
              <div className="font-bold">{totalCoinsLeft}</div>
              <Hexcoin className="w-6 h-6 ml-2" />
            </div>
            <button
              onClick={handleCheckout}
              className="bg-jams_red font-bold rounded-md px-6 hover:bg-red-500 py-2 text-center text-xs sm:text-sm cursor-pointer"
            >
              Checkout
            </button>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default MarketHeader;
