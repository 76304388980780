export const ADD_ITEM = 'ADD ITEM'
export const REMOVE_ITEM = 'REMOVE ITEM'

export const LIMIT = {
  challenges: 1,
  style: 1,
  font: 2,
  illustrationStyle: 1,
  problemStatement: 1,
  colour: 1
}

export const GOOGLE_PROVIDER = 'GOOGLE PROVIDER'
export const APPLE_PROVIDER = 'APPLE PROVIDER'
