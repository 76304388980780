import anime from 'animejs'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const LoadingScreen = ({ op }) => {
  const { loading } = useSelector((state) => state.loading)

  useEffect(() => {
    anime({
      targets: '#devjams path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuad',
      duration: 1000,
      delay: function (el, i) {
        return i * 100
      },
      direction: 'alternate',
      loop: true
    })
  }, [])

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 w-screen h-screen z-100 grid place-items-center bg-black ${
        loading ? 'visible' : 'invisible'
      } ${op || 'opacity-80'}`}
    >
      <div>
        <svg
          id='devjams'
          className='w-40 sm:w-60 md:w-96'
          viewBox='0 0 866 232'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.1985 64.2952H60.8036C71.7167 64.2952 81.5384 65.9642 90.2688 69.3023C98.9993 72.6404 106.446 77.3908 112.608 83.5535C118.771 89.5878 123.457 96.8417 126.667 105.315C130.005 113.789 131.674 123.097 131.674 133.24C131.674 143.511 130.005 152.883 126.667 161.357C123.457 169.831 118.771 177.085 112.608 183.119C106.446 189.153 98.9993 193.839 90.2688 197.178C81.5384 200.516 71.7167 202.185 60.8036 202.185H14.1985V64.2952ZM59.6481 177.534C67.2231 177.534 73.8993 176.507 79.6768 174.453C85.4543 172.27 90.2688 169.253 94.1205 165.401C97.9722 161.55 100.861 156.928 102.787 151.535C104.713 146.015 105.675 139.916 105.675 133.24C105.675 126.564 104.713 120.529 102.787 115.137C100.861 109.616 97.9722 104.93 94.1205 101.079C90.2688 97.2269 85.4543 94.274 79.6768 92.2197C73.8993 90.0371 67.2231 88.9458 59.6481 88.9458H40.1972V177.534H59.6481Z'
            stroke='#FC5251'
          />
          <path
            d='M210.97 179.46C206.605 187.163 200.699 193.39 193.253 198.14C185.934 202.891 176.947 205.266 166.291 205.266C159.101 205.266 152.425 204.046 146.262 201.607C140.228 199.039 134.964 195.508 130.471 191.015C125.977 186.521 122.446 181.257 119.878 175.223C117.439 169.06 116.219 162.32 116.219 155.002C116.219 148.197 117.439 141.778 119.878 135.744C122.318 129.581 125.72 124.253 130.085 119.759C134.451 115.137 139.586 111.478 145.492 108.782C151.526 106.086 158.138 104.738 165.328 104.738C172.903 104.738 179.643 106.022 185.549 108.589C191.455 111.029 196.398 114.495 200.378 118.989C204.358 123.354 207.375 128.554 209.43 134.588C211.484 140.622 212.511 147.17 212.511 154.231C212.511 155.13 212.511 155.901 212.511 156.542C212.383 157.313 212.318 158.019 212.318 158.661C212.19 159.303 212.126 160.009 212.126 160.779H141.063C141.576 164.631 142.603 167.969 144.144 170.794C145.813 173.49 147.803 175.801 150.114 177.727C152.553 179.524 155.185 180.872 158.01 181.771C160.834 182.541 163.723 182.926 166.676 182.926C172.454 182.926 177.204 181.642 180.927 179.075C184.779 176.379 187.796 173.04 189.979 169.06L210.97 179.46ZM188.053 142.869C187.925 141.2 187.347 139.338 186.32 137.284C185.421 135.23 184.009 133.304 182.083 131.507C180.285 129.709 177.974 128.233 175.15 127.077C172.454 125.922 169.18 125.344 165.328 125.344C159.936 125.344 155.185 126.885 151.077 129.966C146.969 133.047 144.08 137.348 142.411 142.869H188.053Z'
            stroke='#5A90FC'
          />
          <path
            d='M190.684 107.819H219.379L242.874 167.905H244.222L267.91 107.819H296.413L255.777 202.185H230.934L190.684 107.819Z'
            stroke='#FBC235'
          />
          <path
            d='M292.605 204.892C281.692 204.892 272.127 201.939 263.91 196.033C255.822 190.127 250.173 181.974 246.963 171.575L270.843 161.753C272.512 167.916 275.208 172.538 278.932 175.619C282.655 178.701 287.213 180.241 292.605 180.241C298.639 180.241 303.325 178.315 306.664 174.464C310.002 170.612 311.671 165.22 311.671 158.287V63.921H337.669V158.094C337.669 165.798 336.45 172.602 334.01 178.508C331.699 184.285 328.49 189.164 324.381 193.144C320.401 196.996 315.651 199.885 310.13 201.811C304.738 203.865 298.896 204.892 292.605 204.892Z'
            stroke='#39AA68'
          />
          <path
            d='M390.184 191.796H388.644C385.691 195.391 382.096 198.472 377.859 201.04C373.751 203.608 368.423 204.892 361.875 204.892C356.996 204.892 352.374 204.057 348.009 202.388C343.772 200.848 340.049 198.665 336.839 195.84C333.629 193.016 331.126 189.614 329.328 185.634C327.531 181.654 326.632 177.288 326.632 172.538C326.632 167.659 327.595 163.294 329.521 159.442C331.447 155.462 334.143 152.06 337.609 149.235C341.076 146.411 345.12 144.292 349.742 142.88C354.492 141.339 359.628 140.569 365.149 140.569C371.311 140.569 376.383 141.083 380.363 142.11C384.343 143.009 387.617 143.971 390.184 144.999V142.11C390.184 137.103 388.194 132.994 384.214 129.784C380.234 126.446 375.291 124.777 369.385 124.777C360.655 124.777 353.273 128.436 347.238 135.755L328.943 123.237C338.957 110.655 352.631 104.363 369.963 104.363C384.6 104.363 395.769 107.83 403.473 114.763C411.176 121.568 415.028 131.71 415.028 145.191V201.811H390.184V191.796ZM390.184 163.487C387.232 162.074 384.15 160.983 380.94 160.213C377.859 159.314 374.457 158.865 370.734 158.865C364.699 158.865 360.013 160.213 356.675 162.909C353.465 165.477 351.86 168.686 351.86 172.538C351.86 176.39 353.401 179.471 356.482 181.782C359.692 184.093 363.351 185.248 367.46 185.248C370.798 185.248 373.879 184.671 376.704 183.515C379.528 182.231 381.903 180.626 383.829 178.701C385.883 176.646 387.424 174.335 388.451 171.768C389.607 169.071 390.184 166.311 390.184 163.487Z'
            stroke='#568EF4'
          />
          <path
            d='M407.162 107.445H430.85V119.77H432.39C435.343 115.148 439.323 111.425 444.33 108.6C449.466 105.776 455.115 104.363 461.278 104.363C468.468 104.363 474.502 106.033 479.381 109.371C484.259 112.709 487.726 116.817 489.78 121.696C492.733 116.946 496.906 112.901 502.298 109.563C507.69 106.097 514.238 104.363 521.941 104.363C527.719 104.363 532.726 105.326 536.963 107.252C541.2 109.05 544.666 111.617 547.362 114.956C550.187 118.294 552.305 122.338 553.718 127.088C555.13 131.71 555.836 136.782 555.836 142.302V201.811H530.608V146.924C530.608 134.085 525.344 127.666 514.816 127.666C511.478 127.666 508.525 128.436 505.957 129.977C503.389 131.389 501.207 133.379 499.409 135.947C497.74 138.387 496.392 141.339 495.365 144.806C494.466 148.272 494.017 151.996 494.017 155.976V201.811H468.788V146.924C468.788 134.085 463.268 127.666 452.226 127.666C449.017 127.666 446.192 128.436 443.753 129.977C441.313 131.389 439.259 133.379 437.59 135.947C435.921 138.387 434.637 141.339 433.738 144.806C432.84 148.272 432.39 151.996 432.39 155.976V201.811H407.162V107.445Z'
            stroke='#F45658'
          />
          <path
            d='M586.049 204.892C579.886 204.892 574.366 204.122 569.487 202.581C564.737 201.04 560.564 199.05 556.969 196.611C553.502 194.043 550.55 191.154 548.11 187.945C545.671 184.606 543.809 181.268 542.525 177.93L565.057 168.301C567.24 173.18 570.129 176.839 573.724 179.278C577.447 181.589 581.555 182.745 586.049 182.745C590.671 182.745 594.33 181.91 597.026 180.241C599.722 178.572 601.071 176.582 601.071 174.271C601.071 171.703 599.915 169.649 597.604 168.108C595.421 166.439 591.57 164.963 586.049 163.679L572.761 160.79C569.808 160.148 566.727 159.121 563.517 157.709C560.435 156.297 557.611 154.499 555.043 152.317C552.475 150.134 550.357 147.502 548.688 144.421C547.019 141.339 546.184 137.745 546.184 133.636C546.184 129.014 547.147 124.906 549.073 121.311C551.127 117.716 553.888 114.699 557.354 112.259C560.821 109.692 564.865 107.766 569.487 106.482C574.237 105.07 579.309 104.363 584.701 104.363C593.688 104.363 601.712 106.161 608.774 109.756C615.835 113.222 621.035 118.807 624.373 126.511L602.611 135.369C600.814 131.646 598.182 128.95 594.715 127.281C591.249 125.612 587.782 124.777 584.316 124.777C580.721 124.777 577.575 125.548 574.879 127.088C572.183 128.501 570.835 130.362 570.835 132.673C570.835 134.856 571.926 136.589 574.109 137.873C576.42 139.157 579.501 140.312 583.353 141.339L597.797 144.806C607.426 147.117 614.551 150.84 619.173 155.976C623.924 160.983 626.299 166.953 626.299 173.886C626.299 177.994 625.336 181.91 623.41 185.634C621.484 189.357 618.724 192.695 615.129 195.648C611.663 198.472 607.426 200.719 602.419 202.388C597.54 204.057 592.083 204.892 586.049 204.892Z'
            stroke='#F9C531'
          />
          <path
            d='M635.857 91.1688L645.679 76.1473C642.854 75.1202 640.415 73.3227 638.361 70.755C636.435 68.0588 635.472 64.8491 635.472 61.1258C635.472 56.5038 637.077 52.5879 640.286 49.3782C643.624 46.0401 647.605 44.3711 652.227 44.3711C656.848 44.3711 660.764 46.0401 663.974 49.3782C667.184 52.5879 668.789 56.5038 668.789 61.1258C668.789 63.5652 668.339 65.7478 667.441 67.6736C666.67 69.5995 665.515 71.6537 663.974 73.8363L647.219 98.6795L635.857 91.1688Z'
            stroke='#39AA68'
          />
          <path
            d='M689.369 202.616V179.699C696.944 172.252 703.941 165.319 710.36 158.9C713.056 156.204 715.817 153.443 718.641 150.619C721.466 147.794 724.098 145.162 726.537 142.723C728.977 140.284 731.159 138.101 733.085 136.175C735.011 134.121 736.487 132.516 737.514 131.361C739.569 128.921 741.366 126.739 742.907 124.813C744.576 122.887 745.86 121.025 746.758 119.228C747.785 117.302 748.556 115.44 749.069 113.643C749.583 111.717 749.84 109.663 749.84 107.48C749.84 103.243 748.171 99.5844 744.833 96.503C741.494 93.2933 736.937 91.6885 731.159 91.6885C725.382 91.6885 720.888 93.3575 717.678 96.6956C714.469 99.9053 712.286 103.564 711.13 107.673L688.213 98.2363C689.24 94.7698 690.909 91.2391 693.22 87.6442C695.531 83.9209 698.484 80.647 702.079 77.8225C705.674 74.8695 709.911 72.4943 714.79 70.6969C719.797 68.7711 725.382 67.8081 731.544 67.8081C738.221 67.8081 744.255 68.8353 749.647 70.8895C755.039 72.8153 759.597 75.5115 763.321 78.978C767.044 82.4445 769.933 86.5529 771.987 91.3033C774.041 95.9253 775.068 100.932 775.068 106.325C775.068 114.542 773.014 122.117 768.905 129.05C764.925 135.854 760.047 142.209 754.269 148.115L737.707 164.677C733.342 169.043 728.399 173.921 722.878 179.314L723.648 180.469H776.994V202.616H689.369ZM823.822 202.616V100.547L800.519 110.369L791.275 88.9923L829.984 70.8895H849.05V202.616H823.822Z'
            stroke='#568EF4'
          />
        </svg>
        <div className='font-sora text-xs text-center text-white'>Loading</div>
      </div>
    </div>
  )
}

export default LoadingScreen
