// Don't change class names as CSS styles are associated with it

const Speakers = {
  DineshPunni: {
    name: 'Dinesh Punni',
    content:
      'Dinesh is the Founder and CEO of Immersive Insiders. He helps professionals to be a part of and grow in the Augmented and Virtual Reality industry. He teaches AR/VR creation on his YouTube channel and with in-depth step by step courses on his XR education platform: immersive insiders.',
    route: 'https://www.linkedin.com/in/dineshpunni/'
  },
  AanshulSadaria: {
    name: 'Aanshul Sadaria',
    content:
      'Aanshul is a Software Engineer at Google, where he works on providing Cloud Search solutions to enterprise customers. He is also interested and enthusiastic about Data Science and ML+DL solutions',
    route: ' https://www.linkedin.com/in/aanshul-sadaria-31069494'
  },
  PrateekNarang: {
    name: 'Prateek Narang',
    content:
      'Prateek is a Software Engineer and a professional coding instructor with a demonstrated history of working in the edtech and software engineering industry.He is currently serving as founding member and SWE at Product Uplift Team, which is a part of the Customer Engagement Org at Google.',
    route: 'https://www.linkedin.com/in/prateeknarang27/'
  },
  RiddhiDutta: {
    name: 'Riddhi Dutta',
    content:
      'Software Development Engineer at Amazon. He loves Problem Solving and unraveling the mysteries behind the magic of computer programs. He also teaches competitive programming to students on YouTube',
    route: 'https://www.linkedin.com/in/riddhi-dutta/'
  },
  Sudhakar: {
    name: 'Sudhakar',
    content:
      'Sudhakar is an Engineer with the Spotlight team at CrowdStrike. He has 4+ years of experience around reversing, exploitation, CTFs and software development. He is passionate about all thing’s exploitation and maths. He is currently the chapter lead for Null',
    route: 'https://sudhackar.github.io/'
  }
}

export default Speakers
