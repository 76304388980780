import { createSlice } from '@reduxjs/toolkit'
import produce from 'immer'
import { ADD_ITEM, REMOVE_ITEM } from 'Utils/Constants'
import { camelize } from 'Utils/Helper'

const market = {
  catalog: {
    challenges: {},
    style: {},
    font: {},
    illustrationStyle: {},
    problemStatement: {},
    colour: {}
  },
  catalogFetched: false,
  choices: {
    participation_id: '',
    totalCoinsLeft: 0,
    totalCoinsUsed: 0,
    selectedItems: []
  },
  selected: {
    challenges: [],
    style: [],
    font: [],
    illustrationStyle: [],
    problemStatement: [],
    colour: []
  }
}

export const marketSlice = createSlice({
  name: 'market',
  initialState: market,
  reducers: {
    setTeamsChoices: (state, action) => {
      const { choices, selectedList } = action.payload
      if (choices) {
        const { participation_id: id, choice_json: chosen } = choices
        const { totalCoinsLeft, totalCoinsUsed, selectedItems } = chosen

        // initialize selected items
        state.choices.selectedItems = selectedList

        const itemsPurchased = []

        // initialize selected
        state.selected = produce(state.selected, (draft) => {
          selectedItems.forEach((item) => {
            const categoryname = camelize(item.category_name)
            draft[categoryname].push(item.item_name)
            itemsPurchased.push(item.item_id)
          })
        })

        state.catalog = produce(state.catalog, (draft) => {
          Object.keys(draft).forEach((key) => {
            const categ = draft[key]
            const itemsObj = {}
            Object.keys(categ.items)?.forEach((itemKey) => {
              const item = categ.items[itemKey]
              const { item_name: itemName } = item
              const purchasedItem = { ...item, isPurchased: false }

              itemsPurchased.forEach((id) => {
                const similar = item.item_id === id
                if (similar) purchasedItem.isPurchased = true
              })

              itemsObj[camelize(itemName)] = purchasedItem
            })
            draft[key] = { ...categ, items: itemsObj }
          })
        })
        state.choices.participation_id = id
        state.choices.totalCoinsLeft = totalCoinsLeft
        state.choices.totalCoinsUsed = totalCoinsUsed
      }
    },
    setAvailableCatalog: (state, action) => {
      const categList = action.payload
      categList?.forEach((categ) => {
        const name = camelize(categ.category_name)
        const itemsObj = {}

        // make key object pairs of category name and
        // corresponding category details
        categ.items?.forEach((item) => {
          const { item_name: itemName } = item
          itemsObj[camelize(itemName)] = item
        })

        state.catalog[name] = { ...categ, items: itemsObj }
      })

      state.catalogFetched = true
    },
    makePurchase: (state, action) => {
      let {
        updatedList,
        updatedNames,
        title: itemName,
        categoryName,
        price,
        type
      } = action.payload
      categoryName = camelize(categoryName)
      itemName = camelize(itemName)

      if (type === REMOVE_ITEM) {
        state.catalog[categoryName].items[itemName].isPurchased = false
        state.choices.totalCoinsLeft += price * -1
        state.choices.totalCoinsUsed += price
      } else if (type === ADD_ITEM) {
        state.catalog[categoryName].items[itemName].isPurchased = true
        state.choices.totalCoinsLeft += price
        state.choices.totalCoinsUsed += price * -1
      }
      state.choices.selectedItems = updatedList
      state.selected = updatedNames
    }
  }
})

export const { setAvailableCatalog, setTeamsChoices, makePurchase } =
  marketSlice.actions

export default marketSlice.reducer
