import { createSlice } from '@reduxjs/toolkit'

const auth = {
  accessToken: null,
  registered: false,
  config: {
    headers: {
      Authorization: null
    }
  },
  isLoggedIn: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: auth,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setRegistered: (state, action) => {
      state.registered = action.payload
    },
    setBackendToken: (state, action) => {
      state.config.headers.Authorization = `Bearer ${action.payload}`
      state.isLoggedIn = true
    }
  }
})

export const { setAccessToken, setRegistered, setBackendToken } =
  authSlice.actions

export default authSlice.reducer
