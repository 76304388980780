/* eslint-disable */

import { ReactComponent as Hexcoin } from 'Assets/Hexathon/Hexcoin.svg'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { makePurchase } from 'Redux/slices/events/market'
import { ADD_ITEM, LIMIT, REMOVE_ITEM } from 'Utils/Constants'
import { camelize } from 'Utils/Helper'
import produce from 'immer'

const MarketCard = ({
  imgURL,
  selected,
  price,
  itemDescription,
  styles,
  isPurchased,
  itemId,
  title,
  categoryId,
  categoryName
}) => {
  const dispatch = useDispatch()
  const { selectedItems, totalCoinsLeft } = useSelector(
    (state) => state.market.choices
  )
  const { selected: selectedNames } = useSelector((state) => state.market)
  let isError = false
  let disabled = false

  const limit = LIMIT[categoryName ? camelize(categoryName) : 0]

  if (categoryName !== 'Challenges') {
    if (Math.abs(price) > totalCoinsLeft) {
      disabled = true
    } else {
      disabled = false
    }
  }

  const manageItem = (type) => {
    const updatedList = produce(selectedItems, (draft) => {
      const indexOfItem = draft
        ?.map((cat) => cat.categoryID)
        .indexOf(categoryId)
      // if index with such category found
      if (indexOfItem !== -1) {
        if (type === REMOVE_ITEM) {
          draft[indexOfItem].items = draft[indexOfItem].items.filter(
            (id) => id !== itemId
          )
        } else if (type === ADD_ITEM) {
          if (draft[indexOfItem].items?.length < limit) {
            draft[indexOfItem].items.push(itemId)
          } else {
            isError = true
            toast.error(
              `Can only choose atmost ${
                limit > 1 ? `${limit} items` : `${limit} item`
              }`
            )
          }
        }
      } else {
        // If no such category found means it's probably an add operation
        if (type === ADD_ITEM) {
          draft.push({
            categoryID: categoryId,
            items: [itemId]
          })
        }
      }
    })

    const categname = camelize(categoryName)
    const updatedNames = produce(selectedNames, (draft) => {
      if (type === ADD_ITEM) {
        if (categname !== 'challenges') {
          if (Math.abs(price) > totalCoinsLeft) {
            toast.error('Not enough coins 😢')
            isError = true
          }
        }
        if (draft[categname]?.length < limit) draft[categname].push(title)
      } else if (type === REMOVE_ITEM) {
        if (categname === 'challenges') {
          if (Math.abs(price) > totalCoinsLeft) {
            toast.error('You will be out of coins! 😢')
            isError = true
          }
        }
        draft[categname] = draft[categname].filter((name) => name !== title)
      }
    })

    if (!isError) {
      dispatch(
        makePurchase({
          updatedList,
          updatedNames,
          title,
          categoryName,
          price,
          type
        })
      )
      if (type === ADD_ITEM) {
        toast.success('Item has been added. Checkout to save changes')
      } else if (type === REMOVE_ITEM) {
        toast.info('Item has been removed. Checkout to save changes')
      }
    }
  }

  return (
    <div
      className={`${
        isPurchased ? 'border-2 border-green-400' : ''
      } bg-jams_dark_blue min-w-full h-full text-white flex flex-col font-sora rounded-lg overflow-hidden ${styles}`}
    >
      {imgURL && (
        <img
          src={imgURL}
          alt='item-url'
          className='w-full max-h-56 object-cover pb-4'
        />
      )}
      <div className='px-6 py-4 flex flex-col flex-auto justify-between'>
        <div className='flex-auto'>
          <div className='font-bold text-base py-3'>{title}</div>
          <div className='text-jams_light_purple'>{itemDescription}</div>
        </div>
        <div className='pt-6 pb-4'>
          {selected ? (
            <div className='text-green-400'>
              <span className='font-bold mr-2'>Selected:</span>
              <span>{selected}</span>
            </div>
          ) : (
            !price && (
              <div className='text-red-400'>
                <span className='font-bold mr-2'>Selected:</span>
                None
              </div>
            )
          )}
          <div className='flex rounded-md overflow-hidden'>
            {isPurchased ? (
              <div className='bg-jams_notify_purple w-full py-2 flex justify-center items-center'>
                <div className='text-center font-bold py-0.5'>
                  Added to cart
                </div>
              </div>
            ) : (
              price && (
                <button
                  disabled={true}
                  className={'w-full py-2 flex justify-center items-center bg-jams_light_purple cursor-default'}
                >
                  <div className='font-bold'>{price && Math.abs(price)}</div>
                  <Hexcoin className='w-6 h-6 ml-2' />
                </button>
              )
            )}
            {isPurchased && (
              <div
                className='bg-jams_red px-4 py-2'
              >
                -
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketCard
