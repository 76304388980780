import { ReactComponent as DevJamsLogo } from 'Assets/Logos/DevjamsLogo.svg'
import DevJamsGridGround from 'Assets/Home/DevJamsGridGround'
import events from 'Data/EventsData'
import 'Styles/Components/Events.css'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const CurrentEventGrid = ({
  event,
  isRegistered,
  teamCreationRequired,
  eventId,
  newTab,
  buttonText,
  ...props
}) => {
  const { date, content, route } = event
  const history = useHistory()

  const handleRouting = () => {
    if (newTab) {
      window.open(route, '_blank')
    } else {
      history.push(route)
    }
  }

  return (
    <>
      <div className='relative grid--dark coming-soon-grid--dark w-80 pt-4 shadow-md rounded-xl sm:rounded-3xl overflow-hidden'>
        <div className='content-container'>
          <div className='h-56 grid gap-y-4'>
            <div className='grid place-items-center gap-y-4'>
              {props.children}
              <div className='text-jams_red font-bold'>{date}</div>
            </div>
            <div className='content--dark'>{content}</div>
          </div>
          <div
            onClick={handleRouting}
            className='btn__register--dark grid-btn hover:bg-jams_red text-center cursor-pointer'
          >
            {buttonText}
          </div>
        </div>
        {/* circles */}
        <div className='-z-10 absolute top-0 left-0 right-0 bottom-0'>
          <Circles info={event} />
        </div>
      </div>
    </>
  )
}

const ComingSoonGrid = ({ event, status, ...props }) => {
  const { date, content } = event

  return (
    <div className='relative grid--dark coming-soon-grid--dark w-80 pt-4 shadow-md rounded-xl sm:rounded-3xl overflow-hidden'>
      <div className='content-container'>
        <div className='h-56 grid gap-y-4'>
          <div className='grid place-items-center gap-y-4'>
            {props.children}
            <div className='text-jams_red font-bold'>{date}</div>
          </div>
          <div className='content--dark'>{content}</div>
        </div>
        <div className='text-center'>
          <div className='btn__coming-soon--dark bottom-4 grid-btn'>
            {status || 'Coming Soon'}
          </div>
        </div>
      </div>
      <div className='-z-10 absolute top-0 bottom-0 right-0 left-0'>
        <Circles info={event} />
      </div>
    </div>
  )
}

const DevJamsGrid = ({ buttonText }) => {
  const { date, month } = events.devjams

  return (
    <div
      className={`grid--dark coming-soon-grid--dark ${events.devjams.class}--dark mx-5 rounded-3xl relative sm:rounded-3xl overflow-hidden xl:mx-12 mb-3 lg:mb-0 border-4 border-jams_blue`}
    >
      <div className='grid md:grid-cols-2 place-items-center px-10 relative mb-2 z-10'>
        <div className='flex flex-col items-center md:items-start'>
          <DevJamsLogo className='w-40 sm:w-72 h-auto py-6' />
          <a
            href='https://devjams.dscvit.com/Brochure.pdf'
            target='_blank'
            rel='noreferrer noopener'
            className='cursor-pointer flex justify-center md:justify-start gap-x-2 text-gray-100 hover:text-white md:text-lg font-semibold items-center md:px-0 py-2 md:pb-4 md:pt-0'
          >
            <span>Download Brochure</span>
            <ChervonRight />
          </a>
        </div>
        <div className='text-jams_red grid font-bold gap-y-1 place-items-center pt-5'>
          <div className='text-base sm:text-lg lg:text-xl xl:text-2xl'>
            {date}
          </div>
          <div className='text-sm sm:text-base lg:text-lg xl:text-xl'>
            {month}
          </div>
          <div className='grid justify-items-end'>
            <Link to='/events/devjams'>
              <button className='bg-jams_red text-white grid-btn hover:bg-red-500 text-center cursor-pointer px-16'>
                {buttonText}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <DevJamsGridGround className='devjams-ground w-full opacity-40' />
    </div>
  )
}

const Circles = ({ info }) => {
  const { coords, classname } = info

  return (
    <>
      <div
        style={{ width: '300px', height: '300px' }}
        className={`circle ${coords.circle1} ${classname}__circle--dark`}
      />
      <div
        style={{ width: '200px', height: '200px' }}
        className={`circle ${coords.circle2} ${classname}__circle--dark`}
      />
    </>
  )
}

const ChervonRight = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-4 w-4'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M9 5l7 7-7 7'
      />
    </svg>
  )
}

export { CurrentEventGrid, DevJamsGrid, ComingSoonGrid }
