import events from 'Data/EventsData'
import { ReactComponent as DevTalksLogo } from 'Assets/Logos/DevTalks Logo.svg'
import { ComingSoonGrid } from './Grids'

const DevtalksCard = () => {
  return (
    <ComingSoonGrid event={events.devtalks} status='Registrations Closed'>
      <DevTalksLogo className='w-40' />
    </ComingSoonGrid>
  )
}

export default DevtalksCard
