import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import userReducer from './slices/user'
import loadingReducer from './slices/loading'
import registerReducer from './slices/register'

// Event reducers
import KnockathonsReducer from './slices/events/knockathon'
import HexathonReducer from './slices/events/hexathon'
import DevjamsReducer from './slices/events/devjams'
import MarketReducer from './slices/events/market'

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    loading: loadingReducer,
    register: registerReducer,
    knockathons: KnockathonsReducer,
    hexathon: HexathonReducer,
    devjams: DevjamsReducer,
    market: MarketReducer
  }
})

export default store
