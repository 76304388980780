import { Switch, useRouteMatch, Route, Redirect } from 'react-router'
import EventsNavbar from 'Pages/Events/EventsNavbar'
import Info from 'Pages/Events/Info'
import Teams from 'Pages/Events/Knockathons/Teams'
import Timeline from 'Pages/Events/Timeline'
import Submission from 'Pages/Events/Knockathons/Submission'
import Results from 'Pages/Events/Knockathons/Results'
import { ReactComponent as Knockathons } from 'Assets/Logos/Knockathons Logo.svg'
import faq from 'Data/FaqData'
import events from 'Data/EventsData'

const KnockathonLayout = () => {
  const match = useRouteMatch()

  return (
    <div className='w-screen'>
      <EventsNavbar />
      <div className='w-11/12 md:w-3/4 xl:w-5/7 mx-auto grid place-items-center text-sm md:pb-28'>
        <Switch>
          <Route exact path={`${match.url}/timeline`} component={Timeline} />
          <Route exact path={`${match.url}/teams`} component={Teams} />
          <Route
            exact
            path={`${match.url}/info`}
            render={() => (
              <Info faq={faq.knockathons} details={events.knockathon.content}>
                <Knockathons className='w-48 sm:w-60' />
              </Info>
            )}
          />
          <Route exact path={`${match.url}/results`} component={Results} />
          <Route
            exact
            path={`${match.url}/submission`}
            component={Submission}
          />
          <Redirect exact from={`${match.url}`} to={`${match.url}/results`} />
        </Switch>
      </div>
    </div>
  )
}

export default KnockathonLayout
