const ComingSoon = () => {
  return (
    <div className='relative'>
      <div className='absolute -inset-1 bg-gradient-to-t from-jams_red to-jams_blue blur opacity-100 rounded-full' />
      <div className='flex justify-center h-full cards overflow-y-hidden py-auto'>
        <div>
          <h1 className='text-white relative z-30 h-40 w-40 bg-jams_purple px-5 py-3 text-xl rounded-full grid place-items-center text-center font-bold'>
            Coming Soon
          </h1>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon
