import MarketCard from 'Components/Cards/MarketCard'
import MarketHeader from 'Components/MarketHeader'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { headings } from './MarketData'

const MarketHome = () => {
  const match = useRouteMatch()
  const { selected: chosen } = useSelector((state) => state.market)

  return (
    <div>
      <MarketHeader {...headings.home} />
      <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
        <Link to={`${match.url}/statements`}>
          <MarketCard
            title='Problem Statement'
            itemDescription='Choose any one design challenge from the ones available!'
            selected={chosen?.problemStatement?.join(', ')}
          />
        </Link>
        <Link to={`${match.url}/fonts`}>
          <MarketCard
            title='Fonts'
            itemDescription='You can only use purchased fonts in your design. You may purchase multiple fonts.'
            selected={chosen?.font?.join(', ')}
          />
        </Link>
        <Link to={`${match.url}/styles`} className='grid row-span-2'>
          <MarketCard
            title='Theme'
            itemDescription='Choose a single theme, which has to be followed throughout your designs.'
            styles='row-span-2'
            selected={chosen?.style?.join(', ')}
          />
        </Link>
        <Link to={`${match.url}/colours`}>
          <MarketCard
            title='Color Palette'
            itemDescription='Choose a single color palette for your designs.'
            selected={chosen?.colour?.join(', ')}
          />
        </Link>
        <Link to={`${match.url}/illustrations`} className='grid md:col-span-2'>
          <MarketCard
            title='Illustration Styles'
            itemDescription='You have to stick to this style of illustrations for your designs. You may use pre-made illustrations unless you decide to take up a challenge which prohibits you from using pre-made illustrations.'
            styles='md:col-span-2'
            selected={chosen?.illustrationStyle?.join(', ')}
          />
        </Link>
        <Link to={`${match.url}/challenges`}>
          <MarketCard
            title='Challenges (optional)'
            itemDescription='Take up a challenge to earn some extra Hexcoins! You can use these to purchase expensive elements from the marketplace.'
            selected={chosen?.challenges?.join(', ')}
          />
        </Link>
      </div>
    </div>
  )
}

export default MarketHome
