import React, { useEffect } from 'react'
import {
  joinTeamInEvent,
  registerUserToEvent,
  fetchTeamsOfEvent,
  getTeamDetails,
  getParticipationDetails
} from 'Utils/APICalls'
import { Formik, Field, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { setLoading } from 'Redux/slices/loading'
import {
  setRegisteredForHexathon,
  setHexathonAllTeams,
  setHexathonTeamMembers,
  setHexathonUserDetails,
  setHexathonTeamJoinCode,
  setHexathonTeamDetails
} from 'Redux/slices/events/hexathon'
import { ReactComponent as DefaultUser } from 'Assets/user.svg'
import useFuzzy from 'Hooks/useFuzzy'
import { ReactComponent as Search } from 'Assets/Search.svg'

const RegisterTeam = () => {
  const { allTeams } = useSelector((state) => state.hexathon)
  const { query, resetQuery, result, search } = useFuzzy(allTeams)

  const { config } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const eventId = process.env.REACT_APP_HEXATHON_EVENT_ID

  useEffect(() => {
    if (allTeams.length === 0) {
      fetchTeamsOfEvent(eventId, config).then((res) => {
        dispatch(setHexathonAllTeams(res))
      })
    }
  }, [eventId, config, dispatch, allTeams.length])

  const getTeam = (teamId) => {
    const paramsObject = {
      params: { team_id: teamId }
    }
    const hexathonConfig = { ...config, ...paramsObject }
    let teamSize = 1
    getTeamDetails(teamId, hexathonConfig)
      .then((res) => {
        const teamName = res.team.team_details.team_name
        const joinCode = res.team.join_code
        const members = res.team.members
        const userDetails = res.team.user_details
        teamSize = members.length
        dispatch(setHexathonTeamJoinCode({ joinCode, teamName }))
        dispatch(setHexathonTeamMembers(members))
        dispatch(setHexathonUserDetails(userDetails))
      })
      .catch((err) => {
        console.log(err.message)
      })
    getParticipationDetails(config).then((res) => {
      const event = res.filter((event) => event.event.id === eventId)[0]
      const participation = {
        id: event.id,
        created_at: event.created_at,
        updated_at: event.updated_at,
        event_id: event.event_id,
        team_id: event.team_id,
        submission_id: event.submission_id
      }
      dispatch(setHexathonTeamDetails({ participation, teamSize }))
    })
  }

  const handleCreateTeam = async () => {
    dispatch(setLoading(true))
    try {
      const res = await registerUserToEvent(eventId, config)
      if (res) {
        dispatch(setRegisteredForHexathon(true))
        getTeam(res.data.team.id)
      }
    } catch (err) {
      dispatch(setRegisteredForHexathon(false))
      console.log('Error while registering', err)
    }
    dispatch(setLoading(false))
  }

  const handleJoinTeam = async (teamCode) => {
    dispatch(setLoading(true))
    try {
      const res = await joinTeamInEvent(teamCode, config)
      if (res) {
        getTeam(res.data.team.id)
        dispatch(setRegisteredForHexathon(true))
      }
    } catch (err) {
      dispatch(setRegisteredForHexathon(false))
      console.log('Error while joining team', err)
    }
    dispatch(setLoading(false))
  }

  return (
    <div className='h-full grid lg:grid-cols-3 gap-8'>
      <div className='grid gap-y-8 lg:col-span-2'>
        <div className='text-white grid bg-jams_dark_blue w-full p-10 text-justify opacity-95 rounded-xl divide-y-2 divide-gray-600 divide-dashed '>
          <div className='mb-6'>
            <h1 className='text-xl font-bold'>Join Team</h1>
          </div>
          <div className='pt-8'>
            <p className='mb-2 text-gray-300'>
              Enter your invite code and get on-board the DevJams Express!
            </p>
            <Formik
              initialValues={{ teamCode: '' }}
              onSubmit={(values) => handleJoinTeam(values.teamCode)}
            >
              <Form className='grid sm:flex gap-3 place-items-center'>
                <Field
                  name='teamCode'
                  className='rounded-md bg-jams_input_gray w-full py-2 px-3 text-indigo-200 font-semibold'
                  type='text'
                />
                <button
                  type='submit'
                  className='bg-jams_green hover:bg-green-500 px-6 py-2 my-2 font-semibold rounded-md whitespace-nowrap'
                >
                  Join Team
                </button>
              </Form>
            </Formik>
          </div>
        </div>

        <div className='text-white grid bg-jams_dark_blue w-full p-10 text-justify opacity-95 rounded-xl divide-y-2 divide-gray-600 divide-dashed '>
          <div className='mb-6'>
            <h1 className='text-xl font-semibold'>Create Team</h1>
          </div>
          <div className='grid sm:flex gap-3 place-items-center justify-between pt-8'>
            <p className='mb-2 text-gray-300 sm:w-2/3 md:w-8/12 xl:w-9/12'>
              Create your own team and invite your friends to get on-board the
              DevJams Express!
            </p>
            <button
              className='px-6 py-2 bg-jams_green hover:bg-green-500 rounded-md font-bold'
              onClick={handleCreateTeam}
            >
              Create Team
            </button>
          </div>
        </div>
      </div>
      <div
        style={{ maxHeight: '534px' }}
        className='text-white flex w-full flex-col bg-jams_dark_blue px-9 py-8 text-justify opacity-95 rounded-xl divide-y-2 divide-gray-600 divide-dashed '
      >
        <div className='flex flex-col justify-center mb-4'>
          <h1 className='text-xl py-2 font-bold'>Similar Teams</h1>
          <p className='text-xs md:text-xs text-gray-300'>
            Email the team leader to get the team invite code
          </p>
          <div className='flex items-center mt-4 bg-jams_icon_gray rounded-md overflow-hidden'>
            <input
              type='text'
              value={query}
              className='flex-1 px-3 py-2 bg-jams_icon_gray outline-none rounded-md'
              onChange={({ currentTarget }) => search(currentTarget.value)}
              onKeyDown={({ key }) => {
                if (key === 'Enter') resetQuery()
              }}
            />
            <Search className='w-5 h-5 mx-2' />
          </div>
        </div>
        <div className='overflow-y-scroll text-left h-full divide-y-2 divide-gray-600 similar-teams'>
          {result &&
            result.map((team, index) => {
              const { leader, count, id } = team
              return (
                <div
                  key={id || index}
                  className='pb-3 pt-3 flex justify-between w-full items-center break-all'
                >
                  <div>
                    <p className='pb-2'>{team.teamName}</p>
                    <p className='text-xs text-gray-300'>
                      {leader.first_name + ' ' + leader.last_name}
                    </p>
                    <p className='text-xs text-gray-300'>{leader.email}</p>
                  </div>
                  <div
                    className={`py-1 px-2 flex text-xs ${
                      count < 2
                        ? 'bg-jams_green'
                        : count < 3
                        ? 'bg-jams_yellow'
                        : 'bg-jams_red'
                    } rounded-full`}
                  >
                    <span>{count}</span>
                    <DefaultUser className='w-4 ml-1' />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default RegisterTeam
