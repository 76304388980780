import { useEffect } from 'react'
import { api } from 'api'
import { ReactComponent as Buildings } from 'Assets/Night/Buildings.svg'
import { ReactComponent as TrainAndTrack } from 'Assets/TrainAndTrack.svg'
import Oauth from 'Pages/Auth/Oauth'
import Register from 'Pages/Auth/Register'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import {
  setAccessToken,
  setBackendToken,
  setRegistered
} from 'Redux/slices/auth'
import { setLoggedInUserDetails } from 'Redux/slices/user'
import { setLoading } from 'Redux/slices/loading'
import RegisterWithEmail from 'Pages/Auth/RegisterWithEmail'

const AuthLayout = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const auth = getAuth()

    const loginAPI = async (token) => {
      try {
        const res = await api
          .post('/api/user/login', {
            id_token: token
          })
          .catch((err) => {
            const errResponse = err.response.data
            if (errResponse.code === 404) {
              dispatch(setRegistered(false))
              history.push('/login/register')
            }
          })

        if (res.data) {
          const { token, user } = res.data?.data
          dispatch(setLoggedInUserDetails(user))
          dispatch(setBackendToken(token))
          dispatch(setRegistered(true))
        }
        history.push('/')
      } catch (err) {
        console.log(err)
      }
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      dispatch(setLoading(true))
      if (user) {
        const { accessToken } = user
        dispatch(setAccessToken(accessToken))
        await loginAPI(accessToken)
      } else {
        dispatch(setAccessToken(null))
      }
      dispatch(setLoading(false))
    })

    return () => unsubscribe()
  }, []); //eslint-disable-line

  return (
    <>
      <Backgrounds />
      <div>
        <Switch>
          <Route path='/login/oauth' component={Oauth} />
          <Route path='/login/email' component={RegisterWithEmail} />
          <Route path='/login/register' component={Register} />
          <Redirect exact from='/login' to='/login/oauth' />
        </Switch>
      </div>
    </>
  )
}

const Backgrounds = () => {
  return (
    <>
      <div className='fixed w-screen h-screen -z-10 bg-gradient-to-b from-jams_purple to-jams_light_purple'>
        <Buildings className='building-bg fixed bottom-0 max-h-screen' />
      </div>
      <TrainAndTrack className='absolute bottom-4' />
    </>
  )
}

export default AuthLayout
