import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { ReactComponent as Devjams } from 'Assets/Night/DevJams Logo.svg'
import { setRegister2 } from 'Redux/slices/register'

const Register2 = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef(null)

  const { address } = useSelector((state) => state.register)

  const [addressline1, addressline2, city, statename, country, pincode] =
    address.split(';')

  return (
    <div className='z-40 bg-indigo-900 w-11/12 md:w-4/5 lg:w-3/5 xl:w-2/5 p-7 text-left rounded-xl flex flex-col mb-6'>
      <Devjams className='w-1/2 mx-auto' />
      <div className=' mt-5'>
        <Formik
          innerRef={ref}
          initialValues={{
            addressline1,
            addressline2,
            city,
            statename,
            country,
            pincode
          }}
          validationSchema={Yup.object({
            addressline1: Yup.string().required('Required'),
            addressline2: Yup.string(),
            city: Yup.string().required('Required'),
            statename: Yup.string().required('Required'),
            country: Yup.string().required('Required'),
            pincode: Yup.string().required('Required')
          })}
          onSubmit={(values) => {
            dispatch(setRegister2(values))
            history.push('/login/register/3')
          }}
        >
          <Form className='text-sm grid gap-y-4'>
            <div>
              <label
                className='formikLabel text-white mt-2'
                htmlFor='addressline1'
              >
                Address Line 1
              </label>
              <Field name='addressline1' className='formikInput' type='text' />
              <ErrorMessage className='text-red-500 w-full' name='addressline1'>
                {(msg) => <div className='err-msg'>{msg}</div>}
              </ErrorMessage>
            </div>
            <div>
              <label
                className='formikLabel text-white mt-4'
                htmlFor='addressline2'
              >
                Address Line 2
              </label>
              <Field name='addressline2' className='formikInput' type='text' />
              <ErrorMessage className='text-red-500 w-full' name='addressline2'>
                {(msg) => <div className='err-msg'>{msg}</div>}
              </ErrorMessage>
            </div>
            <div className='grid sm:grid-cols-2 gap-x-4 gap-y-3'>
              <div>
                <label className='formikLabel text-white mt-2' htmlFor='city'>
                  City
                </label>
                <Field name='city' className='formikInput' type='text' />
                <ErrorMessage name='city'>
                  {(msg) => <div className='err-msg'>{msg}</div>}
                </ErrorMessage>
              </div>
              <div>
                <label
                  className='formikLabel text-white mt-2'
                  htmlFor='statename'
                >
                  State
                </label>
                <Field name='statename' className='formikInput' type='text' />
                <ErrorMessage name='statename'>
                  {(msg) => <div className='err-msg'>{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-x-4 gap-y-3'>
              <div className='sm:col-span-2'>
                <label
                  className='formikLabel text-white mt-2'
                  htmlFor='country'
                >
                  Country
                </label>
                <Field name='country' className='formikInput' type='text' />
                <ErrorMessage name='country'>
                  {(msg) => <div className='err-msg'>{msg}</div>}
                </ErrorMessage>
              </div>
              <div>
                <label
                  className='formikLabel text-white mt-2'
                  htmlFor='pincode'
                >
                  Pincode
                </label>
                <Field name='pincode' className='formikInput' type='text' />
                <ErrorMessage name='pincode'>
                  {(msg) => <div className='err-msg'>{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className='w-2/3 mx-auto flex justify-between mt-2 gap-x-4'>
              <button
                onClick={() => {
                  dispatch(setRegister2(ref.current.values))
                  history.goBack()
                }}
                type='button'
                className='mx-auto w-28 bg-red-500 text-white px-3 py-2 rounded-md'
              >
                Previous
              </button>
              <button
                type='submit'
                className='mx-auto w-28 bg-red-500 text-white px-3 py-2 rounded-md'
              >
                Next
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Register2
