import MarketCard from 'Components/Cards/MarketCard'
import MarketHeader from 'Components/MarketHeader'
import { useSelector } from 'react-redux'
import { headings } from './MarketData'

const Illustrations = () => {
  const { items: illustrationStyle, category_name: categoryName } = useSelector(
    (state) => state.market.catalog.illustrationStyle
  )

  return (
    <div className='w-full'>
      <MarketHeader {...headings.illustration} />
      <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
        {Object.keys(illustrationStyle)?.map((item) => {
          const {
            item_id: id,
            item_name: name,
            item_photo_url: photoURL,
            item_price: price,
            category_id: categoryId,
            item_description: itemDescription,
            isPurchased
          } = illustrationStyle[item]
          return (
            <div key={id}>
              <MarketCard
                itemId={id}
                title={name}
                imgURL={photoURL}
                price={price}
                categoryId={categoryId}
                isPurchased={isPurchased}
                categoryName={categoryName}
                itemDescription={itemDescription}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Illustrations
