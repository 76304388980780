import { isValidPhoneNumber } from 'react-phone-number-input'

const currentDateWithinRange = (from, to) => {
  const currentTime = new Date().getTime()
  const fromTime = new Date(from).getTime()
  const toTime = new Date(to).getTime()

  return currentTime <= toTime && currentTime >= fromTime
}

const validatePhoneNumber = (value) => {
  let error
  if (value && !isValidPhoneNumber(value)) {
    error = 'Invalid Phone Number'
  }
  return error
}

const timeAgo = (current, previous) => {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = Math.abs(current - previous)

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago'
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago'
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago'
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago'
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago'
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago'
  }
}

const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '')

const decamelize = (str) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })

export {
  currentDateWithinRange,
  validatePhoneNumber,
  timeAgo,
  camelize,
  decamelize
}
