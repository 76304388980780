import Popup from 'reactjs-popup'
import GDSC from 'Assets/Logos/GDSC Small.svg'
import { getMessages } from 'Utils/APICalls'
import { useSelector } from 'react-redux'
import { timeAgo } from 'Utils/Helper'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

const Notifications = () => {
  const { config } = useSelector((state) => state.auth)
  const [read, setRead] = useState(false)

  const {
    isLoading,
    error,
    data: messages
  } = useQuery('notifications', async () => {
    const res = await getMessages(config)
    return res
  })

  useEffect(() => {
    if (messages?.length > 0) {
      setRead(false)
    }
  }, [messages])

  return (
    <Popup
      onOpen={() => setRead(true)}
      trigger={
        <button type='submit'>
          <div className='relative'>
            {!read && messages?.length > 0 && (
              <span className='bg-jams_red absolute -top-1 -right-1 text-xs w-2 h-2 rounded-full text-white' />
            )}
            <NotificationIcon />
          </div>
        </button>
      }
      closeOnDocumentClick
      on='click'
      position={`bottom ${window.innerWidth > 768 ? 'right' : 'center'}`}
      contentStyle={{
        padding: 0,
        margin: 0,
        border: 'none',
        backgroundColor: '#5C60CD',
        borderRadius: '10px',
        zIndex: 10000,
        maxheight: '200px',
        position: 'fixed'
      }}
      arrowStyle={{ color: '#5C60CD' }}
    >
      <div className='w-72 h-64 overflow-y-auto sm:w-96 px-6 py-6 text-white text-sm font-sora notifications'>
        <div className='flex justify-between items-center border-dashed border-b-2 pb-3'>
          <div className='text-base font-bold'>Notifications</div>
        </div>
        <div className='text-xs'>
          {isLoading && (
            <p className='text-center pt-3 text-md font-semibold'>
              Loading notifications! 🍞
            </p>
          )}
          {error && (
            <p className='text-center pt-3 text-md font-semibold'>
              Unable to load notifications 😢
            </p>
          )}
          {messages && messages.length > 0
            ? (
              <div className='divide-y-2 divide-gray-200 overflow-y-auto'>
                {messages.map((message) => {
                  return (
                    <div key={message.created_at} className='py-3 sm:py-5'>
                      <div className='flex justify-between items-center'>
                        <div className='flex items-center'>
                          <div className='p-3 bg-jams_purple rounded-full mr-2'>
                            <img
                              src={GDSC}
                              alt='GDSC VIT logo'
                              className='w-4 h-4'
                            />
                          </div>
                          <p className='text-sm font-semibold'>GDSC VIT</p>
                        </div>
                        <p className='text-xs'>
                          {timeAgo(new Date(message.created_at), new Date())}
                        </p>
                      </div>
                      <p className='ml-12 py-2'>{message.message}</p>
                    </div>
                  )
                })}
              </div>
              )
            : (
              <p className='text-center pt-3 text-md font-semibold'>
                All caught up! 😎
              </p>
              )}
        </div>
      </div>
    </Popup>
  )
}

const NotificationIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-8 w-8 bg-jams_notify_purple text-white p-2 rounded-md'
      viewBox='0 0 20 20'
      fill='currentColor'
    >
      <path d='M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z' />
    </svg>
  )
}

export default Notifications
