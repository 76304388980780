import SpeakerCard from 'Components/Cards/SpeakerCard'
import Speakers from 'Data/SpeakersData'
import DineshPunni from 'Assets/Speakers/DineshPunni.jpeg'
import RiddhiDutta from 'Assets/Speakers/RiddhiDutta.jpeg'
import Sudhakar from 'Assets/Speakers/Sudhakar.jpeg'
import PrateekNarang from 'Assets/Speakers/PrateekNarang.jpg'
import AanshulSadaria from 'Assets/Speakers/AanshulSadaria.jpg'

const Timeline = () => {
  return (
    <div className='overflow-hidden'>
      <div className='cards md:pl-24 w-screen overflow-x-auto overflow-y-hidden pt-6 pb-12'>
        <div className='inline-flex gap-x-10 pl-10 md:pl-0 pr-10'>
          <div>
            <SpeakerCard speaker={Speakers.DineshPunni}>
              <img src={DineshPunni} alt='speaker' className='w-36 mt-3 rounded-full' />
            </SpeakerCard>
          </div>
          <div>
            <SpeakerCard speaker={Speakers.Sudhakar}>
              <img src={Sudhakar} alt='speaker' className='w-36 mt-3 rounded-full' />
            </SpeakerCard>
          </div>
          <div>
            <SpeakerCard speaker={Speakers.AanshulSadaria}>
              <img src={AanshulSadaria} alt='speaker' className='w-36 mt-3 rounded-full' />
            </SpeakerCard>
          </div>
          <div>
            <SpeakerCard speaker={Speakers.PrateekNarang}>
              <img src={PrateekNarang} alt='speaker' className='w-36 mt-3 rounded-full' />
            </SpeakerCard>
          </div>
          <div>
            <SpeakerCard speaker={Speakers.RiddhiDutta}>
              <img src={RiddhiDutta} alt='speaker' className='w-36 mt-3 rounded-full' />
            </SpeakerCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timeline
