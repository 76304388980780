import First from 'Assets/Results/First Place.png'
import Second from 'Assets/Results/Second Place.png'
import Third from 'Assets/Results/Third Place.png'
import idea from 'Assets/Results/idea.png'
import fresher from 'Assets/Results/fresher.png'
// import Ren from 'Assets/Devjams/Tracks/Ren.jpg'
// import Alchemy from 'Assets/Devjams/Tracks/Alchemy.jpg'
// import Vicara from 'Assets/Devjams/Tracks/Vicara.jpg'
// import Groww from 'Assets/Devjams/Tracks/Groww.png'
// import CrowdStrike from 'Assets/Devjams/Tracks/CrowdStrike.jpg'

const Results = () => {
  return (
    <>
      <div className='text-white grid bg-jams_dark_blue w-full mx-3 px-4 sm:px-10 py-10 text-justify opacity-95 rounded-lg mb-16 md:mb-0'>
        <div className='flex flex-col gap-4 justify-between items-center mb-4 divide-y-2 divide-gray-600 divide-dashed'>
          <h1 className='text-xl font-bold w-full text-center md:text-left'>Event Winners 🎉</h1>
          <div className='lg:w-full xl:w-11/12 w-10/12 py-10'>
            <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-5 items-center justify-center'>
              <div className='flex flex-col items-center bg-yellow-100 text-black p-5 rounded-md col-span-2 md:col-span-4 lg:col-span-2'>
                <img className='w-16 lg:w-24 mb-3' src={First} alt='first' />
                <p className='lg:text-lg whitespace-nowrap'>Risk_Hai_To_Ishq_Hai</p>
                <p className='md:text-base mt-2 whitespace-nowrap bg-yellow-200 p-3 rounded-md shadow-md'>Rs. 1,00,000 🤯</p>
              </div>
              <div className='flex flex-col items-center bg-gray-100 text-black p-5 rounded-md col-span-2'>
                <img className='w-16 lg:w-24 mb-3' src={Second} alt='second' />
                <p className='lg:text-lg whitespace-nowrap'>Heavy Scam</p>
                <p className='md:text-base mt-2 whitespace-nowrap bg-gray-300 p-3 rounded-md shadow-md'>Rs. 75,000 🥳</p>
              </div>
              <div className='flex flex-col items-center bg-jams_brown text-black p-5 rounded-md col-span-2'>
                <img className='w-16 lg:w-24 mb-3' src={Third} alt='third' />
                <p className='lg:text-lg whitespace-nowrap'>UwU</p>
                <p className='md:text-base mt-2 whitespace-nowrap bg-jams_brown_dark p-3 rounded-md shadow-md'>Rs. 50,000 🤩</p>
              </div>
            </div>
          </div>

          <div className='w-full xl:w-10/12 pt-10'>
            <div className='grid md:grid-cols-2 gap-5'>
              <div className='flex flex-col items-center justify-center'>
                <div className='w-2/3 lg:w-1/2 whitespace-nowrap'>
                  <h3 className='text-center md:text-lg bg-jams_blue px-3 py-1 rounded-full mb-5'>Best Idea</h3>
                </div>
                <div className='flex flex-col w-5/6 lg:w-2/3 items-center whitespace-nowrap md:col-start-5 md:col-span-2 bg-blue-400 text-black p-5 rounded-md'>
                  <img className='w-16 lg:w-24 mb-3' src={idea} alt='idea' />
                  <p className='md:text-lg'>Akatsuki</p>
                  <p className='text-base mt-2 bg-blue-600 text-white p-3 whitespace-nowrap rounded-md shadow-md'>Rs. 15,000 👊</p>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <div className='w-2/3 lg:w-1/2'>
                  <h3 className='text-center md:text-lg bg-jams_blue px-3 py-1 rounded-full mb-5'>Best Freshers</h3>
                </div>
                <div className='flex flex-col w-5/6 lg:w-2/3 items-center whitespace-nowrap md:col-start-5 md:col-span-2 bg-blue-400 text-black p-5 rounded-md'>
                  <img className='w-16 lg:w-24 mb-3' src={fresher} alt='first' />
                  <p className='md:text-lg'>Code Shark</p>
                  <p className='text-base mt-2 bg-blue-600 text-white p-3 whitespace-nowrap rounded-md shadow-md'>Rs. 10,000 👏</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='text-white grid bg-jams_dark_blue w-full mx-3 md:mt-20 px-6 sm:px-10 py-10 text-justify opacity-95 rounded-lg mb-16 md:mb-0'>
        <div className='flex flex-col gap-4 justify-between items-center mb-4 divide-y-2 divide-gray-600 divide-dashed'>
          <h1 className='text-xl font-bold w-full text-center md:text-left'>Sponsored Track Winners 🎊</h1>
          <div className='w-full grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 xl:w-5/6 pt-10'>
            <div className='flex flex-col items-center bg-blue-300 text-black p-5 rounded-md w-11/12 sm:w-5/6 mx-auto md:w-full'>
              <img className='w-16 lg:w-24 mb-3 rounded-full' src={CrowdStrike} alt='vicara' />
              <p className='md:text-lg'>UwU</p>
              <p className='mt-2 bg-blue-600 text-white p-3 rounded-md shadow-md whitespace-nowrap'>Rs. 20,000 👏</p>
            </div>
            <div className='flex flex-col items-center bg-blue-300 text-black p-5 rounded-md w-11/12 sm:w-5/6 mx-auto md:w-full'>
              <img className='w-16 lg:w-24 mb-3 rounded-full' src={Groww} alt='vicara' />
              <p className='md:text-lg'>UwU</p>
              <p className='mt-2 bg-blue-600 text-white p-3 rounded-md shadow-md whitespace-nowrap'>Rs. 20,000 👏</p>
            </div>
            <div className='flex flex-col items-center bg-blue-300 text-black p-5 rounded-md w-11/12 sm:w-5/6 mx-auto md:w-full'>
              <img className='w-16 lg:w-24 mb-3 rounded-full' src={Ren} alt='ren' />
              <p className='md:text-lg'>UwU</p>
              <p className='mt-2 bg-blue-600 text-white p-3 rounded-md shadow-md whitespace-nowrap'>1,000 USD 💲</p>
            </div>
            <div className='flex flex-col items-center bg-blue-300 text-black p-5 rounded-md w-11/12 sm:w-5/6 mx-auto md:w-full'>
              <img className='w-16 lg:w-24 mb-3 rounded-full' src={Alchemy} alt='alchemy' />
              <p className='md:text-lg' />
              <p className='mt-2 bg-blue-600 text-white p-3 rounded-md shadow-md whitespace-nowrap'>Rs. 10,000 👏</p>
            </div>
            <div className='flex flex-col items-center bg-blue-300 text-black p-5 rounded-md w-11/12 sm:w-5/6 mx-auto md:w-full'>
              <img className='w-16 lg:w-24 mb-3 rounded-full' src={Vicara} alt='vicara' />
              <p className='md:text-lg'>invincible coders</p>
              <p className='mt-2 bg-blue-600 text-white p-3 rounded-md shadow-md whitespace-nowrap'>Rs. 30,000 👏</p>
            </div>
            <div className='flex flex-col items-center bg-blue-300 text-black p-5 rounded-md w-11/12 sm:w-5/6 mx-auto md:w-full'>
              <img className='w-16 lg:w-24 mb-3 rounded-full' src={Vicara} alt='vicara' />
              <p className='md:text-lg'>Uchiha</p>
              <p className='mt-2 bg-blue-600 text-white p-3 rounded-md shadow-md whitespace-nowrap'>Rs. 20,000 👏</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Results
