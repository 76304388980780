import { Route, Switch } from 'react-router'
import React from 'react'
import KnockathonLayout from './Layouts/KnockathonLayout'
import events from 'Data/EventsData'
import Home from './Events/Home'
import HexathonLayout from './Layouts/HexathonLayout'
import DevjamsLayout from './Layouts/DevjamsLayout'
import DevtalksLayout from './Layouts/DevtalksLayout'

const Events = () => {
  return (
    <div className='pt-20'>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path={`${events.knockathon.route}`} component={KnockathonLayout} />
        <Route path={`${events.hexathon.route}`} component={HexathonLayout} />
        <Route path={`${events.devjams.route}`} component={DevjamsLayout} />
        <Route path={`${events.devtalks.route}`} component={DevtalksLayout} />
      </Switch>
    </div>
  )
}

export default Events
