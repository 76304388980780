import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import * as Yup from "yup";
import {
  setHexathonChallenge,
  setTeamsSubmission,
} from "Redux/slices/events/hexathon";
import { toast } from "react-toastify";
import { makeSubmission, sendTeamParticipation } from "Utils/APICalls";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const SubmissionPage = () => {
  const { link } = useSelector((state) => state.hexathon.submission);

  const { id } = useSelector(
    (state) => state.hexathon.teamDetails.participation
  );
  const { eventStarted } = useSelector((state) => state.hexathon.eventDetails);
  const { config } = useSelector((state) => state.auth);
  const { isRegistered, submissionMade, teamDetails, eventDetails } =
    useSelector((state) => state.hexathon);

  const dispatch = useDispatch();
  const formikRef = useRef();

  const submissionOfLinks = (values) => {
    const data = {
      participation_id: id,
      submission: {
        ...values,
      },
    };
    if (eventStarted) {
      if (teamDetails.team_size >= eventDetails.member_lower_limit) {
        makeSubmission(data, config).then((success) => {
          if (success) {
            dispatch(setTeamsSubmission(values));
          }
        });
      } else {
        toast.error("Team size is less than limit");
      }
    } else {
      toast.error("We know you're excited but the event hasn't started yet 😥");
    }
  };

  useEffect(() => {
    if (isRegistered && eventStarted) {
      sendTeamParticipation(id, config).then((data) => {
        if (data) {
          const metaChallengeField = data.data?.challenge?.meta;
          const metaSubmissionField = data.data?.submission?.meta;
          dispatch(setHexathonChallenge(metaChallengeField));
          dispatch(setTeamsSubmission(metaSubmissionField));
        }
      });
    }
  }, [id, config, dispatch, isRegistered, eventStarted]);

  return (
    <>
      {isRegistered ? (
        <div className="text-white grid bg-jams_dark_blue w-full mx-3 px-4 sm:px-10 py-10 text-justify opacity-95 rounded-lg divide-y-2 divide-gray-600 divide-dashed">
          <div className="flex flex-col sm:flex-row gap-4 justify-between items-center mb-4">
            <h1 className="text-xl font-bold">Final Submission</h1>
            <Popup
              trigger={
                <button
                  disabled={!eventStarted}
                  className="px-6 py-2 text-xs bg-indigo-400 hover:bg-red-400 opacity-95 text-white font-sora w-auto rounded-md disabled:bg-indigo-300 disabled:cursor-default"
                >
                  {submissionMade ? "Update" : "Submit"}
                </button>
              }
              modal
              contentStyle={{
                maxWidth: "600px",
                width: "90%",
              }}
            >
              {(close) => (
                <div className="bg-indigo-500 px-8 py-10 rounded-xl border-2 border-jams_light_purple text-white font-sora text-sm text-center">
                  <div
                    onClick={close}
                    className="absolute top-5 right-5 font-sora cursor-pointer"
                  >
                    &times;
                  </div>
                  <div>
                    <div className="pb-3 font-bold text-lg">
                      Proceed to submission?
                    </div>
                    <div className="text-sm md:px-7">
                      Don't worry you can submit again until deadline and from
                      there on you can always update your submission
                    </div>
                  </div>
                  <div className="flex justify-center gap-x-4 pt-5">
                    <div>
                      <button
                        disabled={!eventStarted}
                        type="submit"
                        onClick={() => {
                          formikRef.current.handleSubmit();
                          close();
                        }}
                        className="bg-jams_green hover:bg-green-500 px-10 py-1 rounded-md text-sm"
                      >
                        {submissionMade ? "Update" : "Submit"}
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={close}
                        className="bg-blue-700 hover:bg-blue-800 px-10 py-1 text-sm rounded-md"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
          <div className="pt-2">
            <div>
              {submissionMade && (
                <div className="text-xs py-3 text-gray-100">
                  Yayy! you made a submission 🥳. We will be looking into your
                  awesome work soon. Feel free to update your submission in the
                  mean time. Stay tuned for updates!
                </div>
              )}
            </div>
            <div>
              <Formik
                initialValues={{ link }}
                innerRef={formikRef}
                validationSchema={Yup.object({
                  link: Yup.string()
                    .required("Required")
                    .url("Enter a valid URL"),
                })}
                onSubmit={(values) => {
                  submissionOfLinks(values);
                }}
                enableReinitialize
              >
                <Form>
                  <div className="mt-2">
                    <label className="py-3 block mb-2" htmlFor="md">
                      {/* <p className='text-sm mb-1 sm:w-1/3 mx-auto font-bold text-center pt-4 pb-2 border-b-2 border-jams_blue'>
                        Problem Statement
                      </p> */}
                      <p className="leading-relaxed">
                        It's time for your final submissions. Update the final
                        link for your submissions incase the link is different.
                        Happy designing ✨
                      </p>
                    </label>
                  </div>
                  <div>
                    <label
                      className="formikLabel pb-2 block text-white"
                      htmlFor="link"
                    >
                      Submission Link
                    </label>
                    <Field
                      name="link"
                      className="rounded-md w-full h-10 px-3 text-white bg-jams_input_gray"
                      type="text"
                    />
                    <ErrorMessage name="link">
                      {(msg) => <div className="err-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full md:w-1/2 lg:w-1/3 text-sm leading-relaxed text-center text-discord_white bg-jams_dark_blue p-10 rounded-xl">
          You have not registered to the event yet. 😢
          <Link to="/events/hexathon/teams">
            <div className="text-jams_notify_purple font-bold border-2 border-dashed border-jams_notify_purple p-6 my-3 mt-6 rounded-md">
              Create or join a team to make a submission
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default SubmissionPage;
