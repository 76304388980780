import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { accessToken, registered } = useSelector((state) => state.auth)
  const pathname = useLocation().pathname

  return (
    <Route
      {...rest}
      render={(props) => {
        if ((accessToken && registered) || pathname === '/Brochure.pdf') {
          return <Component {...props} />
        } else {
          return <Redirect exact to='/login' />
        }
      }}
    />
  )
}

export default PrivateRoute
