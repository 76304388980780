import { toast } from 'react-toastify'
import { api, marketplaceApi } from 'api'

const fetchParticularEvent = async (id, config) => {
  try {
    const events = await api.get(`/api/event/fetch/${id}`, config)
    return events.data.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to get event details. Contact administator - ${errResponse.message}`
    )
  }
}

const registerUserToEvent = async (eventId, config) => {
  try {
    const res = await api.post(
      '/api/participation/create',
      { event_id: eventId },
      config
    )
    toast.success('Successfully created a team! 🥳')
    return res.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to create team. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const fetchTeamsOfEvent = async (eventId, config) => {
  try {
    const res = await api.get(`/api/participation/teams/${eventId}`, config)
    return res.data.data.teams
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to get event teams. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const joinTeamInEvent = async (code, config) => {
  try {
    const res = await api.post('/api/team/join', { code }, config)
    toast.success('Successfully created a team! 🥳')
    return res.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to join team. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const getTeamDetails = async (code, config) => {
  try {
    const events = await api.get('/api/team/fetch', config)
    return events.data.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to get team details. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const getParticipationDetails = async (config) => {
  try {
    const res = await api.get('/api/user/participation', config)
    return res.data.data.my_participation
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to get participation details. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const removeTeamMember = async (config) => {
  try {
    await api.delete('/api/team/member/remove', config)
    toast.success('Removed member from the team!')
    return true
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to remove member. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const leaveTeam = async (config) => {
  try {
    await api.delete('/api/team/leave', config)
    toast.success('Left them team successfully')
    return true
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to leave team. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const deleteParticipation = async (config) => {
  try {
    await api.delete('/api/participation/remove', config)
    toast.success('Deleted team successfully')
    return true
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to delete participation. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const updateTeamName = async (body, config) => {
  try {
    await api.patch('/api/team/teamName', body, config)
    toast.success('Your team got a new name! 🥳')
    return true
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to update team name. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const sendTeamParticipation = async (id, config) => {
  try {
    const res = await api.post(
      '/api/participation/start',
      { participation_id: id },
      config
    )
    return res.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(`Unable to get participation details - ${errResponse.message}`)
  }
}

const makeSubmission = async (data, config) => {
  try {
    await api.patch('/api/submission/update', data, config)
    toast.success('Submission successfully updated! 🎉')
    return true
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to make submission right now. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const getMessages = async (config) => {
  try {
    const res = await api.get('/api/user/message', config)
    return res.data.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to get messages. Contact administator - ${errResponse.message}`
    )
    console.log(error)
  }
}

const getProfile = async (config) => {
  try {
    const res = await api.get('/api/user/profile', config)
    return res.data.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable get profile. Contact administator - ${errResponse.message}`
    )
  }
}

const updateProfile = async (config, body) => {
  try {
    await api.patch('/api/user/update', body, config)
    toast.success('Successfully updated your profile')
    return true
  } catch (error) {
    const errResponse = error.response.data
    toast.error(
      `Unable to update. Contact administator - ${errResponse.message}`
    )
    return false
  }
}

const getLeaderboard = async () => {
  try {
    const res = await api.get('/api/leaderboard')
    return res.data.data
  } catch (error) {
    toast.error('Unable to get Leaderboard')
    return false
  }
}

const fetchMarketplaceCatalog = async (config) => {
  try {
    const res = await marketplaceApi.get('/api/v1/market/catalog/fetch', config)
    return res.data
  } catch (error) {
    toast.error('Unable to fetch market items fetch marketplace items')
  }
}

const sendMarketplaceCart = async (selectedItems, config) => {
  try {
    const res = await marketplaceApi.post(
      'api/v1/market/selection/submit',
      selectedItems,
      config
    )
    toast.success('Updated cart successfully! 🥳')
    return res.data
  } catch (error) {
    const errResponse = error.response.data
    toast.error(`Unable to update cart - ${errResponse.message}`)
  }
}

export {
  fetchParticularEvent,
  registerUserToEvent,
  joinTeamInEvent,
  fetchTeamsOfEvent,
  getTeamDetails,
  removeTeamMember,
  fetchMarketplaceCatalog,
  sendMarketplaceCart,
  leaveTeam,
  updateTeamName,
  deleteParticipation,
  sendTeamParticipation,
  makeSubmission,
  getMessages,
  getProfile,
  updateProfile,
  getParticipationDetails,
  getLeaderboard
}
