import Diversity from 'Assets/Devjams/Tracks/diversity.png'
import Creativity from 'Assets/Devjams/Tracks/creativity.png'
import Arvr from 'Assets/Devjams/Tracks/arvr.png'
import DigitalAccess from 'Assets/Devjams/Tracks/DigitalAccess.png'
import Sustainability from 'Assets/Devjams/Tracks/sustainability.png'
import ProfTools from 'Assets/Devjams/Tracks/ProfTools.png'
import Innovation from 'Assets/Devjams/Tracks/innovation.png'
import Groww from 'Assets/Devjams/Tracks/Groww.png'
import Vicara from 'Assets/Devjams/Tracks/Vicara.jpg'
import Ren from 'Assets/Devjams/Tracks/Ren.jpg'
import CrowdStrike from 'Assets/Devjams/Tracks/CrowdStrike.jpg'
import Alchemy from 'Assets/Devjams/Tracks/Alchemy.jpg'
import { devjamsTracksData, sponsorTracks } from 'Data/TracksData'

const images = [
  Diversity,
  ProfTools,
  DigitalAccess,
  Arvr,
  Innovation,
  Sustainability,
  Creativity
]

const sponsorImages = [
  CrowdStrike,
  Groww,
  Alchemy,
  Ren,
  Vicara,
  Vicara
]

export const Tracks = () => {
  return (
    <div className='w-full'>
      {devjamsTracksData.map((track, index) => (
        <div
          key={index}
          className='my-4 flex flex-col-reverse lg:flex-row justify-between bg-jams_dark_blue border-4 border-jams_blue rounded-xl text-gray-50 font-sora'
        >
          <div className='p-10'>
            <div className='flex items-center gap-4 font-bold text-lg md:text-xl mb-8'>
              <img
                className='w-8 sm:w-12 h-8 sm:h-12'
                src={images[index]}
                alt='track logo'
              />
              <span className='border-b-2 border-jams_notify_purple leading-loose'>
                {track.title}
              </span>
            </div>
            <div className='leading-relaxed text-sm md:text-base'>
              {track.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export const SponsoredTracks = () => {
  return (
    <div className='w-full md:w-2/3'>
      {sponsorTracks.map((track, index) => (
        <div
          key={index}
          className='my-4 flex flex-col-reverse lg:flex-row justify-between bg-jams_dark_blue border-4 border-jams_blue rounded-xl text-gray-50 font-sora'
        >
          <div className='p-10'>
            <div className='flex items-center gap-4 font-bold text-lg md:text-xl mb-8'>
              <img
                className='w-8 sm:w-12 h-8 sm:h-12 rounded-full'
                src={sponsorImages[index]}
                alt='track logo'
              />
              <span className='border-b-2 border-jams_notify_purple leading-loose'>
                {track.title}
              </span>
              {track.link &&
                <a
                  href={track.link}
                  target='_blank'
                  rel='noreferrer noopener'
                  className='bg-jams_red text-white px-3 py-1 rounded-md text-xs'
                >
                  Read more
                </a>}
              {track.tasks && <span className='bg-jams_red text-white px-3 py-1 rounded-md text-xs'>Choose any task</span>}
            </div>
            <div className={`leading-relaxed mb-2 ${track.tasks ? 'font-semibold md:text-lg' : 'text-sm md:text-base'}`}>
              {track.description}
            </div>
            <div className='leading-relaxed text-sm md:text-base'>
              {track.tasks?.map(task =>
                <p className='py-2'>{task}</p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
