import React, { useRef, useEffect } from 'react'
import 'Styles/Faq.css'
import 'accordion-js/dist/accordion.min.css'
import Accordion from 'accordion-js'

const AccordianElement = ({ color, question, answer }) => {
  return (
    <div className={`ac ${color} overflow-hidden`}>
      <h2 className='ac-header bg-jams_dark_blue'>
        <button className='ac-trigger font-sora md:text-lg'>{question}</button>
      </h2>
      <div className='ac-panel h-28'>
        <p className='ac-text text'>{answer}</p>
      </div>
    </div>
  )
}

const Faq = ({ faq }) => {
  const accordionRef = useRef(null)

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const Acc = new Accordion(accordionRef.current, {
      duration: 200
    })
  }, [])

  return (
    <div
      className='faq dark rounded-xl overflow-hidden font-sora'
      ref={accordionRef}
    >
      {faq.map((el) => {
        return (
          <AccordianElement
            key={el.key}
            color={el.color}
            question={el.question}
            answer={el.answer}
          />
        )
      })}
      <div className='ac red'>
        <h2 className='ac-header bg-jams_dark_blue'>
          <button className='ac-trigger font-sora md:text-lg'>
            I still have some doubts regarding the event. How can I resolve
            them?
          </button>
        </h2>
        <div className='ac-panel h-28'>
          <p className='text'>
            Feel free to ping us on{' '}
            <a
              className='underline'
              target='_blank'
              rel='noopener noreferrer'
              href='https://twitter.com/gdscvit'
            >
              Twitter
            </a>
            , on{' '}
            <a
              className='underline'
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/gdscvitvellore/'
            >
              Instagram
            </a>{' '}
            or you can mail us at{' '}
            <a className='underline' href='mailto:dscvitvellore@gmail.com'>
              dscvitvellore@gmail.com
            </a>{' '}
            at any time of the day. We will try to respond as soon as possible.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Faq
