import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const marketplaceApi = axios.create({
  baseURL: process.env.REACT_APP_MARKETPLACE_API_URL
})

export { api, marketplaceApi }
