import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import PrivateRoute from './PrivateRoute'
import MainLayout from './Pages/Layouts/MainLayout'
import AuthLayout from './Pages/Layouts/AuthLayout'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-number-input/style.css'
import './App.css'

import LoadingScreen from './Components/LoadingScreen'
import UserLayout from './Pages/Layouts/UserLayout'
import LeaderboardLayout from './Pages/Layouts/LeaderboardLayout'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000
    }
  }
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ToastContainer
          theme='colored'
          toastClassName='font-sora text-sm'
          position='top-center'
          autoClose={3000}
        />
        <LoadingScreen />
        <div className='font-sora'>
          <Switch>
            <Route path='/login' component={AuthLayout} />
            <PrivateRoute path='/user/profile' component={UserLayout} />
            <PrivateRoute path='/leaderboard' component={LeaderboardLayout} />
            <PrivateRoute path='/' component={MainLayout} />
          </Switch>
        </div>
      </Router>
    </QueryClientProvider>
  )
}

export default App
