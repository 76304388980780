import { createSlice } from '@reduxjs/toolkit'

const user = {
  loginDetails: {}
}

export const userSlice = createSlice({
  name: 'user',
  initialState: user,
  reducers: {
    setLoggedInUserDetails: (state, action) => {
      state.loginDetails = action.payload
    }
  }
})

export const { setLoggedInUserDetails } = userSlice.actions

export default userSlice.reducer
