import MarketCard from 'Components/Cards/MarketCard'
import MarketHeader from 'Components/MarketHeader'
import { useSelector } from 'react-redux'
import { headings } from './MarketData'

const ProblemStatement = () => {
  const { items: problemStatement, category_name: categoryName } = useSelector(
    (state) => state.market.catalog.problemStatement
  )

  return (
    <div className='w-full'>
      <MarketHeader {...headings.problemStatement} />
      <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
        {Object.keys(problemStatement)?.map((item) => {
          const {
            item_id: id,
            item_name: name,
            item_photo_url: photoURL,
            item_price: price,
            category_id: categoryId,
            item_description: itemDescription,
            isPurchased
          } = problemStatement[item]
          return (
            <div key={id}>
              <MarketCard
                title={name}
                imgURL={photoURL}
                price={price}
                categoryId={categoryId}
                isPurchased={isPurchased}
                categoryName={categoryName}
                itemDescription={itemDescription}
                itemId={id}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProblemStatement
