import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { ReactComponent as DevJamsLogo } from 'Assets/Logos/DevjamsLogo.svg'
import GoogleLogo from 'Assets/Logos/GoogleLogo.svg'
import AppleLogo from 'Assets/Logos/AppleLogo.svg'
import { APPLE_PROVIDER, GOOGLE_PROVIDER } from 'Utils/Constants'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import PasswordShowHide from 'Components/PasswordShowHide'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ForgotPassword from './ForgotPassword'

const Oauth = () => {
  const loginUser = (type) => {
    const auth = getAuth()
    let provider = null

    if (type === APPLE_PROVIDER) {
      provider = new OAuthProvider('apple.com')
    } else if (type === GOOGLE_PROVIDER) {
      provider = new GoogleAuthProvider()
    }

    if (provider) {
      signInWithPopup(auth, provider).catch((err) => {
        console.log(err)
        toast.error(`Unable to login with oauth - ${err.message}`)
      })
    }
  }

  const loginWithEmail = async ({ email, password }) => {
    try {
      const auth = getAuth()
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      console.log(error)
      toast.error(`Unable to login with email - ${error.message}`)
    }
  }

  return (
    <div className='grid place-items-center w-screen h-screen'>
      <div className='relative bg-indigo-900 px-3 py-3 flex flex-col justify-center items-center w-11/12 md:w-4/5 lg:w-3/4 xl:w-1/2 rounded-xl'>
        <DevJamsLogo className='w-72 h-auto mb-5 px-6' />
        <div className='grid lg:grid-cols-2 place-items-center w-full gap-x-10'>
          <div className='px-3 md:px-6 pb-6 w-full'>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Required'),
                password: Yup.string().matches()
              })}
              onSubmit={(values) => {
                loginWithEmail(values)
              }}
            >
              <Form className='grid'>
                <div>
                  <label className='formikLabel' htmlFor='email'>
                    Email ID
                  </label>
                  <Field className='formikInput' name='email' type='email' />
                  <ErrorMessage name='email'>
                    {(p) => <div className='err-msg'>{p}</div>}
                  </ErrorMessage>
                </div>
                <div className='mt-2'>
                  <label className='formikLabel' htmlFor='password'>
                    Password
                  </label>
                  <Field name='password' component={PasswordShowHide} />
                  <ErrorMessage name='password'>
                    {(p) => <div className='err-msg'>{p}</div>}
                  </ErrorMessage>
                </div>
                <ForgotPassword />
                <div className='flex justify-center'>
                  <button
                    type='submit'
                    className='bg-jams_red font-bold text-white px-6 py-2 rounded-md mt-7 text-sm mr-3'
                  >
                    Login
                  </button>
                  <Link to='/login/email'>
                    <button
                      type='submit'
                      className='bg-jams_notify_purple font-bold text-white px-6 py-2 rounded-md mt-7 text-sm mr-3'
                    >
                      Register
                    </button>
                  </Link>
                </div>
              </Form>
            </Formik>
          </div>
          <div className='grid sm:gap-y-3 place-items-center lg:relative lg:bottom-10 py-3'>
            <button
              onClick={() => loginUser(GOOGLE_PROVIDER)}
              className='bg-white flex items-center justify-start mb-3 rounded-sm w-56'
            >
              <img src={GoogleLogo} alt='google-logo' className='w-11' />
              <div className='text-base font-roboto px-3'>
                Sign in with Google
              </div>
            </button>
            <button
              onClick={() => loginUser(APPLE_PROVIDER)}
              className='bg-white google-login-btn flex items-center justify-start rounded-sm overflow-hidden w-56'
            >
              <img src={AppleLogo} alt='Apple-logo' className='ml-2' />
              <div className='text-base font-roboto px-4'>
                Sign in with Apple
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Oauth
