import React from 'react'
import NavbarLink from 'Components/NavbarLink'
import { ReactComponent as InfoStar } from 'Assets/Logos/Navbar/info-star.svg'
import { ReactComponent as TimelineLogo } from 'Assets/Logos/Navbar/Timeline.svg'
import { useRouteMatch } from 'react-router'

const EventsNavbar = () => {
  const match = useRouteMatch()

  return (
    <div className='fixed flex items-center max-w-xs bottom-0 bg-jams_dark_blue left-1/2 transform -translate-x-1/2 overflow-hidden rounded-t-xl md:rounded-tl-none md:rounded-r-xl md:translate-x-0 md:left-0 md:bottom-auto md:top-1/2 md:-translate-y-1/2 z-60 border-2 border-b-0 border-jams_input_gray md:border-none'>
      <div className='w-64 flex justify-center md:flex-col md:w-20'>
        <NavbarLink
          icon={() => <InfoStar className='m-1 w-8 fill-current' />}
          name='Info'
          next={`${match.url}/info`}
        />
        <NavbarLink
          icon={() => <TimelineLogo className='m-1 w-8 fill-current' />}
          name='Timeline'
          next={`${match.url}/timeline`}
        />
      </div>
    </div>
  )
}

export default EventsNavbar
