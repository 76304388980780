import { useRef, useEffect } from 'react'
import KnockathonCard from 'Components/Cards/KnockathonCard'
import HexathonCard from 'Components/Cards/HexathonCard'
import CTFCard from 'Components/Cards/CTFCard'
import DevtalksCard from 'Components/Cards/DevtalksCard'
import DevJamsCard from 'Components/Cards/DevJamsCard'

const Home = () => {
  const hexathonRef = useRef(null)
  const devjamsRef = useRef(null)
  const devtalksRef = useRef(null)
  const cardsRef = useRef(null)

  useEffect(() => {
    if (hexathonRef) {
      const cardLeft = hexathonRef.current.getBoundingClientRect().left
      if (cardsRef) {
        cardsRef.current.scrollLeft = cardLeft - 20
      }
    }
  }, [])

  return (
    <div className='overflow-hidden -mt-10'>
      <DevJamsCard />
      <div ref={cardsRef} className='cards w-screen overflow-x-auto overflow-y-hidden pt-6 pb-12'>
        <div className='inline-flex gap-x-10 px-5 lg:px-12 2xl:flex 2xl:justify-around'>
          <CTFCard />
          <div ref={devjamsRef}>
            <KnockathonCard />
          </div>
          <div ref={devtalksRef}>
            <DevtalksCard />
          </div>
          <div ref={hexathonRef}>
            <HexathonCard />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
