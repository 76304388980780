import events from 'Data/EventsData'
import { CurrentEventGrid } from './Grids'
import { ReactComponent as KnockathonsLogo } from 'Assets/Logos/Knockathons Logo.svg'
import { useDispatch, useSelector } from 'react-redux'

import { fetchParticularEvent } from 'Utils/APICalls'
import { useEffect } from 'react'
import {
  setKnockathonsEventDetails,
  setKnockathonsTeamDetails,
  setRegisteredForKnockathons
} from 'Redux/slices/events/knockathon'

const KnockathonsCard = () => {
  const dispatch = useDispatch()

  const { isRegistered, eventDetails } = useSelector(
    (state) => state.knockathons
  )
  const { config } = useSelector((state) => state.auth)

  const eventId = process.env.REACT_APP_KNOCKATHON_EVENT_ID

  useEffect(() => {
    // need to event fetch everytime
    if (eventId && eventDetails.id === '') {
      fetchParticularEvent(eventId, config).then((data) => {
        if (data) {
          const {
            is_registered: isRegistered,
            event,
            participation,
            team_size: teamSize
          } = data
          dispatch(setRegisteredForKnockathons(isRegistered))
          dispatch(setKnockathonsEventDetails(event))
          dispatch(setKnockathonsTeamDetails({ participation, teamSize }))
        }
      })
    }
  }, []); //eslint-disable-line

  return (
    <>
      <CurrentEventGrid
        event={events.knockathon}
        isRegistered={isRegistered}
        eventId={eventId}
        teamCreationRequired
        buttonText='View'
      >
        <KnockathonsLogo className='w-52' />
      </CurrentEventGrid>
    </>
  )
}

export default KnockathonsCard
