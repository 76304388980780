import events from 'Data/EventsData'
import { ComingSoonGrid } from './Grids'
import { ReactComponent as CTFLogo } from 'Assets/Logos/CTF Logo.svg'

const CTFCard = () => {
  return (
    <ComingSoonGrid event={events.ctf} status='Registrations Closed'>
      <CTFLogo className='w-52' />
    </ComingSoonGrid>
  )
}

export default CTFCard
