import First from 'Assets/Results/First Place.png'
import Second from 'Assets/Results/Second Place.png'

const Results = () => {
  return (
    <>
      <div className='text-white grid bg-jams_dark_blue w-full mx-3 px-4 sm:px-10 py-10 text-justify opacity-95 rounded-lg mb-16 md:mb-0 border-4 border-jams_blue'>
        <div className='flex flex-col gap-4 justify-between items-center mb-4 divide-y-2 divide-gray-600 divide-dashed'>
          <h1 className='text-xl font-bold w-full text-center md:text-left'>
            Winners 🎉
          </h1>
          <div className='w-full pt-10'>
            <p className='text-base text-center mb-6 lg:w-11/12 lg:mx-auto'>
              Heartiest of congratulations to both the teams who made it to the
              finals. After a heated debate that lasted more than an hour, we
              finally have our winner for Knockathons 2021 !!
            </p>
            <div className='flex flex-col mx-auto md:grid md:grid-cols-2 w-full px-2 lg:w-4/6 items-center md: gap-6'>
              <div className='flex flex-col items-center w-full h-full p-5 bg-yellow-100 rounded-md'>
                <img className='w-16 lg:w-24 mb-3' src={First} alt='first' />
                <p className='md:text-lg text-black'>Team BruteForce</p>
              </div>
              <div className='flex flex-col items-center w-full h-full p-5 bg-gray-100 rounded-md'>
                <img className='w-16 lg:w-24 mb-3' src={Second} alt='first' />
                <p className='md:text-lg text-center text-black'>
                  Team Confused UngaBunga
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Results
