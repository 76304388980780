import { Switch, useRouteMatch, Route, Redirect } from 'react-router'
import EventsNavbar from 'Pages/Events/EventsNavbar'
import Info from 'Pages/Events/Info'
import Teams from 'Pages/Events/Devjams/Teams'
import Timeline from 'Pages/Events/Timeline'
import Submission from 'Pages/Events/Devjams/Submission'
import Results from 'Pages/Events/Devjams/Results'
import { ReactComponent as Devjams } from 'Assets/Logos/DevjamsLogo.svg'
import { ReactComponent as TracksIcon } from 'Assets/Devjams/color-swatch.svg'
import DJPrizes from 'Assets/DJprizes.png'
import faq from 'Data/FaqData'
import events from 'Data/EventsData'
import NavbarLink from 'Components/NavbarLink'
import { Tracks, SponsoredTracks } from 'Pages/Events/Devjams/Tracks'

const TracksLink = () => {
  const match = useRouteMatch()

  return (
    <NavbarLink
      icon={() => <TracksIcon className='m-1 w-8 fill-current' />}
      name='Tracks'
      next={`${match.url}/tracks`}
    />
  )
}

const SponsorTracksLink = () => {
  const match = useRouteMatch()

  return (
    <NavbarLink
      icon={() => <TracksIcon className='m-1 w-8 fill-current' />}
      name='Sponsors'
      next={`${match.url}/sponsortracks`}
    />
  )
}

const DevjamsLayout = () => {
  const match = useRouteMatch()

  return (
    <div className='w-screen'>
      <EventsNavbar devjams={TracksLink} sponsor={SponsorTracksLink} />
      <div className='w-11/12 md:w-3/4 xl:w-5/7 mx-auto grid place-items-center text-sm pb-28'>
        <Switch>
          <Route exact path={`${match.url}/timeline`} component={Timeline} />
          <Route
            exact
            path={`${match.url}/info`}
            render={() => (
              <Info faq={faq.devjams} details={events.devjams.content} prizes={DJPrizes}>
                <Devjams className='w-44 sm:w-60' />
              </Info>
            )}
          />
          <Route
            exact
            path={`${match.url}/tracks`}
            render={() => <Tracks />}
          />
          <Route
            exact
            path={`${match.url}/sponsortracks`}
            render={() => <SponsoredTracks />}
          />
          <Route exact path={`${match.url}/teams`} component={Teams} />
          <Route exact path={`${match.url}/results`} component={Results} />
          <Route
            exact
            path={`${match.url}/submission`}
            component={Submission}
          />
          <Redirect exact from={`${match.url}`} to={`${match.url}/results`} />
        </Switch>
      </div>
    </div>
  )
}

export default DevjamsLayout
