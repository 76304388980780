import { getAuth, signOut } from 'firebase/auth'
import { toast } from 'react-toastify'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { ReactComponent as GDSCLogoNight } from 'Assets/Logos/GDSC Logo Night.svg'
import { ReactComponent as UserIcon } from 'Assets/user.svg'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Notifications from './Notifications'
import Popup from 'reactjs-popup'
import { emptyDevjamsDetails } from 'Redux/slices/events/devjams'
import { emptyRegistrationDetails } from 'Redux/slices/register'
import { emptyHexathonDetails } from 'Redux/slices/events/hexathon'

const Navbar = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const isNotProfilePage = path !== '/user/profile'
  const isNotLeaderboard = path !== '/leaderboard'
  const isNotHomePage = path !== '/'

  const [navBg, setNavBg] = useState(false)
  const { registered } = useSelector((state) => state.auth)

  const auth = getAuth()

  const logOutUser = () => {
    signOut(auth)
      .then(() => {
        if (match.url !== '/login/oauth') {
          history.push('/login/oauth')
        }
        dispatch(emptyDevjamsDetails())
        dispatch(emptyRegistrationDetails())
        dispatch(emptyHexathonDetails())
        toast.success('Logged out successfully. We will miss you 😢')
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleProfile = () => {
    if (isNotProfilePage) {
      history.push('/user/profile')
    }
  }

  const handleLeaderboard = () => {
    if (isNotLeaderboard) {
      history.push('/leaderboard')
    }
  }

  useEffect(() => {
    const navbarBg = () => {
      if (window.scrollY > 0) setNavBg(true)
      else setNavBg(false)
    }
    window.addEventListener('scroll', navbarBg)
    return () => window.removeEventListener('scroll', navbarBg)
  })

  return (
    <div
      className={`fixed top-0 w-full py-3 ${
        navBg ? 'bg-jams_dark_purple' : 'bg-transparent'
      } z-60`}
    >
      <div className='flex justify-between items-center relative'>
        {isNotHomePage ? (
          <Link to='/' className='text-white px-2 text-sm'>
            <div className='flex place-items-center font-bold'>
              <ChervonLeft />
              <span>Back</span>
            </div>
          </Link>
        ) : (
          <a
            href='https://dscvit.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='invisible sm:visible'
          >
            <GDSCLogoNight className='w-10 sm:w-72 px-3 z-50 left-6 transition-all ease-in-out duration-300 top-5' />
          </a>
        )}
        <div className='flex gap-x-2 md:gap-x-4 items-center justify-end mr-32 md:mr-36 lg:mr-40'>
          <Popup
            trigger={
              <div>
                {auth.currentUser?.photoURL ? (
                  <img
                    referrerPolicy='no-referrer'
                    src={auth.currentUser.photoURL}
                    alt='profile-pic'
                    className='rounded-full w-12 h-12 cursor-pointer'
                  />
                ) : (
                  <div className='bg-jams_notify_purple p-3 rounded-full'>
                    <UserIcon className='w-6 h-6 text-white' />
                  </div>
                )}
              </div>
            }
            position='bottom center'
            on='click'
            closeOnDocumentClick
            mouseEnterDelay={0}
            contentStyle={{
              padding: 0,
              margin: '6px 0',
              border: 'none',
              backgroundColor: '#5C60CD',
              borderRadius: '10px',
              position: 'fixed'
            }}
            arrow={false}
          >
            <div className='w-40 text-white text-sm font-sora'>
              <div className='flex flex-col items-center gap-y-3 p-4'>
                {registered && (
                  <div className='w-full'>
                    <div
                      onClick={handleProfile}
                      className='w-full text-center py-2 cursor-pointer font-bold bg-indigo-400 rounded-md text-xs'
                    >
                      Profile Page
                    </div>
                    <div
                      onClick={handleLeaderboard}
                      className='w-full text-center py-2 cursor-pointer font-bold bg-indigo-400 rounded-md text-xs mt-3'
                    >
                      Leaderboard
                    </div>
                  </div>
                )}
                <div className='w-full text-center mx-auto'>
                  <button
                    onClick={logOutUser}
                    className='cursor-pointer w-full text-xs px-5 py-2 rounded-md font-bold font-sora text-white bg-red-500 border-red-500 hover:bg-white hover:text-red-500 transition-all duration-300 ease-in-out whitespace-nowrap'
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </Popup>
          {registered && <Notifications />}
        </div>
      </div>
    </div>
  )
}

const ChervonLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-5 w-5'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M15 19l-7-7 7-7'
      />
    </svg>
  )
}

export default Navbar
