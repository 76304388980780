import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { ReactComponent as Devjams } from 'Assets/Night/DevJams Logo.svg'
import { setRegister3 } from 'Redux/slices/register'
import SelectField from 'Components/SelectField'
import { schoolOptions, degreeOptions, streamOptions } from 'Data/DropdownData'

const Register3 = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef(null)

  const {
    reg_no: regno,
    college,
    degree,
    stream,
    graduation_year: graduationYear
  } = useSelector((state) => state.register)

  return (
    <div className='z-40 bg-indigo-900 w-11/12 md:w-4/5 lg:w-3/5 xl:w-2/5 p-7 text-left rounded-xl flex flex-col'>
      <Devjams className='w-1/2 mx-auto' />
      <div className=' mt-5'>
        <Formik
          innerRef={ref}
          initialValues={{
            regno,
            college,
            degree,
            stream,
            graduationYear
          }}
          validationSchema={Yup.object({
            regno: Yup.string().required('Required'),
            college: Yup.string().required('Required'),
            degree: Yup.string().required('Required'),
            stream: Yup.string().required('Required'),
            graduationYear: Yup.string().required('Required')
          })}
          onSubmit={(values) => {
            dispatch(setRegister3(values))
            history.push('/login/register/4')
          }}
        >
          <Form className='text-sm'>
            <div className='mt-3'>
              <label className='formikLabel text-white mt-2' htmlFor='regno'>
                Roll Number
              </label>
              <Field name='regno' className='formikInput py-1' type='text' />
              <ErrorMessage name='regno'>
                {(msg) => <div className='err-msg'>{msg}</div>}
              </ErrorMessage>
            </div>

            <div className='mt-3'>
              <label className='formikLabel' htmlFor='college'>
                School
              </label>
              <Field
                name='college'
                component={SelectField}
                options={schoolOptions}
                placeholder=''
              />
              <ErrorMessage name='college'>
                {(msg) => <div className='err-msg'>{msg}</div>}
              </ErrorMessage>
            </div>

            <div className='grid md:grid-cols-3 gap-x-4'>
              <div className='mt-3 md:col-span-2'>
                <label className='formikLabel' htmlFor='degree'>
                  Current Level of Study
                </label>
                <Field
                  name='degree'
                  component={SelectField}
                  options={degreeOptions}
                  placeholder=''
                />
                <ErrorMessage name='degree'>
                  {(msg) => <div className='err-msg'>{msg}</div>}
                </ErrorMessage>
              </div>

              <div className='mt-3'>
                <label className='formikLabel' htmlFor='graduationYear'>
                  Graduation Year
                </label>
                <Field
                  name='graduationYear'
                  className='formikInput'
                  type='text'
                />
                <ErrorMessage name='graduationYear'>
                  {(msg) => <div className='err-msg'>{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className='mt-3'>
              <label className='formikLabel' htmlFor='stream'>
                Stream
              </label>
              <Field
                name='stream'
                component={SelectField}
                options={streamOptions}
                placeholder=''
              />
              <ErrorMessage name='stream'>
                {(msg) => <div className='err-msg'>{msg}</div>}
              </ErrorMessage>
            </div>
            <div className='w-2/3 mx-auto flex justify-between gap-x-4'>
              <button
                onClick={() => {
                  dispatch(setRegister3(ref.current.values))
                  history.goBack()
                }}
                type='button'
                className='mx-auto w-28 bg-red-500 text-white px-3 py-2 rounded-md mt-7'
              >
                Previous
              </button>
              <button
                type='submit'
                className='mx-auto w-28 bg-red-500 text-white px-3 py-2 rounded-md mt-7'
              >
                Next
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Register3
