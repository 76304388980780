import events from 'Data/EventsData'
import { CurrentEventGrid, ComingSoonGrid } from './Grids'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchParticularEvent } from 'Utils/APICalls'
import { ReactComponent as HexathonLogo } from 'Assets/Logos/Hexathon Logo.svg'
import {
  setHexathonEventDetails,
  setHexathonTeamDetails,
  setRegisteredForHexathon
} from 'Redux/slices/events/hexathon'

const HexathonCard = () => {
  const eventId = process.env.REACT_APP_HEXATHON_EVENT_ID
  const dispatch = useDispatch()
  const { isRegistered, eventDetails, submissionMade } = useSelector(
    (state) => state.hexathon
  )
  const { config } = useSelector((state) => state.auth)

  useEffect(() => {
    // need to event fetch everytime
    if (eventId && eventDetails.id === '') {
      fetchParticularEvent(eventId, config).then((data) => {
        if (data) {
          const {
            is_registered: isRegistered,
            event,
            participation,
            team_size: teamSize
          } = data
          dispatch(setRegisteredForHexathon(isRegistered))
          dispatch(setHexathonEventDetails(event))
          dispatch(setHexathonTeamDetails({ participation, teamSize }))
        }
      })
    }
  }, []); //eslint-disable-line

  return (
    // <>
    //   <CurrentEventGrid
    //     event={events.hexathon}
    //     isRegistered={isRegistered}
    //     eventId={eventId}
    //     teamCreationRequired
    //     buttonText={
    //       isRegistered ? (submissionMade ? 'View' : 'Submit') : 'Register'
    //     }
    //   >
    //     <HexathonLogo className='w-36 mt-3' />
    //   </CurrentEventGrid>
    // </>
    <>
      {eventDetails.registrationStarted || isRegistered ? (
        <CurrentEventGrid
          event={events.hexathon}
          isRegistered={isRegistered}
          eventId={eventId}
          teamCreationRequired
          buttonText={
            isRegistered ? (submissionMade ? 'View' : 'Submit') : 'Register'
          }
        >
          <HexathonLogo className='w-36 mt-3' />
        </CurrentEventGrid>
      ) : (
        <ComingSoonGrid
          event={events.hexathon}
          status={
            eventDetails.registrationStarted ? false : 'Registrations Closed'
          }
        >
          <HexathonLogo className='w-36' />
        </ComingSoonGrid>
      )}
    </>
  )
}

export default HexathonCard
