import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setKnockathonsLeaderboard } from 'Redux/slices/events/knockathon'
import { getLeaderboard } from 'Utils/APICalls'
import Navbar from 'Components/Navbar'
// import Pagination from './Leaderboard/Pagination'
import ReactPaginate from 'react-paginate'
import { ReactComponent as Buildings } from 'Assets/Night/Buildings.svg'
import { setLoading } from 'Redux/slices/loading'

function LeaderboardLayout () {
  const [currentPage, setCurrentPage] = useState(1)
  const pagination = useRef()
  const dispatch = useDispatch()
  const { leaderboard } = useSelector((state) => state.knockathons)

  useEffect(() => {
    dispatch(setLoading(true))
    getLeaderboard().then((res) => {
      const leaderboard = res
      if (leaderboard) {
        leaderboard.sort((a, b) => {
          return a.score - b.score
        })
        dispatch(setKnockathonsLeaderboard(leaderboard))
      }
    })
    dispatch(setLoading(false))
  }, [dispatch])

  const paginate = ({ selected: pageNumber }) => {
    setCurrentPage(pageNumber + 1)
  }

  const usersPerPage = 6
  const indexOfLastUser = currentPage * usersPerPage
  const indexOfFirstUser = indexOfLastUser - usersPerPage
  const currentUsers = leaderboard.slice(indexOfFirstUser, indexOfLastUser)

  let id = indexOfFirstUser
  let position = 0
  let previousScore = -1

  return (
    <>
      <div className='fixed w-screen h-screen -z-10 bg-gradient-to-b from-jams_purple to-jams_light_purple'>
        <Buildings className='building-bg fixed bottom-0 max-h-screen' />
      </div>
      <div className='h-screen' style={{ display: 'flex', flexFlow: 'column' }}>
        <Navbar />
        <div className='flex-grow pt-10'>
          <div className='flex items-center h-full'>
            <div className='w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto grid place-items-center text-sm'>
              <div className='grid bg-jams_dark_blue w-full mx-3 py-5 sm:px-5 md:px-12 rounded-lg mt-6 border-4 border-jams_blue'>
                <div className='text-white text-xl border-b-2 border-jams_blue font-semibold m-6'>
                  Leaderboard
                </div>
                <div className='grid grid-cols-3 md:grid-cols-4 col-span-3 place-items-center text-sm'>
                  <p className='text-indigo-300 font-semibold py-3'>Place</p>
                  <p className='text-indigo-300 font-semibold py-3 md:col-span-2'>
                    Participant
                  </p>
                  <p className='text-indigo-300 font-semibold py-3'>Score</p>
                </div>
                {currentUsers &&
                  currentUsers.length > 0 &&
                  currentUsers.map((user) => {
                    id += 1
                    if (previousScore !== user.score) {
                      position += 1
                      previousScore = user.score
                    }
                    return (
                      <div
                        className='col-span-3 grid grid-cols-3 md:grid-cols-4 text-gray-100 py-3 place-items-center'
                        key={id}
                      >
                        <p>{position}</p>
                        <div className='flex items-center md:col-span-2 md:ml-12 text-center w-full md:text-left'>
                          <img
                            src={user.photo_url}
                            alt='user'
                            referrerPolicy='no-referrer'
                            className='hidden md:block rounded-full w-12 mr-3'
                          />
                          <p className='text-sm mx-auto md:mx-0'>
                            {user.first_name + ' ' + user.last_name}
                          </p>
                        </div>
                        <p>{user.score}</p>
                      </div>
                    )
                  })}
                <div className='col-span-3 md:grid-cols-4'>
                  <ReactPaginate
                    ref={pagination}
                    pageCount={leaderboard.length / usersPerPage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={paginate}
                    previousLabel='←'
                    nextLabel='→'
                    containerClassName='flex justify-center items-center gap-x-2 text-white py-3 rounded-md'
                    activeClassName='bg-jams_blue rounded-full'
                    pageLinkClassName=' p-3'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaderboardLayout
