export const headings = {
  home: {
    title: 'Marketplace',
    text: "Sale! Buy everything you need using your hexcoins. Psst saving them won't take you to the moon",
    limit: ''
  },
  problemStatement: {
    title: 'Problem Statement',
    text: 'Choose any one design challenge from the ones available!',
    limit: '1'
  },
  font: {
    title: 'Fonts',
    text: 'You can only use purchased fonts in your design. You may purchase multiple fonts.',
    limit: '2'
  },
  theme: {
    title: 'Theme',
    text: 'Choose a single theme, which has to be followed throughout your designs.',
    limit: '1'
  },
  illustration: {
    title: 'Illustration Styles',
    text: 'You have to stick to this style of illustrations for your designs. You may use pre-made illustrations unless you decide to take up a challenge which prohibits you from using pre-made illustrations.',
    limit: '1'
  },
  color: {
    title: 'Color Palette',
    text: 'Choose a single color palette for your designs.',
    limit: '1'
  },
  challenges: {
    title: 'Challenges (optional)',
    text: 'Take up a challenge to earn some extra Hexcoins! You can use these to purchase expensive elements from the marketplace.',
    limit: '1'
  }
}
