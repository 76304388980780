import React, { useState, useEffect } from 'react'
import Navbar from 'Components/Navbar'
import { ReactComponent as Buildings } from 'Assets/Night/Buildings.svg'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'
import SelectField from 'Components/SelectField'
import {
  genderOptions,
  shirtSizeOptions,
  schoolOptions,
  degreeOptions,
  streamOptions
} from 'Data/DropdownData'
import { getProfile, updateProfile } from 'Utils/APICalls'
import { setRegister } from 'Redux/slices/register'
import { getAuth } from '@firebase/auth'
import PhoneNumber from 'Components/PhoneNumber'
import { validatePhoneNumber } from 'Utils/Helper'

const UserLayout = () => {
  const dispatch = useDispatch()
  const [detailsLoad, setDetailsLoad] = useState(false)
  const { config } = useSelector((state) => state.auth)
  const {
    first_name: firstName,
    last_name: lastName,
    phone_number: phone,
    gender,
    age,
    t_shirt_size: tshirt,
    address,
    reg_no: regno,
    college,
    degree,
    stream,
    graduation_year: graduationYear
  } = useSelector((state) => state.register)
  const [addressline1, addressline2, city, statename, country, pincode] =
    address.split(';')

  const auth = getAuth()
  const [detailsChange, setDetailsChange] = useState(false)

  useEffect(() => {
    if (!detailsLoad) {
      getProfile(config).then((res) => {
        const {
          first_name: firstName,
          last_name: lastName,
          phone_number: phone,
          gender,
          age,
          t_shirt_size: tshirt,
          address,
          reg_no: regno,
          college,
          degree,
          stream,
          graduation_year: graduationYear
        } = res
        const body = {
          firstName,
          lastName,
          phone,
          gender,
          age,
          tshirt,
          address,
          regno,
          college,
          degree,
          stream,
          graduationYear
        }
        dispatch(setRegister(body))
      })
      setDetailsLoad(true)
    }
  }, [config, dispatch, firstName, detailsLoad])

  const rdq = (str) => str.replaceAll(';', '')

  const handleDetailsUpdate = (values) => {
    let {
      firstName,
      lastName,
      phone,
      gender,
      age,
      tshirt,
      addressline1,
      addressline2,
      city,
      statename,
      country,
      pincode,
      regno,
      college,
      degree,
      stream,
      graduationYear
    } = values

    addressline1 = rdq(addressline1)
    addressline2 = rdq(addressline2)
    city = rdq(city)
    statename = rdq(statename)
    country = rdq(country)
    pincode = rdq(pincode)

    const address = `${addressline1};${addressline2};${city};${statename};${country};${pincode}`

    const body = {
      updates: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        gender,
        age,
        t_shirt_size: tshirt,
        address,
        reg_no: regno,
        college,
        degree,
        stream,
        graduation_year: graduationYear
      }
    }
    updateProfile(config, body)
  }

  return (
    <>
      <div className='fixed w-screen h-screen -z-10 bg-gradient-to-b from-jams_purple to-jams_light_purple'>
        <Buildings className='building-bg fixed bottom-0 max-h-screen' />
      </div>
      <div
        className='h-auto pb-5'
        style={{ display: 'flex', flexFlow: 'column' }}
      >
        <Navbar />
        <div className='flex-grow pt-10'>
          <div className='h-full w-full flex flex-col items-center justify-center font-sora mt-5 pt-10'>
            <div className='z-40 sm:top-0 bg-indigo-900 border-4 border-jams_blue w-11/12 md:w-4/5 lg:w-3/5 xl:w-2/5 p-7 text-left rounded-xl flex flex-col items-center'>
              <Formik
                initialValues={{
                  firstName,
                  lastName,
                  phone,
                  gender,
                  age,
                  tshirt,
                  addressline1,
                  addressline2,
                  city,
                  statename,
                  country,
                  pincode,
                  regno,
                  college,
                  degree,
                  stream,
                  graduationYear
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string().required('Required'),
                  lastName: Yup.string().required('Required'),
                  phone: Yup.string().required('Required'),
                  gender: Yup.string().required('Required'),
                  age: Yup.string().required('Required'),
                  tshirt: Yup.string().required('Required'),
                  addressline1: Yup.string().required('Required'),
                  addressline2: Yup.string(),
                  city: Yup.string().required('Required'),
                  statename: Yup.string().required('Required'),
                  country: Yup.string().required('Required'),
                  pincode: Yup.string().required('Required'),
                  regno: Yup.string().required('Required'),
                  college: Yup.string().required('Required'),
                  degree: Yup.string().required('Required'),
                  stream: Yup.string().required('Required'),
                  graduationYear: Yup.string().required('Required')
                })}
                enableReinitialize
                onSubmit={(values) => {
                  if (detailsChange) {
                    handleDetailsUpdate(values)
                    setDetailsChange(false)
                  } else {
                    setDetailsChange(true)
                  }
                }}
              >
                {(props) => {
                  const { setFieldValue } = props
                  return (
                    <Form className='text-sm divide-y-2 divide-dashed text-white sm:p-6'>
                      <div className='pb-7'>
                        <h1 className='text-white text-center text-xl mb-5 font-sora font-bold'>
                          My Profile
                        </h1>
                        <div className='grid place-items-center'>
                          <img
                            referrerPolicy='no-referrer'
                            src={auth.currentUser?.photoURL}
                            alt='profile-pic'
                            className='rounded-full w-20 h-20 mb-3'
                          />
                        </div>
                        <div className='flex justify-center'>
                          <button
                            type='submit'
                            className='text-sm text-white bg-green-600 hover:bg-jams_green px-7 py-1 my-2 font-semibold rounded-md whitespace-nowrap'
                          >
                            {detailsChange ? 'Save' : 'Edit'}
                          </button>
                        </div>
                      </div>

                      <div className='py-6'>
                        <div className='text-center py-4 font-bold'>
                          Personal Details
                        </div>
                        <div className='grid sm:grid-cols-2 gap-x-6 text-sm'>
                          <div className='mt-2'>
                            <label
                              className='formikLabel text-white mt-2'
                              htmlFor='firstName'
                            >
                              First name
                            </label>
                            <Field
                              name='firstName'
                              className='formikInput py-1'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('firstName', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='firstName'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className='mt-2'>
                            <label
                              className='formikLabel text-white mt-2'
                              htmlFor='lastName'
                            >
                              Last name
                            </label>
                            <Field
                              name='lastName'
                              className='formikInput py-1'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('lastName', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='lastName'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className='grid sm:grid-cols-3 lg:grid-cols-4 gap-x-6 text-sm'>
                          <div className='sm:col-span-2 lg:col-span-3 mt-2'>
                            <label
                              className='formikLabel text-white mt-2'
                              htmlFor='phone'
                            >
                              Phone Number
                            </label>
                            <Field
                              name='phone'
                              validate={validatePhoneNumber}
                              component={PhoneNumber}
                              placeholder=''
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='phone'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className='mt-2'>
                            <label className='formikLabel' htmlFor='gender'>
                              Gender
                            </label>
                            <Field
                              name='gender'
                              component={SelectField}
                              options={genderOptions}
                              placeholder=''
                              onChange={(e) => {
                                setFieldValue('gender', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='gender'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className='mt-2 grid grid-cols-2 gap-x-6 text-sm'>
                          <div>
                            <label className='formikLabel' htmlFor='age'>
                              Age
                            </label>
                            <Field
                              name='age'
                              className='formikInput'
                              type='number'
                              onChange={(e) => {
                                setFieldValue('age', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='age'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label className='formikLabel' htmlFor='tshirt'>
                              T-Shirt Size
                            </label>
                            <Field
                              name='tshirt'
                              component={SelectField}
                              options={shirtSizeOptions}
                              placeholder=''
                              onChange={(e) => {
                                setFieldValue('tshirt', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='tshirt'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>

                      <div className='py-6'>
                        <div className='text-center py-4 font-bold'>
                          Address Details
                        </div>
                        <div className='mt-2'>
                          <label
                            className='formikLabel text-white mt-2'
                            htmlFor='addressline1'
                          >
                            Address Line 1
                          </label>
                          <Field
                            name='addressline1'
                            className='formikInput'
                            type='text'
                            onChange={(e) => {
                              setFieldValue('addressline1', e.target.value)
                              setDetailsChange(true)
                            }}
                            disabled={!detailsChange}
                          />
                          <ErrorMessage
                            className='text-red-500 w-full'
                            name='addressline1'
                          >
                            {(msg) => <div className='err-msg'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className='mt-2'>
                          <label
                            className='formikLabel text-white mt-4'
                            htmlFor='addressline2'
                          >
                            Address Line 2
                          </label>
                          <Field
                            name='addressline2'
                            className='formikInput'
                            type='text'
                            onChange={(e) => {
                              setFieldValue('addressline2', e.target.value)
                              setDetailsChange(true)
                            }}
                            disabled={!detailsChange}
                          />
                          <ErrorMessage
                            className='text-red-500 w-full'
                            name='addressline2'
                          >
                            {(msg) => <div className='err-msg'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className='grid sm:grid-cols-2 gap-x-4 gap-y-3 mt-2'>
                          <div>
                            <label
                              className='formikLabel text-white mt-2'
                              htmlFor='city'
                            >
                              City
                            </label>
                            <Field
                              name='city'
                              className='formikInput'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('city', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='city'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label
                              className='formikLabel text-white mt-3'
                              htmlFor='statename'
                            >
                              State
                            </label>
                            <Field
                              name='statename'
                              className='formikInput'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('statename', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='statename'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className='grid sm:grid-cols-3 gap-x-4 gap-y-3 mt-2'>
                          <div className='sm:col-span-2'>
                            <label
                              className='formikLabel text-white mt-2'
                              htmlFor='country'
                            >
                              Country
                            </label>
                            <Field
                              name='country'
                              className='formikInput'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('country', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='country'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div>
                            <label
                              className='formikLabel text-white mt-3'
                              htmlFor='pincode'
                            >
                              Pincode
                            </label>
                            <Field
                              name='pincode'
                              className='formikInput'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('pincode', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='pincode'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>

                      <div className='py-6'>
                        <div className='text-center py-4 font-bold'>
                          University Details
                        </div>
                        <div className='mt-3'>
                          <label
                            className='formikLabel text-white mt-3'
                            htmlFor='regno'
                          >
                            Roll Number
                          </label>
                          <Field
                            name='regno'
                            className='formikInput py-1'
                            type='text'
                            onChange={(e) => {
                              setFieldValue('regno', e.target.value)
                              setDetailsChange(true)
                            }}
                            disabled={!detailsChange}
                          />
                          <ErrorMessage name='regno'>
                            {(msg) => <div className='err-msg'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className='mt-3'>
                          <label className='formikLabel' htmlFor='college'>
                            School
                          </label>
                          <Field
                            name='college'
                            component={SelectField}
                            options={schoolOptions}
                            placeholder='Select your school'
                            onChange={(e) => {
                              setFieldValue('college', e.target.value)
                              setDetailsChange(true)
                            }}
                            disabled={!detailsChange}
                          />
                          <ErrorMessage name='college'>
                            {(msg) => <div className='err-msg'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className='grid md:grid-cols-3 gap-x-4'>
                          <div className='mt-3 md:col-span-2'>
                            <label className='formikLabel' htmlFor='degree'>
                              Current Level of Study
                            </label>
                            <Field
                              name='degree'
                              component={SelectField}
                              options={degreeOptions}
                              placeholder=''
                              onChange={(e) => {
                                setFieldValue('degree', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='degree'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>

                          <div className='mt-3'>
                            <label
                              className='formikLabel'
                              htmlFor='graduationYear'
                            >
                              Graduation Year
                            </label>
                            <Field
                              name='graduationYear'
                              className='formikInput'
                              type='text'
                              onChange={(e) => {
                                setFieldValue('graduationYear', e.target.value)
                                setDetailsChange(true)
                              }}
                              disabled={!detailsChange}
                            />
                            <ErrorMessage name='graduationYear'>
                              {(msg) => <div className='err-msg'>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className='mt-3'>
                          <label className='formikLabel' htmlFor='stream'>
                            Stream
                          </label>
                          <Field
                            name='stream'
                            component={SelectField}
                            options={streamOptions}
                            placeholder=''
                            disabled={!detailsChange}
                          />
                          <ErrorMessage name='stream'>
                            {(msg) => <div className='err-msg'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserLayout
