import { Switch, useRouteMatch, Route, Redirect } from 'react-router'
import EventsNavbar from 'Pages/Events/Devtalks/EventsNavbar'
import Info from 'Pages/Events/Info'
import Timeline from 'Pages/Events/Devtalks/Timeline'
import { ReactComponent as Devtalks } from 'Assets/Logos/DevTalks Logo.svg'
import faq from 'Data/FaqData'
import events from 'Data/EventsData'

const DevtalksLayout = () => {
  const match = useRouteMatch()

  return (
    <div className='w-screen'>
      <EventsNavbar />
      <div className='w-11/12 md:w-3/4 xl:w-5/7 mx-auto grid place-items-center text-sm pb-28'>
        <Switch>
          <Route exact path={`${match.url}/timeline`} component={Timeline} />
          <Route
            exact
            path={`${match.url}/info`}
            render={() => (
              <Info faq={faq.devjams} details={events.devtalks.content}>
                <Devtalks className='w-44 sm:w-60' />
              </Info>
            )}
          />
          <Redirect exact from={`${match.url}`} to={`${match.url}/timeline`} />
        </Switch>
      </div>
    </div>
  )
}

export default DevtalksLayout
