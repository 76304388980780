import { createSlice } from '@reduxjs/toolkit'

const register = {
  first_name: '',
  last_name: '',
  phone_number: '',
  gender: '',
  address: ';;;;;', // Don't remove the colons
  age: 18,
  t_shirt_size: '',
  reg_no: '',
  college: '',
  degree: '',
  stream: '',
  graduation_year: '2022',
  is_mlh_codeofconduct: false,
  is_mlh_privacypolicy: false,
  is_mlh_eventdetails: false,
  isRegistrationProcessComplete: false
}

// replace double quotes(rdq)
const rdq = (str) => str.replaceAll(';', '')

export const registerSlice = createSlice({
  name: 'register',
  initialState: register,
  reducers: {
    setRegister: (state, action) => {
      const {
        firstName,
        lastName,
        phone,
        gender,
        age,
        tshirt,
        address,
        regno,
        college,
        degree,
        stream,
        graduationYear
      } = action.payload

      state.first_name = firstName
      state.last_name = lastName
      state.phone_number = phone
      state.gender = gender
      state.age = age
      state.t_shirt_size = tshirt
      state.address = address
      state.reg_no = regno
      state.college = college
      state.degree = degree
      state.stream = stream
      state.graduation_year = graduationYear
    },
    setRegister1: (state, action) => {
      const { firstName, lastName, phone, gender, age, tshirt } =
        action.payload
      state.first_name = firstName
      state.last_name = lastName
      state.phone_number = phone
      state.gender = gender
      state.age = age
      state.t_shirt_size = tshirt
    },
    setRegister2: (state, action) => {
      let { addressline1, addressline2, city, statename, country, pincode } =
        action.payload

      addressline1 = rdq(addressline1)
      addressline2 = rdq(addressline2)
      city = rdq(city)
      statename = rdq(statename)
      country = rdq(country)
      pincode = rdq(pincode)

      state.address = `${addressline1};${addressline2};${city};${statename};${country};${pincode}`
    },
    setRegister3: (state, action) => {
      const { regno, college, degree, stream, graduationYear } = action.payload
      state.reg_no = regno
      state.college = college
      state.degree = degree
      state.stream = stream
      state.graduation_year = graduationYear
    },
    setRegister4: (state, action) => {
      const {
        isMLHCodeOfConduct,
        isMLHPrivacyPolicy,
        isMLHEventDetails,
        isRegistrationProcessComplete
      } = action.payload

      state.is_mlh_codeofconduct = isMLHCodeOfConduct
      state.is_mlh_privacypolicy = isMLHPrivacyPolicy
      state.is_mlh_eventdetails = isMLHEventDetails
      state.isRegistrationProcessComplete = isRegistrationProcessComplete
    },
    emptyRegistrationDetails: (state) => {
      state.first_name = ''
      state.last_name = ''
      state.phone_number = ''
      state.gender = ''
      state.address = ';;;;;' // Don't remove the colons
      state.age = 18
      state.t_shirt_size = ''
      state.reg_no = ''
      state.college = ''
      state.degree = ''
      state.stream = ''
      state.graduation_year = '2022'
      state.is_mlh_codeofconduct = false
      state.is_mlh_privacypolicy = false
      state.is_mlh_eventdetails = false
      state.isRegistrationProcessComplete = false
    }
  }
})

export const {
  setRegister,
  setRegister1,
  setRegister2,
  setRegister3,
  setRegister4,
  emptyRegistrationDetails
} = registerSlice.actions

export default registerSlice.reducer
