import { Switch, useRouteMatch, Route, Redirect } from 'react-router'
import EventsNavbar from 'Pages/Events/EventsNavbar'
import Info from 'Pages/Events/Info'
import Teams from 'Pages/Events/Hexathon/Teams'
import Timeline from 'Pages/Events/Timeline'
import Submission from 'Pages/Events/Hexathon/Submission'
import Results from 'Pages/Events/Hexathon/Results'
import { ReactComponent as Hexathon } from 'Assets/Logos/Hexathon Logo.svg'
import faq from 'Data/FaqData'
import events from 'Data/EventsData'
import MarketplaceLayout from './MarketplaceLayout'
import { ReactComponent as MarketplaceIcon } from 'Assets/Hexathon/Marketplace.svg'
import NavbarLink from 'Components/NavbarLink'
import { useSelector } from 'react-redux'

const MarketplaceLink = () => {
  const match = useRouteMatch()

  return (
    <NavbarLink
      icon={() => <MarketplaceIcon className='m-1 w-8 fill-current' />}
      name='Market'
      next={`${match.url}/market`}
    />
  )
}

const HexathonLayout = () => {
  const match = useRouteMatch()
  const { isRegistered } = useSelector((state) => state.hexathon)

  return (
    <div className='w-screen'>
      <EventsNavbar marketplace={isRegistered && MarketplaceLink} />
      <div className='w-11/12 md:w-3/4 xl:w-5/7 mx-auto grid place-items-center text-sm pb-28'>
        <Switch>
          <Route exact path={`${match.url}/timeline`} component={Timeline} />
          <Route
            exact
            path={`${match.url}/info`}
            render={() => (
              <Info faq={faq.hexathon} details={events.hexathon.content}>
                <Hexathon className='w-36 sm:w-44' />
              </Info>
            )}
          />
          <Route exact path={`${match.url}/teams`} component={Teams} />
          <Route path={`${match.url}/market`} component={MarketplaceLayout} />
          <Route exact path={`${match.url}/results`} component={Results} />
          <Route
            exact
            path={`${match.url}/submission`}
            component={Submission}
          />
          <Redirect exact from={`${match.url}`} to={`${match.url}/teams`} />
        </Switch>
      </div>
    </div>
  )
}

export default HexathonLayout
