const Teams = () => {
  return (
    <>
      <div className='w-full md:w-1/2 lg:w-1/3 mt-20 md:mt-0 text-base leading-relaxed text-center text-discord_white bg-jams_dark_blue p-10 rounded-xl '>
        <p className='mb-2 font-bold'>This event has come to an end 🥳</p>
        <p>Thank you for participating! ✨ </p>
      </div>
    </>
  )
}

export default Teams
