import MessageLayout from 'Pages/Layouts/MessageLayout'

const SubmissionPage = () => {
  return (
    <MessageLayout>
      <p className='mb-2 font-bold'>This event has come to an end ✨</p>
      <p>Thank you for participating! 💖</p>
    </MessageLayout>
  )
}

export default SubmissionPage
