import { useEffect } from 'react'
import Register1 from './Register1'
import Register2 from './Register2'
import Register3 from './Register3'
import Register4 from './Register4'
import Navbar from 'Components/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { setBackendToken, setRegistered } from 'Redux/slices/auth'
import { setLoggedInUserDetails } from 'Redux/slices/user'
import { api } from 'api'
import { setLoading } from 'Redux/slices/loading'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router'
import { toast } from 'react-toastify'
import { emptyRegistrationDetails } from 'Redux/slices/register'

const Register = () => {
  const { isRegistrationProcessComplete, ...regDetails } = useSelector(
    (state) => state.register
  )
  const { accessToken } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()

  useEffect(() => {
    if (isRegistrationProcessComplete && accessToken) {
      const makeRequest = async () => {
        if (accessToken) {
          dispatch(setLoading(true))
          try {
            const res = await api.post('api/user/create', {
              id_token: accessToken,
              meta: regDetails
            })
            if (res.data) {
              const { token, user } = res.data?.data
              dispatch(setLoggedInUserDetails(user))
              dispatch(setBackendToken(token))
              dispatch(setRegistered(true))
              dispatch(emptyRegistrationDetails())
              toast.success(
                "Ahoy! Welcome aboard onto the DevJams Train. Have a safe ride! Here's your ticket 🎟",
                {
                  autoClose: 3000
                }
              )
              toast.success(
                'You get 20 points! Check the leaderboard to see where you stand',
                {
                  autoClose: 3000
                }
              )
            }
            dispatch(setLoading(false))
            history.push('/')
          } catch (err) {
            toast.error('Unable to register now. Contact administrator')
            dispatch(setLoading(false))
            dispatch(emptyRegistrationDetails())
            history.push('/login')
          }
        }
      }
      makeRequest()
    }
  }, [isRegistrationProcessComplete]); //eslint-disable-line

  return (
    <div className='h-screen w-screen grid items-center'>
      <Navbar />
      <div className='relative top-16 sm:top-0 flex items-center justify-center flex-grow pb-10'>
        <Switch>
          <Route path={`${match.url}/1`} component={Register1} />
          <Route path={`${match.url}/2`} component={Register2} />
          <Route path={`${match.url}/3`} component={Register3} />
          <Route path={`${match.url}/4`} component={Register4} />
          <Redirect exact from={`${match.url}`} to={`${match.url}/1`} />
        </Switch>
      </div>
    </div>
  )
}

export default Register
