import Popup from 'reactjs-popup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { getAuth, sendPasswordResetEmail } from '@firebase/auth'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const resetPassword = async (email) => {
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      toast.success(
        "Password verification mail has been sent. You'll receive an email in some time"
      )
    } catch (error) {
      console.log(error)
      toast.error('Unable to reset password')
    }
  }

  return (
    <Popup
      trigger={
        <div className='text-xs text-gray-100 mt-3 cursor-pointer'>
          Forgot password?
        </div>
      }
      modal
      contentStyle={{
        maxWidth: '320px',
        background: 'white',
        borderRadius: '12px'
      }}
      overlayStyle={{
        background: 'rgba(0,0,0,0.5)'
      }}
    >
      {(close) => (
        <div className='bg-indigo-900 px-4 py-10 rounded-xl border-4 border-jams_blue font-sora text-sm w-80'>
          <div
            onClick={close}
            className='absolute top-5 right-5 cursor-pointer text-lg text-white'
          >
            &times;
          </div>
          <div />
          <div>
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Required')
              })}
              onSubmit={({ email }) => {
                resetPassword(email)
                close()
              }}
            >
              <Form className='grid'>
                <label className='formikLabel' htmlFor='email'>
                  Email
                </label>
                <Field className='formikInput' name='email' type='email' />
                <ErrorMessage name='email'>
                  {(p) => <div className='err-msg'>{p}</div>}
                </ErrorMessage>
                <div className='flex justify-center'>
                  <button
                    type='submit'
                    className='bg-jams_red font-bold text-white px-4 py-2 rounded-md mt-2 text-xs'
                  >
                    Reset Password
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default ForgotPassword
