import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

const useFuzzy = (allTeams) => {
  const [query, setQuery] = useState('')
  const [teams, setTeams] = useState([])
  const resetQuery = () => setQuery('')

  useEffect(() => {
    if (allTeams) {
      const tms = allTeams.map((team) => {
        const t = {}

        if (team) {
          t.id = team.id
          t.members = team.members
          t.leader = team.members?.filter((member) => member.is_leader)[0].user
          t.count = team.members?.length
          t.teamName = team.team_name
        }

        return t
      })
      setTeams(tms)
    }
  }, [allTeams])

  const searcher = useMemo(() => {
    const options = {
      tokenize: true,
      threshold: 0.2,
      includeMatches: true,
      keys: [
        'teamName',
        'leader.first_name',
        'leader.last_name',
        'leader.email',
        'count'
      ]
    }

    return new Fuse(teams, options)
  }, [teams])

  const result =
    query || query !== '' ? searcher.search(query).map((e) => e.item) : teams

  return {
    query,
    resetQuery,
    result,
    search: setQuery
  }
}

export default useFuzzy
