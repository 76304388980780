import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Formik, Field, Form } from 'formik'
import { setLoading } from 'Redux/slices/loading'
import Popup from 'reactjs-popup'
import { useHistory } from 'react-router'
import RegisterTeam from './RegisterTeam'
import { ReactComponent as DefaultUser } from 'Assets/user.svg'
import {
  setHexathonTeamJoinCode,
  setHexathonTeamMembers,
  setHexathonUserDetails,
  emptyHexathonDetails
} from 'Redux/slices/events/hexathon'
import {
  removeTeamMember,
  leaveTeam,
  updateTeamName,
  deleteParticipation,
  getTeamDetails
} from 'Utils/APICalls'

const Teams = () => {
  const { members, teamDetails, userDetails, isRegistered, eventDetails } =
    useSelector((state) => state.hexathon)
  const { registrationStarted, eventStarted } = eventDetails
  const { config } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [nameChange, setNameChange] = useState(false)
  const teamId = teamDetails.participation.team_id
  const history = useHistory()

  useEffect(() => {
    if (teamDetails.join_code.length === 0 && teamId) {
      dispatch(setLoading(true))
      const paramsObject = {
        params: { team_id: teamId }
      }
      const hexathonConfig = { ...config, ...paramsObject }
      getTeamDetails(teamId, hexathonConfig)
        .then((res) => {
          const teamName = res.team.team_details.team_name
          const joinCode = res.team.join_code
          const members = res.team.members
          const userDetails = res.team.user_details
          dispatch(setHexathonTeamJoinCode({ joinCode, teamName }))
          dispatch(setHexathonTeamMembers(members))
          dispatch(setHexathonUserDetails(userDetails))
          if (eventDetails.member_limit === members.length) {
            toast.warn('Member limit has been reached')
          }
          dispatch(setLoading(false))
        })
        .catch((err) => {
          console.log(err.message)
        })
      // toast.info('Team formation and idea submissions end at 23:59 7th October IST')
    }
  }, [
    config,
    dispatch,
    teamId,
    teamDetails,
    eventDetails.member_limit,
    members.length
  ])

  const handleTeamNameUpdate = async (teamName) => {
    const body = {
      team_id: teamDetails.participation.team_id,
      team_name: teamName
    }
    const joinCode = teamDetails.join_code
    dispatch(setLoading(true))
    const res = await updateTeamName(body, config)
    if (res) dispatch(setHexathonTeamJoinCode({ joinCode, teamName }))
    dispatch(setLoading(false))
  }

  const handleRemoveTeammate = async (userId) => {
    const removeMemberConfig = {
      data: {
        member_id: userId,
        team_id: teamDetails.participation.team_id
      },
      ...config
    }
    dispatch(setLoading(true))
    const res = await removeTeamMember(removeMemberConfig)
    if (res) {
      const paramsObject = {
        params: { team_id: teamId }
      }
      const hexathonConfig = { ...config, ...paramsObject }
      getTeamDetails(teamId, hexathonConfig).then((res) => {
        const members = res.team.members
        dispatch(setHexathonTeamMembers(members))
      })
    }
    dispatch(setLoading(false))
  }

  const handleLeaveTeam = async () => {
    const leaveTeamConfig = {
      data: {
        team_id: teamDetails.participation.team_id
      },
      ...config
    }
    dispatch(setLoading(true))
    const res = await leaveTeam(leaveTeamConfig)
    if (res) {
      dispatch(emptyHexathonDetails())
      if (!registrationStarted) {
        history.push('/')
      }
      dispatch(setLoading(false))
    }
  }

  const handleDeleteTeam = async () => {
    dispatch(setLoading(true))
    const deleteTeamConfig = {
      data: {
        participation_id: teamDetails.participation.id
      },
      ...config
    }
    const res = await deleteParticipation(deleteTeamConfig)
    if (res) {
      dispatch(emptyHexathonDetails())
      if (!registrationStarted) {
        history.push('/')
      }
      dispatch(setLoading(false))
    }
  }
  return !isRegistered ? (
    <RegisterTeam />
  ) : (
    <>
      <div className='text-white grid bg-jams_dark_blue w-full mx-3 p-8 sm:p-10 text-justify opacity-95 rounded-lg divide-y-2 divide-gray-600 divide-dashed'>
        <div className='flex flex-col sm:flex-row gap-4 justify-between items-center mb-4'>
          <h1 className='text-xl font-bold'>Manage Team</h1>
          <div>
            <button
              disabled={!eventStarted}
              onClick={() => history.push('/events/hexathon/submission')}
              className='px-2 sm:px-6 py-2 text-xs mr-2 bg-indigo-400 hover:bg-jams_purple opacity-95 text-white font-sora w-auto rounded-md'
            >
              Submit Designs
            </button>
            <Popup
              trigger={
                <button className='px-2 sm:px-6 py-2 text-xs bg-indigo-400 hover:bg-red-400 opacity-95 text-white font-sora w-auto rounded-md'>
                  {!userDetails.is_leader ? 'Leave Team' : 'Delete Team'}
                </button>
              }
              modal
              contentStyle={{
                maxWidth: '600px',
                minWidth: '350px',
                width: '30%'
              }}
            >
              {(close) => (
                <div className='bg-indigo-500 p-10 rounded-xl border-2 border-jams_light_purple text-white font-sora text-sm text-center'>
                  <div
                    onClick={close}
                    className='absolute top-5 right-5 font-sora cursor-pointer'
                  >
                    &times;
                  </div>
                  <div>
                    <div className='text-sm pb-3'>
                      Are you sure? This action can't be undone
                    </div>
                    <div className='font-bold'>
                      {!userDetails.is_leader
                        ? " You're about leave this team"
                        : ' Deleting the team will disband the entire group'}
                    </div>
                  </div>
                  <div className='flex justify-center gap-x-4 pt-8'>
                    <div>
                      <button
                        onClick={close}
                        className='bg-blue-700 hover:bg-blue-800 px-10 py-1 text-sm rounded-md'
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          if (!userDetails.is_leader) {
                            handleLeaveTeam()
                          } else {
                            handleDeleteTeam()
                          }
                          close()
                        }}
                        className='bg-jams_red hover:bg-red-500 px-10 py-1 rounded-md text-sm'
                      >
                        {!userDetails.is_leader ? 'Leave' : 'Delete'}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>
        <div className='pt-6'>
          <p className='mb-2'>Team Name</p>
          <Formik
            initialValues={{ teamName: teamDetails.team_name }}
            enableReinitialize
            onSubmit={(values) => {
              if (nameChange) {
                handleTeamNameUpdate(values.teamName)
                setNameChange(false)
              } else {
                setNameChange(true)
              }
            }}
          >
            {(props) => {
              const { setFieldValue } = props
              return (
                <Form className='flex gap-x-3 place-items-center'>
                  <Field
                    name='teamName'
                    className='rounded-md w-full h-10 px-3 text-white bg-jams_input_gray disabled:text-gray-400'
                    type='text'
                    onChange={(e) => {
                      setFieldValue('teamName', e.target.value)
                      setNameChange(true)
                    }}
                    disabled={!nameChange}
                  />
                  <button
                    type='submit'
                    disabled={!userDetails.is_leader}
                    className={`text-xs bg-green-600 ${
                      userDetails.is_leader
                        ? 'hover:bg-jams_green'
                        : 'disabled:bg-green-700 text-gray-400 cursor-default'
                    } px-6 md:px-10 py-2 my-2 font-semibold rounded-md whitespace-nowrap`}
                  >
                    {nameChange ? 'Save' : 'Edit'}
                  </button>
                </Form>
              )
            }}
          </Formik>
          {members.length < eventDetails.member_lower_limit ? (
            <div className='text-center my-3 bg-red-500 p-2 rounded-md'>
              <p>Team size is less than {eventDetails.member_lower_limit}</p>
              <p>Submission will not be considered</p>
            </div>
          ) : (
            members.length !== eventDetails.member_limit && (
              <div className='text-center my-3 text-black bg-jams_yellow p-2 rounded-md'>
                <p>Maximum team size is {eventDetails.member_limit}</p>
              </div>
            )
          )}
          <div className='mt-5 grid md:grid-cols-2 items-start'>
            <div className='py-3 mb-5 grid gap-y-4'>
              <div className='flex items-center justify-between md:mr-6 mb-2'>
                <p>Team Members</p>
                <div className='py-1 px-2 text-xs bg-jams_green rounded-full flex items-center'>
                  <p>{members.length}</p>
                  <DefaultUser className='w-4 ml-1' />
                </div>
              </div>
              {members.length > 0 &&
                members.map((member) => {
                  return (
                    <div
                      className='flex items-center justify-between'
                      key={member.user_id}
                    >
                      <div className='flex items-center'>
                        {member.user.photo_url ? (
                          <img
                            referrerPolicy='no-referrer'
                            src={member.user.photo_url}
                            alt='dp'
                            className='rounded-full w-12 h-12'
                          />
                        ) : (
                          <DefaultUser className='w-9 h-9' />
                        )}
                        <div className='ml-3'>
                          <p className='w-auto text-left'>
                            {member.user.first_name +
                              ' ' +
                              member.user.last_name}
                          </p>
                          {member.is_leader && (
                            <p className='text-gray-400 text-xs'>Team Leader</p>
                          )}
                        </div>
                      </div>
                      {userDetails.is_leader && !member.is_leader && (
                        <Popup
                          trigger={
                            <button className='text-sm cursor-pointer pr-6 stroke-current text-jams_red'>
                              <RemoveIcon />
                            </button>
                          }
                          modal
                          contentStyle={{
                            maxWidth: '600px',
                            minWidth: '350px',
                            width: '33%'
                          }}
                        >
                          {(close) => (
                            <div className='bg-indigo-500 px-10 py-14 rounded-xl border-2 border-jams_light_purple text-white font-sora text-sm text-center'>
                              <div
                                onClick={close}
                                className='absolute top-5 right-5 font-sora cursor-pointer'
                              >
                                &times;
                              </div>
                              <div>
                                <div className='text-sm pb-3'>
                                  Are you sure? This action can't be undone
                                </div>
                                <div className='font-bold'>
                                  You're about remove this member from your team
                                </div>
                              </div>
                              <div className='flex justify-center gap-x-4 pt-8'>
                                <div>
                                  <button
                                    onClick={close}
                                    className='bg-blue-700 hover:bg-blue-800 px-10 py-1 text-md rounded-md'
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div>
                                  <button
                                    onClick={() => {
                                      handleRemoveTeammate(member.user_id)
                                      close()
                                    }}
                                    className='bg-jams_red hover:bg-red-500 px-10 py-1 rounded-md text-sm'
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      )}
                    </div>
                  )
                })}
            </div>
            <div className='grid justify-center text-center p-5 border-2 border-gray-400 rounded-lg'>
              <p className='font-semibold mb-5'>Team Invite Code</p>
              <p className='mb-5 text-xs sm:text-sm'>
                Share your invite code with your friends and get on-board the
                DevJams Express!
              </p>
              <div className='flex flex-col sm:flex-row gap-3 place-items-center'>
                <p className='text-xs sm:text-sm w-full bg-jams_input_gray py-3 rounded-lg text-indigo-300 p-4 overflow-x-auto break-all'>
                  {teamDetails.join_code}
                </p>
                <CopyToClipboard joinCode={teamDetails.join_code} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CopyToClipboard = ({ joinCode }) => {
  const copy = () => {
    if (!navigator.clipboard) {
      toast.warning("Your device doesn't support navigator")
      return
    }
    navigator.clipboard
      .writeText(joinCode)
      .then(() => {
        toast.info('Copied to clipboard ✨')
      })
      .catch((err) => {
        toast.error(`Unable to copy to clipboard - ${err}`)
      })
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='bg-jams_purple w-7 sm:w-10 rounded-lg p-2 cursor-pointer hover:bg-jams_light_purple'
      fill='none'
      onClick={copy}
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3'
      />
    </svg>
  )
}

const RemoveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-4 w-4'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  )
}

export default Teams
