import { createSlice } from '@reduxjs/toolkit'
import { currentDateWithinRange } from 'Utils/Helper'

const devjams = {
  eventDetails: {
    registrationStarted: false,
    eventStarted: false,
    member_limit: 1,
    member_lower_limit: 1,
    id: '',
    event_name: ''
  },
  teamDetails: {
    team_name: '',
    team_size: 0,
    participation: {},
    join_code: ''
  },
  members: [],
  userDetails: {
    is_leader: false,
    team_id: ''
  },
  submission: {
    track: '',
    sponsoredtrack: '',
    md: '',
    link: '',
    github: ''
  },
  challenge: {
    meta: {
      question: 'Questions will be updated soon. Stay tuned'
    }
  },
  leaderboard: [],
  allTeams: [],
  isRegistered: false,
  submissionMade: false
}

export const devjamsSlice = createSlice({
  name: 'devjams',
  initialState: devjams,
  reducers: {
    setDevjamsEventDetails: (state, action) => {
      const {
        rsvp_start: rsvpStart,
        rsvp_end: rsvpEnd,
        start,
        end,
        member_limit: memberLimit,
        member_lower_limit: memberLowerLimit,
        event_name: eventName,
        id
      } = action.payload

      state.eventDetails = {
        ...state.eventDetails,
        registrationStarted: currentDateWithinRange(rsvpStart, rsvpEnd),
        eventStarted: currentDateWithinRange(start, end),
        member_limit: memberLimit,
        member_lower_limit: memberLowerLimit,
        event_name: eventName,
        id
      }
    },
    setDevjamsTeamDetails: (state, action) => {
      const { participation, teamSize } = action.payload
      state.teamDetails = {
        ...state.teamDetails,
        participation,
        team_size: teamSize
      }
    },
    setDevjamsTeamJoinCode: (state, action) => {
      const { joinCode, teamName } = action.payload
      state.teamDetails = {
        ...state.teamDetails,
        join_code: joinCode,
        team_name: teamName
      }
    },
    setDevjamsTeamMembers: (state, action) => {
      state.members = action.payload
    },
    setRegisteredForDevjams: (state, action) => {
      state.isRegistered = action.payload
    },
    setDevjamsUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    setDevjamsAllTeams: (state, action) => {
      state.allTeams = action.payload
    },
    setTeamsSubmission: (state, action) => {
      if (action.payload) {
        const { link, md, track, sponsoredtrack, github } = action.payload
        state.submission.track = track
        state.submission.sponsoredtrack = sponsoredtrack
        state.submission.link = link
        state.submission.github = github
        state.submission.md = md
        state.submissionMade = true
      }
    },
    setDevjamsChallenge: (state, action) => {
      if (action.payload) {
        const { question1 } = action.payload
        state.challenge.meta.question = question1
      }
    },
    setDevjamsLeaderboard: (state, action) => {
      state.leaderboard = action.payload
    },
    emptyDevjamsDetails: (state) => {
      state.teamDetails = {
        team_name: '',
        team_size: 0,
        participation: {},
        join_code: ''
      }
      state.members = []
      state.userDetails = {
        is_leader: false,
        team_id: ''
      }
      state.submission = {
        track: '',
        sponsoredtrack: '',
        md: '',
        link: '',
        github: ''
      }
      state.challenge = {
        meta: {
          question: 'Questions will be updated soon. Stay tuned'
        }
      }
      state.allTeams = []
      state.isRegistered = false
      state.submissionMade = false
    }
  }
})

export const {
  setDevjamsAllTeams,
  setDevjamsEventDetails,
  setDevjamsLeaderboard,
  setDevjamsTeamDetails,
  setDevjamsTeamJoinCode,
  setDevjamsTeamMembers,
  setDevjamsUserDetails,
  setRegisteredForDevjams,
  setTeamsSubmission,
  setDevjamsChallenge,
  emptyDevjamsDetails
} = devjamsSlice.actions

export default devjamsSlice.reducer
