import { DevJamsGrid } from 'Components/Cards/Grids'
// import { useDispatch, useSelector } from 'react-redux'

// import { fetchParticularEvent } from 'Utils/APICalls'
// import { useEffect } from 'react'
// import {
//   setDevjamsEventDetails,
//   setDevjamsTeamDetails,
//   setRegisteredForDevjams
// } from 'Redux/slices/events/devjams'

const DevJamsCard = () => {
  // const dispatch = useDispatch()

  // const { isRegistered, submissionMade, eventDetails } = useSelector(
  //   (state) => state.devjams
  // )
  // const { config } = useSelector((state) => state.auth)

  // const eventId = process.env.REACT_APP_DEVJAMS_EVENT_ID

  // useEffect(() => {
  //   // need to event fetch everytime
  //   if (eventId && eventDetails.id === '') {
  //     fetchParticularEvent(eventId, config).then((data) => {
  //       if (data) {
  //         const {
  //           is_registered: isRegistered,
  //           event,
  //           participation,
  //           team_size: teamSize
  //         } = data
  //         dispatch(setRegisteredForDevjams(isRegistered))
  //         dispatch(setDevjamsEventDetails(event))
  //         dispatch(setDevjamsTeamDetails({ participation, teamSize }))
  //       }
  //     })
  //   }
  // }, [])

  return (
    <DevJamsGrid
      buttonText='View'
    />
  )
}

export default DevJamsCard
