import 'Styles/Components/Events.css'
import { ReactComponent as Linkedin } from 'Assets/Linkedin.svg'
import { ReactComponent as Github } from 'Assets/Github.svg'

const SpeakerCard = ({ speaker, ...props }) => {
  const { name, content, route } = speaker

  const handleRouting = () => {
    window.open(route, '_blank')
  }

  return (
    <>
      <div className='relative grid--dark coming-soon-grid--dark w-80 pt-4 shadow-md rounded-xl sm:rounded-3xl overflow-hidden'>
        <div className='content-container'>
          <div className='grid gap-y-4 h-80 md:h-96'>
            <div className='grid justify-items-center items-start gap-y-4'>
              {props.children}
              <div className='text-jams_red font-bold'>{name}</div>
            </div>
            <div className='content--dark text-left italic'>{content}</div>
          </div>
          <div
            onClick={handleRouting}
            className='cursor-pointer my-3 grid place-items-center'
          >
            {name === 'Sudhakar'
              ? <Github className='w-9 h-9 rounded-none fill-current text-white' />
              : <Linkedin className='w-9 h-9 rounded-none fill-current text-jams_blue' />}
          </div>
        </div>
      </div>
    </>
  )
}

export default SpeakerCard
