export const devjamsTracksData = [
  {
    title: 'Diversity & Inclusion',
    description:
      "Our interwoven lives appeal for more empathic settings in which individuals from all walks of life can be a part of the discussion sans hindrance, regardless of their background. Create solutions to today's most pressing issues of bias, diversity and inclusion so that no marginalised community is left behind"
  },
  {
    title: 'Professional Tools',
    description:
      'We have transitioned our lives from analog to digital in mere decades, but this transition has not been smooth for many professionals who are yet to see the benefits from this. Help professionals integrate their workflows using unique digital age tools and enjoy the rapid advancements made in tech'

  },
  {
    title: 'Digital Accessibility',
    description:
      'As technology continues to touch more lives every day, it becomes our responsibility to make sure that every group is able to make fair use of technology without any lingual or ableist barrier. Come up with solutions to bridge this gap and make tech accessible to everyone.'

  },
  {
    title: 'Hybrid World',
    description:
      'We are on the verge of a fascinating, expansive, and imaginative epoch. By fusing the physical and virtual worlds, ideate a solution that can extend reality and experience, profoundly transforming the way we live, work, and play.'

  },
  {
    title: 'Open Innovation',
    description:
      'Have an avant-garde idea which you are eager to implement to fruition? Then open innovation is the track for you. Let your innovative mind run amok and showcase your Promethean solution that could come to aid.'
  },

  {
    title: 'Sustainability',
    description:
      'The effects of global warming and climate change are becoming increasingly apparent and the time to tackle this problem is nigh. Developing sustainable solutions is our best bet to ensure a secure future for our ecosystem and coming generations. '
  },
  {
    title: 'Creativity',
    description:
      'More and more artists are incorporating digital technology into their creative or presentation processes. Help artists realise  their ingenuity and inventive prowess through tailormade artisan tools.'
  }
]

export const sponsorTracks = [
  {
    title: 'CrowdStrike',
    description: 'Better Security Against Social Engineering Attacks'
  },
  {
    title: 'Groww',
    description: 'Revolutionizing investments in india'
  },
  {
    title: 'Alchemy',
    description: "Building decentralized application(or DApp) using Alchemy's platform!"
  },
  {
    title: 'Ren',
    description: 'Build a DeFi application with RenVM which focuses on enhancing DeFi with RenVM’s native cross-chain user experience.',
    link: 'https://bit.ly/DJ_Ren_Prizes_Guidelines'
  },
  {
    title: 'Vicara',
    description: 'Motion Tracking and Analysis',
    tasks: [
      "1. For a given user, with an everyday mobile phone, develop an application that can track and recognize the exercises performed by a user, and give them insights on how well they're doing.",
      '2. Develop an application that can recognize gestures such as swipe up, swipe down, swipe left, swipe right, and grab and allow the user to control their computer by automating the mouse and keyboard using the gestures.'
    ]
  },
  {
    title: 'Vicara',
    description: 'For the Developers, By the developers',
    tasks: [
      "1. For a given git repository, a lot of operational overhead is involved in setting up the workflow of their git pipeline. This operational work, also called GitOps, is heavily used in the industry to implement a standard way of operating on git repositories. Develop a way to simplify the GitOps infrastructure in such a way that it simplifies GitOps to an extremely simple to use format, which can be setup in hours rather than days, while at the same time maintaining flexibility of usage that doesn't prevent users from being restricted in terms of what they can do.",
      "2. Any given application in today's world consists of multiple features that are constantly being released or rolled back. These features are also rolled out in a phased manner, providing some users a part of the beta feature set, while the general audience get a more stable product. Develop a tool that provides app developers a dashboard to control these features on the fly, allowing them to enable, disable features, control the phased rollout of new features, all while reflecting these features on the user's applications in real-time, with minimal user-disruption.",
      "3. Any application backend code will require some form of a database, deployed in a database of the developer's choice (MySQL, PostgreSQL, MongoDB, etc). Design and develop a library / framework that abstracts the complexities involved in maintaining the schemas of databases. The goal of the library / framework is to provide it with the data-structure of the information that needs to be stored (either in GraphQL format or any other new format), and it should abstract the setting up of databases, schemas, indexes, as well as constraints. Additionally, it should also help in handling migrations of data from one version of a schema to another, allowing the developers to freely setup their data-structures and migrate between them, while the framework handles the storage as well as migration of data in a safe and secure fashion.",
      '4. Develop an application that can generate frontend code of any framework of choice (React / Angular / Vue, etc) from a given figma file. The user will design a figma prototype, and that prototype should be converted into a usable frontend code that can be deployed as a website.'
    ]
  }
]
