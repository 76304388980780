import { createUserWithEmailAndPassword, getAuth } from '@firebase/auth'
import { ReactComponent as DevJamsLogo } from 'Assets/Logos/DevjamsLogo.svg'
import Navbar from 'Components/Navbar'
import PasswordShowHide from 'Components/PasswordShowHide'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

const RegisterWithEmail = () => {
  const history = useHistory()

  const loginWithEmail = async ({ email, password }) => {
    try {
      const auth = getAuth()
      await createUserWithEmailAndPassword(auth, email, password)
      history.push('/login/oauth')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='grid place-items-center w-screen h-screen'>
      <Navbar />
      <div className='relative bg-indigo-900 px-3 py-3 flex flex-col justify-center items-center w-11/12 md:w-4/5 lg:w-3/5 xl:w-2/5 rounded-xl'>
        <DevJamsLogo className='w-72 h-auto mb-5 px-6' />
        <div className='px-3 md:px-6 pb-6 w-full'>
          <Formik
            initialValues={{
              email: '',
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
              password: Yup.string().matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                'Password is weak'
              ),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required')
            })}
            onSubmit={(values) => {
              loginWithEmail(values)
            }}
          >
            <Form className='grid'>
              <div>
                <label className='formikLabel' htmlFor='email'>
                  Email ID
                </label>
                <Field className='formikInput' name='email' type='email' />
                <ErrorMessage name='email'>
                  {(p) => <div className='err-msg'>{p}</div>}
                </ErrorMessage>
              </div>
              <div className='mt-2'>
                <label className='formikLabel' htmlFor='password'>
                  Password
                </label>
                <Field name='password' component={PasswordShowHide} />
                <ErrorMessage name='password'>
                  {(p) => <div className='err-msg'>{p}</div>}
                </ErrorMessage>
              </div>
              <div className='mt-2'>
                <label className='formikLabel' htmlFor='confirmPassword'>
                  Confirm Password
                </label>
                <Field name='confirmPassword' component={PasswordShowHide} />
                <ErrorMessage name='confirmPassword'>
                  {(p) => <div className='err-msg'>{p}</div>}
                </ErrorMessage>
              </div>
              <div className='flex justify-center'>
                <button
                  type='submit'
                  className='bg-jams_red font-bold text-white px-6 py-2 rounded-md mt-7 text-sm mr-3'
                >
                  Continue
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default RegisterWithEmail
