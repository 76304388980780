import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { ReactComponent as Devjams } from 'Assets/Night/DevJams Logo.svg'
import { setRegister4 } from 'Redux/slices/register'

const mlh = {
  coc: 'https://static.mlh.io/docs/mlh-code-of-conduct.pdf',
  privacy: 'https://mlh.io/privacy',
  termsAndConditions:
    'https://github.com/MLH/mlh-policies/tree/master/prize-terms-and-conditions'
}

const Register3 = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef(null)

  const {
    is_mlh_codeofconduct: isMLHCodeOfConduct,
    is_mlh_privacypolicy: isMLHPrivacyPolicy,
    is_mlh_eventdetails: isMLHEventDetails
  } = useSelector((state) => state.register)

  return (
    <div className='z-40 bg-indigo-900 w-11/12 md:w-4/5 lg:w-3/5 xl:w-2/5 p-7 text-left rounded-xl flex flex-col'>
      <Devjams className='w-1/2 mx-auto' />
      <div className=' mt-5'>
        <Formik
          innerRef={ref}
          initialValues={{
            isMLHCodeOfConduct,
            isMLHPrivacyPolicy,
            isMLHEventDetails
          }}
          validationSchema={Yup.object({
            isMLHCodeOfConduct: Yup.boolean()
              .oneOf([true], 'You must accept the MLH Code of Conduct.')
              .required('Required'),
            isMLHPrivacyPolicy: Yup.boolean()
              .oneOf(
                [true],
                'You must accept the MLH Terms and Conditions and Privacy Policy.'
              )
              .required('Required'),
            isMLHEventDetails: Yup.boolean()
          })}
          onSubmit={(values) => {
            dispatch(
              setRegister4({ isRegistrationProcessComplete: true, ...values })
            )
          }}
        >
          <Form className='text-sm '>
            <div className='grid grid-cols-12 items-center gap-y-5'>
              <Field name='isMLHCodeOfConduct' type='checkbox' />
              <div className='col-span-11'>
                <label
                  className='text-xs block text-white italic'
                  htmlFor='rollno'
                >
                  I have read and agree to the
                  <a
                    className='px-2 no-underline text-jams_blue'
                    href={mlh.coc}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    MLH Code of Conduct
                  </a>
                </label>
                <ErrorMessage name='isMLHCodeOfConduct'>
                  {(msg) => <div className='err-msg mt-2'>{msg}</div>}
                </ErrorMessage>
              </div>
              <Field
                name='isMLHPrivacyPolicy'
                className='py-1'
                type='checkbox'
              />
              <div className='col-span-11'>
                <label
                  className='text-xs block text-white mt-2 italic'
                  htmlFor='rollno'
                >
                  I authorize you to share my application/registration
                  information with Major League Hacking for event
                  administration, ranking, and MLH administration in-line with
                  the
                  <a
                    className='px-2 no-underline text-jams_blue'
                    href={mlh.privacy}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    MLH Privacy Policy
                  </a>
                  . I further agree to the terms of both the
                  <a
                    className='px-2 no-underline text-jams_blue'
                    href={mlh.termsAndConditions}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    MLH Contest Terms and Conditions
                  </a>
                  and the
                  <a
                    className='px-2 no-underline text-jams_blue'
                    href={mlh.privacy}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    MLH Privacy Policy
                  </a>
                </label>
                <ErrorMessage name='isMLHPrivacyPolicy'>
                  {(msg) => <div className='err-msg mt-2'>{msg}</div>}
                </ErrorMessage>
              </div>
              <Field
                name='isMLHEventDetails'
                className='py-1'
                type='checkbox'
              />
              <div className='col-span-11'>
                <label
                  className='text-xs block text-white mt-2 italic'
                  htmlFor='rollno'
                >
                  I authorize MLH to send me pre- and post-event informational
                  emails, which contain free credit and opportunities from their
                  partners."
                  <span className='not-italic text-jams_red pl-2'>
                    (optional)
                  </span>
                </label>

                <ErrorMessage name='isMLHEventDetails'>
                  {(msg) => <div className='err-msg mt-2'>{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className='w-2/3 mx-auto flex justify-between gap-x-4'>
              <button
                type='button'
                onClick={() => {
                  dispatch(setRegister4(ref.current.values))
                  history.goBack()
                }}
                className='mx-auto w-28 bg-red-500 text-white px-3 py-2 rounded-md mt-7'
              >
                Previous
              </button>
              <button
                type='submit'
                className='mx-auto w-28 bg-red-500 text-white px-3 py-2 rounded-md mt-7'
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Register3
