import { createSlice } from '@reduxjs/toolkit'
import { currentDateWithinRange } from 'Utils/Helper'

const knockathons = {
  eventDetails: {
    registrationStarted: false,
    eventStarted: false,
    member_limit: 1,
    member_lower_limit: 1,
    id: '',
    event_name: ''
  },
  teamDetails: {
    team_name: '',
    team_size: 0,
    participation: {},
    join_code: ''
  },
  members: [],
  userDetails: {
    is_leader: false,
    team_id: ''
  },
  submission: {
    md: '',
    link: ''
  },
  challenge: {
    meta: {
      question: 'Questions will be updated soon. Stay tuned'
    }
  },
  leaderboard: [],
  allTeams: [],
  isRegistered: false,
  submissionMade: false
}

export const knockathonsSlice = createSlice({
  name: 'knockathons',
  initialState: knockathons,
  reducers: {
    setKnockathonsEventDetails: (state, action) => {
      const {
        rsvp_start: rsvpStart,
        rsvp_end: rsvpEnd,
        start,
        end,
        member_limit: memberLimit,
        member_lower_limit: memberLowerLimit,
        event_name: eventName,
        id
      } = action.payload

      state.eventDetails = {
        ...state.eventDetails,
        registrationStarted: currentDateWithinRange(rsvpStart, rsvpEnd),
        eventStarted: currentDateWithinRange(start, end),
        member_limit: memberLimit,
        member_lower_limit: memberLowerLimit,
        event_name: eventName,
        id
      }
    },
    setKnockathonsTeamDetails: (state, action) => {
      const { participation, teamSize } = action.payload
      state.teamDetails = {
        ...state.teamDetails,
        participation,
        team_size: teamSize
      }
    },
    setKnockathonTeamJoinCode: (state, action) => {
      const { joinCode, teamName } = action.payload
      state.teamDetails = {
        ...state.teamDetails,
        join_code: joinCode,
        team_name: teamName
      }
    },
    setKnockathonsTeamMembers: (state, action) => {
      state.members = action.payload
    },
    setRegisteredForKnockathons: (state, action) => {
      state.isRegistered = action.payload
    },
    setKnockathonsUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    setKnockathonsAllTeams: (state, action) => {
      state.allTeams = action.payload
    },
    setTeamsSubmission: (state, action) => {
      if (action.payload) {
        const { link, md } = action.payload

        state.submission.link = link
        state.submission.md = md
        state.submissionMade = true
      }
    },
    setKnockathonsChallenge: (state, action) => {
      if (action.payload) {
        const { question1 } = action.payload
        state.challenge.meta.question = question1
      }
    },
    setKnockathonsLeaderboard: (state, action) => {
      state.leaderboard = action.payload
    },
    emptyKnockathonsDetails: (state) => {
      state.teamDetails = {
        team_name: '',
        team_size: 0,
        participation: {},
        join_code: ''
      }
      state.members = []
      state.userDetails = {
        is_leader: false,
        team_id: ''
      }
      state.submission = {
        md: '',
        link: ''
      }
      state.challenge = {
        meta: {
          question: 'Questions will be updated soon. Stay tuned'
        }
      }
      state.allTeams = []
      state.isRegistered = false
      state.submissionMade = false
    }
  }
})

export const {
  setKnockathonsEventDetails,
  setRegisteredForKnockathons,
  setKnockathonsTeamDetails,
  setKnockathonsTeamMembers,
  setKnockathonsUserDetails,
  setKnockathonTeamJoinCode,
  setKnockathonsAllTeams,
  setTeamsSubmission,
  setKnockathonsChallenge,
  setKnockathonsLeaderboard,
  emptyKnockathonsDetails
} = knockathonsSlice.actions

export default knockathonsSlice.reducer
