const faq = {
  knockathons: [
    {
      key: 1,
      question: 'What is Knockathons?',
      answer:
        'Knockathons is a knockout-styled ideation where teams will participate in various rounds against an opposing participating team to showcase and present their idea to the judges and the audience. Ideas that are more optimal, innovative, and presented better than the other teams will advance to the further rounds until a winner is finally decided on.',
      color: 'yellow'
    },
    {
      key: 2,
      question: 'Where can I register?',
      answer:
        'Registrations will be starting soon here on the DevJams website. Stay tuned!',
      color: 'blue'
    },
    {
      key: 3,
      question: 'Is Knockathons a Team event?',
      answer:
        'Yes! Each team should have a total of 3 members in order to participate.',
      color: 'red'
    },
    {
      key: 4,
      question: 'What Idea do I have to submit and present?',
      answer:
        'A problem statement will be given before the start of the Knockathon and the idea and solution must be relevant to it',
      color: 'green'
    },
    {
      key: 5,
      question: 'Where and how will the Knockouts happen?',
      answer:
        'Teams will have to compete in rounds against each other and will only progress to the next round if they clear the previous one. All the rounds will happen online and the matchmaking and round timings will be announced to you as the knockathon progresses',
      color: 'yellow'
    },
    {
      key: 6,
      question:
        'Will I have to use any specific platform to demonstrate my ideas?',
      answer:
        'Nope! You are free to use whatever you think best will allow you to clearly express and show your solution to us!',
      color: 'blue'
    }
  ],
  devjams: [
    {
      key: 1,
      question: 'What is DevJams?',
      answer:
        'DevJams is an annual 36-hour long hackathon, that aims to bring together great developers and designers under one roof and pitting them against each other in a healthy competition. Under focused mentorship from a dedicated and experienced technical team, and an equally challenging and innovative panel of themes, participants can test their skills and use them to build projects to solve real-world problems.',
      color: 'red'
    },
    {
      key: 2,
      question: 'Where can I register for DevJams?',
      answer:
        'The details for registering for DevJams will be released soon. Stay Tuned!',
      color: 'green'
    },
    {
      key: 3,
      question:
        'Do I need to pay anything to register for Knockathons, DevJams or any other DSC event?',
      answer:
        'Like all our other events, DevTalks and DevJams, both are free-of-cost and you don’t need to pay anything to register for it. We plan to keep all of our future events free too.',
      color: 'yellow'
    },
    {
      key: 4,
      question:
        'Who will own the IP (Intellectual Property) Rights to the product that I have built?',
      answer:
        'The developer/developers of the web/mobile application will have all rights and own the IP of the product. However, all the code needs to be in the public domain (open source) so that it can be evaluated by the judges.',
      color: 'blue'
    },
    {
      key: 10,
      question:
        'Is it necessary for all participants to belong to the same university?',
      answer:
        'No, there are no restrictions regarding universities. Your team can have members from different universities across the globe!',
      color: 'red'
    },
    {
      key: 11,
      question:
        'I’m interested to participate but I am unable to find a team. What should I do?',
      answer: 'You can try finding like minded people on our discord server.',
      color: 'green'
    },
    {
      key: 12,
      question: 'How do I submit what I have made for the Hackathon?',
      answer:
        'You have to develop the application on your local system, add your code to a GitHub repository and submit its link. Also, you have to update the README of your repository with all the details about your application along with the instructions to run.',
      color: 'yellow'
    },
    {
      key: 13,
      question: 'Do we need to have the entire idea fully working?',
      answer:
        'The entire idea need not be fully implemented. However, the submission should be functional so that it can be reviewed by the judges.',
      color: 'blue'
    },
    {
      key: 14,
      question:
        'How is the environment? Will your environment support any language? Will you provide any IDE and DB for us to work on ideas?',
      answer:
        'You have to develop the application on your local system, add your code to a GitHub repository and submit its link. Update the README with all the details about your application and the instructions to run the code.',
      color: 'red'
    },
    {
      key: 15,
      question:
        'Does one have to be online and available for the entire duration of the Hackathon?',
      answer:
        'No, one does not need to be online for the entire duration. You can develop the application on your local system based on the given themes and then submit it on the DevJams portal, on the specific challenge page.',
      color: 'green'
    },
    {
      key: 16,
      question:
        'Do I need to give a demonstration for the product that I have built?',
      answer:
        "If you want, you can submit a small presentation or a video that demonstrates your submission. However, it's not mandatory, and only good to have. In case you are one of the winners, you might be invited to demonstrate your application, details of which will be shared with sufficient advance notice.",
      color: 'yellow'
    },
    {
      key: 17,
      question:
        "A fully developed application needs to have a proper database for persistent data storage. Since it's online, is it accepted to show only the prototype of it?",
      answer:
        "Yes, it's absolutely fine to submit just the prototype. You can develop the application on your local machine and submit just the source code along with the instructions to run.",
      color: 'blue'
    },
    {
      key: 18,
      question:
        'If it is a team submission, does that mean all team members will have access to work at the same time?',
      answer:
        'Yes, all team members can log in from their account and submit their project on the DevJams portal.',
      color: 'yellow'
    }
  ],
  hexathon: [
    {
      key: 1,
      question: 'What is Hexathon?',
      answer:
        'Hexathon is an event geared towards anyone with an eye for detail and a passion for design! The event begins with an hour-long introductory workshop on October 31st at 11am, followed by a 24-hour long design hackathon from 12pm on October 31st to 12pm on November 1st. (Note that all times are in IST)',
      color: 'yellow'
    },
    {
      key: 2,
      question: 'Is Hexathon a team event?',
      answer:
        'Yes! Each team can have a max of 2 participants!',
      color: 'red'
    },
    {
      key: 3,
      question: 'Is it necessary for all my team members to belong to the same university?',
      answer:
        'No, there are no restrictions regarding universities. Your team can have members from different universities across the world!',
      color: 'blue'
    },
    {
      key: 4,
      question: 'I don’t have a team but want to take part in the event. What should I do?',
      answer:
        'Join our discord server! You can always find like-minded people on there to form a team with!',
      color: 'green'
    },
    {
      key: 5,
      question: 'I am new to design. Can I still participate?',
      answer:
        'Yes, of course! In fact, our workshop aims to help beginners get started with design!',
      color: 'yellow'
    },
    {
      key: 6,
      question: 'Are we required to attend the workshop to participate in the hackathon?',
      answer:
        "While it isn't compulsory to attend the workshop, our portal is an integral part of the hackathon and we will be having a live demo of the portal and how to use it during the workshop, so it is highly recommended that you are present for it!",
      color: 'red'
    },
    {
      key: 7,
      question: 'Where will the hackathon take place?',
      answer:
        'The hackathon will take place on our portal with a review conducted on Discord on November 1st at 12am IST.',
      color: 'blue'
    },
    {
      key: 8,
      question: 'What will I win?',
      answer:
        'There are tons of prizes and goodies up for grabs! More information will be available soon!',
      color: 'green'
    }
  ]
}

export default faq
