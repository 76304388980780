import { createSlice } from '@reduxjs/toolkit'
import { currentDateWithinRange } from 'Utils/Helper'

const hexathon = {
  eventDetails: {
    registrationStarted: false,
    eventStarted: false,
    member_limit: 1,
    member_lower_limit: 1,
    id: '',
    event_name: ''
  },
  teamDetails: {
    team_name: '',
    team_size: 0,
    participation: {},
    join_code: ''
  },
  members: [],
  userDetails: {
    is_leader: false,
    team_id: ''
  },
  submission: {
    link: ''
  },
  challenge: {
    meta: {
      question: 'Submit your design link for Review 1'
    }
  },
  leaderboard: [],
  allTeams: [],
  isRegistered: false,
  submissionMade: false
}

export const hexathonSlice = createSlice({
  name: 'hexathon',
  initialState: hexathon,
  reducers: {
    setHexathonEventDetails: (state, action) => {
      const {
        rsvp_start: rsvpStart,
        rsvp_end: rsvpEnd,
        start,
        end,
        member_limit: memberLimit,
        member_lower_limit: memberLowerLimit,
        event_name: eventName,
        id
      } = action.payload

      state.eventDetails = {
        ...state.eventDetails,
        registrationStarted: currentDateWithinRange(rsvpStart, rsvpEnd),
        eventStarted: currentDateWithinRange(start, end),
        member_limit: memberLimit,
        member_lower_limit: memberLowerLimit,
        event_name: eventName,
        id
      }
    },
    setHexathonTeamDetails: (state, action) => {
      const { participation, teamSize } = action.payload
      state.teamDetails = {
        ...state.teamDetails,
        participation,
        team_size: teamSize
      }
    },
    setHexathonTeamJoinCode: (state, action) => {
      const { joinCode, teamName } = action.payload
      state.teamDetails = {
        ...state.teamDetails,
        join_code: joinCode,
        team_name: teamName
      }
    },
    setHexathonTeamMembers: (state, action) => {
      state.members = action.payload
    },
    setRegisteredForHexathon: (state, action) => {
      state.isRegistered = action.payload
    },
    setHexathonUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    setHexathonAllTeams: (state, action) => {
      state.allTeams = action.payload
    },
    setTeamsSubmission: (state, action) => {
      if (action.payload) {
        const { link } = action.payload

        state.submission.link = link
        state.submissionMade = true
      }
    },
    setHexathonChallenge: (state, action) => {
      if (action.payload) {
        const { question1 } = action.payload
        state.challenge.meta.question = question1
      }
    },
    setHexathonLeaderboard: (state, action) => {
      state.leaderboard = action.payload
    },
    emptyHexathonDetails: (state) => {
      state.teamDetails = {
        team_name: '',
        team_size: 0,
        participation: {},
        join_code: ''
      }
      state.members = []
      state.userDetails = {
        is_leader: false,
        team_id: ''
      }
      state.eventDetails = {
        registrationStarted: false,
        eventStarted: false,
        member_limit: 1,
        member_lower_limit: 1,
        id: '',
        event_name: ''
      }
      state.submission = {
        link: ''
      }
      state.challenge = {
        meta: {
          question: 'Questions will be updated soon. Stay tuned'
        }
      }
      state.allTeams = []
      state.isRegistered = false
      state.submissionMade = false
    }
  }
})

export const {
  setHexathonEventDetails,
  setRegisteredForHexathon,
  setHexathonTeamDetails,
  setHexathonTeamMembers,
  setHexathonUserDetails,
  setHexathonTeamJoinCode,
  setHexathonAllTeams,
  setTeamsSubmission,
  setHexathonChallenge,
  setHexathonLeaderboard,
  emptyHexathonDetails
} = hexathonSlice.actions

export default hexathonSlice.reducer
