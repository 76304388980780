import MarketHome from 'Pages/Market/MarketHome'
import ProblemStatement from 'Pages/Market/ProblemStatement'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { data } from 'marketPlaceData'
import {
  setAvailableCatalog,
  setTeamsChoices
} from 'Redux/slices/events/market'
import { useDispatch, useSelector } from 'react-redux'
import Fonts from 'Pages/Market/Fonts'
import Styles from 'Pages/Market/Styles'
import Challenges from 'Pages/Market/Challenges'
import Illustrations from 'Pages/Market/Illustrations'
import Colours from 'Pages/Market/Colours'
import { fetchMarketplaceCatalog } from 'Utils/APICalls'
import { setLoading } from 'Redux/slices/loading'
import { toast } from 'react-toastify'

const MarketplaceLayout = () => {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { config } = useSelector((state) => state.auth)
  const { catalogFetched } = useSelector((state) => state.market)
  const disabled = false

  useEffect(() => {
    if (!catalogFetched) {
      dispatch(setLoading(true))
      if (data) {
        const { catalog, team_choice: choices } = data
        const { choice_json: chosen } = choices
        const { selectedItems } = chosen

        dispatch(setAvailableCatalog(catalog))

        const selectedList = Object.keys(catalog)?.map((key) => ({
          categoryID: catalog[key].category_id,
          items: []
        }))

        selectedItems.forEach((item) => {
          const index = selectedList
            ?.map((category) => category.categoryID)
            .indexOf(item.category_id)

          selectedList[index].items.push(item.item_id)
        })

        dispatch(setTeamsChoices({ choices, selectedList }))
      }
      dispatch(setLoading(false))
    }
  }, [dispatch, config, catalogFetched])

  useEffect(() => {
    if (!catalogFetched) {
      dispatch(setLoading(true))
      fetchMarketplaceCatalog(config).then((data) => {
        if (data) {
          const { catalog, team_choice: choices } = data
          const { choice_json: chosen } = choices
          const { selectedItems } = chosen

          dispatch(setAvailableCatalog(catalog))

          const selectedList = Object.keys(catalog)?.map((key) => ({
            categoryID: catalog[key].category_id,
            items: []
          }))

          selectedItems.forEach((item) => {
            const index = selectedList
              ?.map((category) => category.categoryID)
              .indexOf(item.category_id)

            selectedList[index].items.push(item.item_id)
          })

          dispatch(setTeamsChoices({ choices, selectedList }))
        }
        dispatch(setLoading(false))
      })
      toast.info('Market is closed!')
    }
  }, [dispatch, config, catalogFetched])

  return (
    <div className='absolute top-16 w-11/12 md:w-3/4 xl:w-4/5 mx-auto grid place-items-center text-sm pb-28'>
      <Switch>
        <Route exact path={`${match.url}/`} component={MarketHome} />
        {!disabled && (
          <Route
            exact
            path={`${match.url}/statements`}
            component={ProblemStatement}
          />
        )}
        {!disabled && (
          <Route exact path={`${match.url}/fonts`} component={Fonts} />
        )}
        {!disabled && (
          <Route exact path={`${match.url}/styles`} component={Styles} />
        )}
        {!disabled && (
          <Route
            exact
            path={`${match.url}/challenges`}
            component={Challenges}
          />
        )}
        {!disabled && (
          <Route
            exact
            path={`${match.url}/illustrations`}
            component={Illustrations}
          />
        )}
        {!disabled && (
          <Route exact path={`${match.url}/colours`} component={Colours} />
        )}
        <Redirect from='*' to={`${match.url}/`} />
      </Switch>
    </div>
  )
}

export default MarketplaceLayout
