import { Link, useLocation } from 'react-router-dom'

const NavbarLink = ({ icon: Icon, name, next }) => {
  const location = useLocation()
  const navLinkActive = location.pathname === next

  return (
    <Link to={next}>
      <div
        className={`z-60 font-sora navlink ${
          navLinkActive ? 'text-discord_white' : 'text-jams_icon_gray'
        } text-xs h-24 w-20`}
      >
        <Icon />
        <div>{name}</div>
      </div>
    </Link>
  )
}

export default NavbarLink
