export const data = {
  catalog: [
    {
      created_at: '2021-10-07T20:41:42.807752+05:30',
      updated_at: '2021-10-07T20:41:42.807752+05:30',
      category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
      category_name: 'Challenges',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: '',
      items: [
        {
          created_at: '2021-10-07T20:48:12.311586+05:30',
          updated_at: '2021-10-07T20:48:12.311586+05:30',
          item_id: 'aa211624-10a0-406e-a907-9282570bb5a1',
          item_name: 'Challenge 1',
          item_photo_url: 'https://picsum.photos/1000?random=1',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 100
        },
        {
          created_at: '2021-10-07T20:48:21.379753+05:30',
          updated_at: '2021-10-07T20:48:21.379753+05:30',
          item_id: 'a64f17a9-d697-4b65-b7f6-f7fe7599d59e',
          item_name: 'Challenge 2',
          item_photo_url: 'https://picsum.photos/1000?random=2',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 100
        },
        {
          created_at: '2021-10-07T20:48:34.541786+05:30',
          updated_at: '2021-10-07T20:48:34.541786+05:30',
          item_id: '167ba9dc-3222-4700-a497-3e0f5ea7a66a',
          item_name: 'Challenge 3',
          item_photo_url: 'https://picsum.photos/1000?random=3',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 100
        },
        {
          created_at: '2021-10-07T20:48:50.937508+05:30',
          updated_at: '2021-10-07T20:48:50.937508+05:30',
          item_id: 'd18564d0-f02f-43e5-a4e9-d032389d94a5',
          item_name: 'Challenge 4',
          item_photo_url: 'https://picsum.photos/1000?random=4',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 100
        },
        {
          created_at: '2021-10-07T20:49:03.71521+05:30',
          updated_at: '2021-10-07T20:49:03.71521+05:30',
          item_id: 'aaf3eb85-b6ff-4ae8-9fd0-1692c86c0af9',
          item_name: 'Challenge 5',
          item_photo_url: 'https://picsum.photos/1000?random=5',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 100
        },
        {
          created_at: '2021-10-07T20:49:15.711162+05:30',
          updated_at: '2021-10-07T20:49:15.711162+05:30',
          item_id: '4a1056a4-6f2e-4461-a22c-3fb478aad297',
          item_name: 'Challenge 6',
          item_photo_url: 'https://picsum.photos/1000?random=6',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 100
        },
        {
          created_at: '2021-10-07T20:49:27.998903+05:30',
          updated_at: '2021-10-07T20:49:27.998903+05:30',
          item_id: 'd03e0915-73f7-4522-a8e8-946fbc1c105e',
          item_name: 'Challenge 7',
          item_photo_url: 'https://picsum.photos/1000?random=7',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 60
        },
        {
          created_at: '2021-10-07T20:49:34.988043+05:30',
          updated_at: '2021-10-07T20:49:34.988043+05:30',
          item_id: 'db4d141d-a8d6-49b7-9f51-45b2f0620f22',
          item_name: 'Challenge 8',
          item_photo_url: 'https://picsum.photos/1000?random=8',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 60
        },
        {
          created_at: '2021-10-07T20:50:41.323943+05:30',
          updated_at: '2021-10-07T20:50:41.323943+05:30',
          item_id: '45505d81-ff83-4341-af31-f947cee2e8d9',
          item_name: 'Challenge 9',
          item_photo_url: 'https://picsum.photos/1000?random=9',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 40
        },
        {
          created_at: '2021-10-07T20:50:46.823151+05:30',
          updated_at: '2021-10-07T20:50:46.823151+05:30',
          item_id: 'cf3b526c-c740-4c03-8daa-8fc4adc79811',
          item_name: 'Challenge 10',
          item_photo_url: 'https://picsum.photos/1000?random=10',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 40
        },
        {
          created_at: '2021-10-07T20:50:51.006078+05:30',
          updated_at: '2021-10-07T20:50:51.006078+05:30',
          item_id: '063eeb62-cb87-4f94-a96f-7e618ac44019',
          item_name: 'Challenge 11',
          item_photo_url: 'https://picsum.photos/1000?random=11',
          item_description: 'This is the item description',
          category_id: '4894d921-e6ca-46a1-a4b8-601e1ad9a4b4',
          item_price: 40
        }
      ]
    },
    {
      created_at: '2021-10-07T20:40:46.27076+05:30',
      updated_at: '2021-10-07T20:40:46.27076+05:30',
      category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
      category_name: 'Style',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: '',
      items: [
        {
          created_at: '2021-10-07T20:43:46.718661+05:30',
          updated_at: '2021-10-07T20:43:46.718661+05:30',
          item_id: 'efc3c905-5fc9-4daa-a1e8-67858cd4d9df',
          item_name: 'Theme 1',
          item_photo_url: 'https://picsum.photos/1000?random=1',
          item_description: 'This is the item description',
          category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
          item_price: -100
        },
        {
          created_at: '2021-10-07T20:43:59.950749+05:30',
          updated_at: '2021-10-07T20:43:59.950749+05:30',
          item_id: 'd6823080-951b-4b78-8545-475959542319',
          item_name: 'Theme 2',
          item_photo_url: 'https://picsum.photos/1000?random=2',
          item_description: 'This is the item description',
          category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
          item_price: -100
        },
        {
          created_at: '2021-10-07T20:44:13.562653+05:30',
          updated_at: '2021-10-07T20:44:13.562653+05:30',
          item_id: 'e93a3b01-410c-4269-80ef-c1f22f7411b0',
          item_name: 'Theme 3',
          item_photo_url: 'https://picsum.photos/1000?random=3',
          item_description: 'This is the item description',
          category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:44:18.524234+05:30',
          updated_at: '2021-10-07T20:44:18.524234+05:30',
          item_id: '30719930-fe26-4b05-808b-3eb834bc2119',
          item_name: 'Theme 4',
          item_photo_url: 'https://picsum.photos/1000?random=4',
          item_description: 'This is the item description',
          category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:44:24.713538+05:30',
          updated_at: '2021-10-07T20:44:24.713538+05:30',
          item_id: '5c110218-2352-477b-ba17-b59909fa5e51',
          item_name: 'Theme 5',
          item_photo_url: 'https://picsum.photos/1000?random=5',
          item_description: 'This is the item description',
          category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
          item_price: -50
        },
        {
          created_at: '2021-10-07T20:44:30.214233+05:30',
          updated_at: '2021-10-07T20:44:30.214233+05:30',
          item_id: 'a3a06adf-34bc-4323-8c3a-3fd8eeb251e6',
          item_name: 'Theme 6',
          item_photo_url: 'https://picsum.photos/1000?random=6',
          item_description: 'This is the item description',
          category_id: '11ac3da5-e284-4084-95b9-c98d07408996',
          item_price: -50
        }
      ]
    },
    {
      created_at: '2021-10-07T20:40:37.553221+05:30',
      updated_at: '2021-10-07T20:40:37.553221+05:30',
      category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
      category_name: 'Font',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: '',
      items: [
        {
          created_at: '2021-10-07T20:44:59.464284+05:30',
          updated_at: '2021-10-07T20:44:59.464284+05:30',
          item_id: 'aa1e1b73-273c-44cc-9be6-62f89eb9023d',
          item_name: 'Font 1',
          item_photo_url: 'https://picsum.photos/1000?random=1',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -200
        },
        {
          created_at: '2021-10-07T20:45:07.544603+05:30',
          updated_at: '2021-10-07T20:45:07.544603+05:30',
          item_id: '417d795e-3552-419a-a950-cc49c1d1e258',
          item_name: 'Font 2',
          item_photo_url: 'https://picsum.photos/1000?random=2',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -100
        },
        {
          created_at: '2021-10-07T20:45:13.122865+05:30',
          updated_at: '2021-10-07T20:45:13.122865+05:30',
          item_id: '4750887a-fa85-49e1-95ba-7988ad62de75',
          item_name: 'Font 3',
          item_photo_url: 'https://picsum.photos/1000?random=3',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -100
        },
        {
          created_at: '2021-10-07T20:45:24.794878+05:30',
          updated_at: '2021-10-07T20:45:24.794878+05:30',
          item_id: '0c408d62-0534-4c5f-ae18-3c04f06b69d0',
          item_name: 'Font 4',
          item_photo_url: 'https://picsum.photos/1000?random=4',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:45:30.776164+05:30',
          updated_at: '2021-10-07T20:45:30.776164+05:30',
          item_id: '0ba8e655-31b3-44c1-849a-6e18ebc12a85',
          item_name: 'Font 5',
          item_photo_url: 'https://picsum.photos/1000?random=5',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:45:37.00137+05:30',
          updated_at: '2021-10-07T20:45:37.00137+05:30',
          item_id: 'd4a60616-1f2b-4987-873f-ecb57a307fbc',
          item_name: 'Font 6',
          item_photo_url: 'https://picsum.photos/1000?random=6',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -50
        },
        {
          created_at: '2021-10-07T20:45:42.980917+05:30',
          updated_at: '2021-10-07T20:45:42.980917+05:30',
          item_id: 'c0833f2f-b925-4973-b185-a9c497b414da',
          item_name: 'Font 7',
          item_photo_url: 'https://picsum.photos/1000?random=7',
          item_description: 'This is the item description',
          category_id: '3e557d4f-e959-47e9-85d6-73a734a0c0a8',
          item_price: -50
        }
      ]
    },
    {
      created_at: '2021-10-07T20:40:51.108443+05:30',
      updated_at: '2021-10-07T20:40:51.108443+05:30',
      category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
      category_name: 'Illustration Style',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: '',
      items: [
        {
          created_at: '2021-10-07T20:46:55.507707+05:30',
          updated_at: '2021-10-07T20:46:55.507707+05:30',
          item_id: 'cd9a89b6-6015-4023-9af0-133ba54a6fac',
          item_name: 'Illustration Style 1',
          item_photo_url: 'https://picsum.photos/1000?random=1',
          item_description: 'This is the item description',
          category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:47:00.210445+05:30',
          updated_at: '2021-10-07T20:47:00.210445+05:30',
          item_id: 'a946432e-ae41-4568-bf90-0bc1a9a7b5b9',
          item_name: 'Illustration Style 2',
          item_photo_url: 'https://picsum.photos/1000?random=2',
          item_description: 'This is the item description',
          category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:47:12.622582+05:30',
          updated_at: '2021-10-07T20:47:12.622582+05:30',
          item_id: '8beb3eac-6ef6-4d74-a53f-1e7f908f13ae',
          item_name: 'Illustration Style 3',
          item_photo_url: 'https://picsum.photos/1000?random=3',
          item_description: 'This is the item description',
          category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
          item_price: -60
        },
        {
          created_at: '2021-10-07T20:47:21.886576+05:30',
          updated_at: '2021-10-07T20:47:21.886576+05:30',
          item_id: '4d12453f-d0f8-4f3b-b590-2d09509980d9',
          item_name: 'Illustration Style 4',
          item_photo_url: 'https://picsum.photos/1000?random=4',
          item_description: 'This is the item description',
          category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
          item_price: -60
        },
        {
          created_at: '2021-10-07T20:47:38.679058+05:30',
          updated_at: '2021-10-07T20:47:38.679058+05:30',
          item_id: '0ff3db93-bbd9-4b11-b717-1f4fcb5a3e0e',
          item_name: 'Illustration Style 5',
          item_photo_url: 'https://picsum.photos/1000?random=5',
          item_description: 'This is the item description',
          category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
          item_price: -40
        },
        {
          created_at: '2021-10-07T20:47:42.152761+05:30',
          updated_at: '2021-10-07T20:47:42.152761+05:30',
          item_id: '943149fc-17d4-4a1d-aafc-61b806eb4b88',
          item_name: 'Illustration Style 6',
          item_photo_url: 'https://picsum.photos/1000?random=6',
          item_description: 'This is the item description',
          category_id: '57d1e2c5-7b95-45ef-932c-f733148a875d',
          item_price: -40
        }
      ]
    },
    {
      created_at: '2021-10-07T20:41:49.686119+05:30',
      updated_at: '2021-10-07T20:41:49.686119+05:30',
      category_id: '5981ea50-054a-4912-9fa9-47f0978a814f',
      category_name: 'Problem Statement',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: '',
      items: [
        {
          created_at: '2021-10-07T20:51:12.253681+05:30',
          updated_at: '2021-10-07T20:51:12.253681+05:30',
          item_id: '6d5191e0-4ca0-436d-8b1c-ac88099a5cad',
          item_name: 'Problem Statement 1',
          item_photo_url: 'https://picsum.photos/1000?random=1',
          item_description: 'This is the item description',
          category_id: '5981ea50-054a-4912-9fa9-47f0978a814f',
          item_price: -40
        },
        {
          created_at: '2021-10-07T20:51:18.787285+05:30',
          updated_at: '2021-10-07T20:51:18.787285+05:30',
          item_id: '303d890f-2f49-4e6e-81e2-a5a8d9c88e4a',
          item_name: 'Problem Statement 2',
          item_photo_url: 'https://picsum.photos/1000?random=2',
          item_description: 'This is the item description',
          category_id: '5981ea50-054a-4912-9fa9-47f0978a814f',
          item_price: -40
        },
        {
          created_at: '2021-10-07T20:51:30.945455+05:30',
          updated_at: '2021-10-07T20:51:30.945455+05:30',
          item_id: 'f03cf4e0-7618-4c52-9111-a0ccd4b43a29',
          item_name: 'Problem Statement 3',
          item_photo_url: 'https://picsum.photos/1000?random=3',
          item_description: 'This is the item description',
          category_id: '5981ea50-054a-4912-9fa9-47f0978a814f',
          item_price: -40
        },
        {
          created_at: '2021-10-18T15:19:54.940805+05:30',
          updated_at: '2021-10-18T15:19:54.940805+05:30',
          item_id: '6a150cbf-7c95-42b5-8a02-919840f0694e',
          item_name: 'Problem Statement 4',
          item_photo_url: 'https://picsum.photos/1000?random=4',
          item_description: 'This is the item description',
          category_id: '5981ea50-054a-4912-9fa9-47f0978a814f',
          item_price: -40
        }
      ]
    },
    {
      created_at: '2021-10-07T20:41:34.942501+05:30',
      updated_at: '2021-10-07T20:41:34.942501+05:30',
      category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
      category_name: 'Colour',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: '',
      items: [
        {
          created_at: '2021-10-07T20:46:10.594917+05:30',
          updated_at: '2021-10-07T20:46:10.594917+05:30',
          item_id: 'cd267de2-f791-44c6-8fb7-3e045baf2ec4',
          item_name: 'Color Palette 1',
          item_photo_url: 'https://picsum.photos/1000?random=1',
          item_description: 'This is the item description',
          category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:46:15.397041+05:30',
          updated_at: '2021-10-07T20:46:15.397041+05:30',
          item_id: '1a163802-1112-434c-a0c6-8111312ca44f',
          item_name: 'Color Palette 2',
          item_photo_url: 'https://picsum.photos/1000?random=2',
          item_description: 'This is the item description',
          category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:46:19.273697+05:30',
          updated_at: '2021-10-07T20:46:19.273697+05:30',
          item_id: '20e25737-ed1b-478a-a478-2594e1453141',
          item_name: 'Color Palette 3',
          item_photo_url: 'https://picsum.photos/1000?random=3',
          item_description: 'This is the item description',
          category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:46:24.325281+05:30',
          updated_at: '2021-10-07T20:46:24.325281+05:30',
          item_id: '23ceba4a-66cc-4237-87e0-06451ce06d17',
          item_name: 'Color Palette 4',
          item_photo_url: 'https://picsum.photos/1000?random=4',
          item_description: 'This is the item description',
          category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:46:28.219132+05:30',
          updated_at: '2021-10-07T20:46:28.219132+05:30',
          item_id: 'f91ceafc-c10e-47ea-8447-8de74728bd0f',
          item_name: 'Color Palette 5',
          item_photo_url: 'https://picsum.photos/1000?random=5',
          item_description: 'This is the item description',
          category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
          item_price: -80
        },
        {
          created_at: '2021-10-07T20:46:32.920252+05:30',
          updated_at: '2021-10-07T20:46:32.920252+05:30',
          item_id: 'fab8525c-6a8c-4734-b881-6add240680b5',
          item_name: 'Color Palette 6',
          item_photo_url: 'https://picsum.photos/1000?random=6',
          item_description: 'This is the item description',
          category_id: 'bfb44695-638a-4c71-b172-844aa85d78a9',
          item_price: -80
        }
      ]
    },
    {
      created_at: '2021-10-18T15:22:27.918813+05:30',
      updated_at: '2021-10-18T15:22:27.918813+05:30',
      category_id: '471499a0-06b8-4e40-9276-3498e021c951',
      category_name: 'Example category',
      category_photo_url: 'https://picsum.photos/1000',
      category_description: 'This is an example category',
      items: []
    }
  ],
  error: false,
  message: 'catalog fetched',
  status: 200,
  team_choice: {
    created_at: '2021-10-17T16:55:22.499273+05:30',
    updated_at: '2021-10-18T15:18:54.550736+05:30',
    participation_id: '30090696-82c2-4af2-a368-49ab0b6f2011',
    choice_json: {
      selectedItems: [],
      totalCoinsLeft: 600,
      totalCoinsUsed: 0
    }
  }
}
