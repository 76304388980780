import Navbar from 'Components/Navbar'
import Events from 'Pages/Events'
import { ReactComponent as Buildings } from 'Assets/Night/Buildings.svg'

export default function MainLayout () {
  return (
    <>
      <div className='fixed w-screen h-screen -z-10 bg-gradient-to-b from-jams_purple to-jams_light_purple'>
        <Buildings className='building-bg fixed bottom-0 max-h-screen' />
      </div>
      <div className='h-screen' style={{ display: 'flex', flexFlow: 'column' }}>
        <Navbar />
        <div className='flex-grow pt-10'>
          <div className='h-full w-full flex md:items-center font-sora mt-0 '>
            <Events />
          </div>
        </div>
      </div>
    </>
  )
}
