import React from 'react'

const DevJamsGridGround = (props) => {
  return (
    <svg
      className={props.className}
      viewBox='0 0 1736 252'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M596.878 254.999H1901.38L1890.86 0.5C1849.97 16.3118 1707.96 64.5633 1383.69 85.5C1058.44 106.5 728.96 198.528 596.878 254.999Z'
        fill='#74BE63'
      />
      <path
        d='M90.8177 115.294C16.6119 119.373 -94.1953 155.311 -140.323 172.771V223H926.134V155.62C893.376 161.337 816.229 172.771 769.7 172.771C711.539 172.771 702.654 169.062 628.448 155.62C554.242 142.178 427.017 167.498 356.321 167.498C285.625 167.498 183.575 110.195 90.8177 115.294Z'
        fill='#74BE63'
      />
      <path
        d='M-120.277 167.5C-110.755 173.167 -49.3138 181.1 120.277 167.5C293.336 153.622 592.98 192.726 747.091 212.725C1036.46 169.385 1441.19 112 1529.02 112C1634.87 112 1810.67 139.333 1885.34 153V266H1220.81H351.81H-120.277V167.5Z'
        fill='#8DC97E'
      />
      <path
        d='M1809.76 170.181C1785.57 157.662 1742.8 153.414 1727.99 154.247C1727.1 154.298 1726.91 155.371 1727.73 155.731C1745.13 163.389 1786.11 173.473 1809.45 171.758C1810.33 171.694 1810.54 170.585 1809.76 170.181Z'
        fill='white'
      />
      <path
        d='M1710.17 74.7187C1707.24 101.748 1718.79 143.063 1724.93 156.527C1725.3 157.337 1726.37 157.121 1726.41 156.231C1727.26 137.277 1721.82 95.5166 1711.75 74.4382C1711.38 73.6456 1710.26 73.8454 1710.17 74.7187Z'
        fill='white'
      />
      <path
        d='M1662.25 203.519C1686.17 194.475 1715.93 167.277 1724.62 156.421C1725.14 155.767 1724.5 154.964 1723.74 155.309C1707.48 162.656 1674.76 184.947 1661.35 202.311C1660.84 202.964 1661.48 203.811 1662.25 203.519Z'
        fill='white'
      />
      <path
        d='M1733.65 300.9H1720.77L1723.12 155.64H1728.84L1733.65 300.9Z'
        fill='white'
      />
      <ellipse
        cx='1726.19'
        cy='155.529'
        rx='8.1325'
        ry='8.11377'
        fill='white'
      />
      <ellipse
        cx='1726.19'
        cy='155.529'
        rx='5.42167'
        ry='5.40918'
        fill='#9690D6'
      />
      <path
        d='M67.7083 146.119C59.8742 142.67 48.0664 141.25 42.1131 141.186C41.2203 141.176 41.0521 142.131 41.8873 142.447C48.2355 144.847 59.7261 147.562 67.4229 147.508C68.3029 147.502 68.5137 146.474 67.7083 146.119Z'
        fill='white'
      />
      <path
        d='M33.7895 114.726C33.4082 123.26 36.3634 134.754 38.4622 140.313C38.7769 141.147 39.73 140.957 39.7221 140.066C39.6623 133.293 38.0317 121.625 35.1905 114.488C34.8656 113.672 33.8287 113.848 33.7895 114.726Z'
        fill='white'
      />
      <path
        d='M18.4307 158.087C25.7534 154.779 34.1412 147.426 37.9136 143.311C38.4796 142.693 37.9175 141.977 37.1736 142.362C31.5172 145.289 22.42 151.61 17.6386 157.016C17.0917 157.634 17.6784 158.427 18.4307 158.087Z'
        fill='white'
      />
      <path
        d='M42.264 193.32H37.6895L38.5242 141.714H40.5576L42.264 193.32Z'
        fill='white'
      />
      <ellipse
        cx='39.6153'
        cy='141.675'
        rx='2.88918'
        ry='2.88252'
        fill='white'
      />
      <ellipse
        cx='39.6156'
        cy='141.675'
        rx='1.92612'
        ry='1.92168'
        fill='#9690D6'
      />
      <path
        d='M691.174 200.873C688.079 192.907 680.736 183.573 676.572 179.328C675.947 178.692 675.152 179.249 675.519 180.062C678.307 186.238 684.508 196.264 689.989 201.655C690.615 202.271 691.492 201.692 691.174 200.873Z'
        fill='white'
      />
      <path
        d='M689.441 154.745C683.123 160.51 677.066 170.722 674.61 176.134C674.241 176.946 675.048 177.486 675.674 176.851C680.431 172.021 687.549 162.62 690.599 155.568C690.947 154.761 690.091 154.152 689.441 154.745Z'
        fill='white'
      />
      <path
        d='M647.849 174.573C655.372 177.399 666.514 178.117 672.098 177.868C672.935 177.831 673.043 176.927 672.244 176.675C666.168 174.754 655.256 172.806 648.045 173.256C647.22 173.307 647.076 174.282 647.849 174.573Z'
        fill='white'
      />
      <path
        d='M676.726 229.32H672.151L672.986 177.714H675.019L676.726 229.32Z'
        fill='white'
      />
      <ellipse
        cx='674.077'
        cy='177.675'
        rx='2.88918'
        ry='2.88252'
        fill='white'
      />
      <ellipse
        cx='674.078'
        cy='177.675'
        rx='1.92612'
        ry='1.92168'
        fill='#9690D6'
      />
    </svg>
  )
}

export default DevJamsGridGround
