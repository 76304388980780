import React from 'react'
import NavbarLink from 'Components/NavbarLink'
import { ReactComponent as InfoStar } from 'Assets/Logos/Navbar/info-star.svg'
import { ReactComponent as TimelineLogo } from 'Assets/Logos/Navbar/Timeline.svg'
import { ReactComponent as TeamsLogo } from 'Assets/Logos/Navbar/Teams.svg'
import { ReactComponent as ResultsLogo } from 'Assets/Logos/Navbar/Results.svg'
import { ReactComponent as SubmissionLogo } from 'Assets/Logos/Navbar/Submission.svg'
import { useRouteMatch } from 'react-router'

const EventsNavbar = ({
  marketplace: MarketplaceLink,
  devjams: DevjamsTracksLink,
  sponsor: SponsorTracksLink
}) => {
  const match = useRouteMatch()

  return (
    <div className='fixed flex md:flex-col md:justify-center md:max-h-96 md:overflow-y-auto items-center max-w-xs bottom-0 bg-jams_dark_blue left-1/2 transform -translate-x-1/2 overflow-hidden rounded-t-xl md:rounded-tl-none md:rounded-r-xl md:translate-x-0 md:left-0 md:bottom-auto md:top-1/2 md:-translate-y-1/2 z-60 border-2 border-b-0 border-jams_input_gray md:border-none'>
      <ChervonUp />
      <ChervonLeft />
      <div className='w-64 flex overflow-auto md:h-1/2 md:flex-col md:w-20'>
        <NavbarLink
          icon={() => <InfoStar className='m-1 w-8 fill-current' />}
          name='Info'
          next={`${match.url}/info`}
        />
        <NavbarLink
          icon={() => <TimelineLogo className='m-1 w-8 fill-current' />}
          name='Timeline'
          next={`${match.url}/timeline`}
        />
        {DevjamsTracksLink && <DevjamsTracksLink />}
        {SponsorTracksLink && <SponsorTracksLink />}
        <NavbarLink
          icon={() => <TeamsLogo className='m-2 fill-current w-8' />}
          name='Team'
          next={`${match.url}/teams`}
        />
        {MarketplaceLink && <MarketplaceLink />}
        <NavbarLink
          icon={() => <ResultsLogo className='m-1 stroke-current w-10' />}
          name='Results'
          next={`${match.url}/results`}
        />
        <NavbarLink
          icon={() => <SubmissionLogo className='m-2 stroke-current w-10' />}
          name='Submit'
          next={`${match.url}/submission`}
        />
      </div>
      <ChervonRight />
      <ChervonDown />
    </div>
  )
}

const ChervonLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-3 w-3 text-jams_icon_gray stroke-current mx-3 block md:hidden'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='M15 19l-7-7 7-7'
      />
    </svg>
  )
}

const ChervonRight = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-3 w-3 text-jams_icon_gray stroke-current mx-3 block md:hidden'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='M9 5l7 7-7 7'
      />
    </svg>
  )
}

const ChervonUp = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-10 w-10 text-jams_icon_gray stroke-current my-3 hidden md:block'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='M5 15l7-7 7 7'
      />
    </svg>
  )
}

const ChervonDown = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-10 w-10 text-jams_icon_gray stroke-current my-3 hidden md:block'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='M19 9l-7 7-7-7'
      />
    </svg>
  )
}

export default EventsNavbar
